import { Component,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../service/payment.service';
import { environment } from '@env/environment';
import { PaymentSlipDialog } from '../payment-slip/payment-slip.component';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.html'
})

export class PayDialog {
    staffInbox = environment.staffInbox;
    public cardColor: string = this.sharedService.blue + '20';
    constructor(public paymentService: PaymentService,
                public sharedService: SharedService,
                private toastr: ToastrService,
                public dialog: MatDialog,
                private dialogRef: MatDialogRef<PayDialog>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    }

    viewRemittanceSlip(): void {
      this.dialog.open(PaymentSlipDialog, {
        data: this.data,
        role: 'dialog',
        ariaLabel: 'Remittance Slip Dialog',
        minWidth: !this.sharedService.mobile? '400px' : '300px',
        maxWidth: !this.sharedService.mobile? '800px' : '300px'
      });
    }

    cancel() {
      this.dialogRef.close();
    }
}
