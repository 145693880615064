import { Component, OnInit } from "@angular/core";
import { IDocument } from "app/models/documents/document";
import { IFacilitatorLicense } from "app/models/licenses/facilitator-license";
import { IManufacturerLicense } from "app/models/licenses/manufacturer-license";
import { IServiceCenterLicense } from "app/models/licenses/service-center-license";
import { ITestingLabLicense } from "app/models/licenses/testing-lab-license";
import { IWorkerLicense } from "app/models/licenses/worker-license";
import { ILicenseAdminSettings } from "app/models/product-tracking/admin-settings";
import { ITrainingProgram } from "app/models/training/training-program";
import { SharedService } from "app/services/core/shared.service";
import { FacilitatorService } from "app/services/licenses/facilitator.service";
import { LicensingService } from "app/services/licenses/licensing.service";
import { ManufacturerService } from "app/services/licenses/manufacturer.service";
import { ServiceCenterService } from "app/services/licenses/service-center.service";
import { TestingLabService } from "app/services/licenses/testing-lab.service";
import { WorkerService } from "app/services/licenses/worker.service";
import { TrainingProgramService } from "app/services/training/training-program.service";

@Component({
  selector: 'licensing-home-component',
  templateUrl: './licensing-home.component.html',
  styleUrls: ['./licensing-home.component.scss'],
})
export class LicensingHomeComponent implements OnInit {

  public adminSettings: ILicenseAdminSettings = {
    message: "",
    changeRequestMessage: "",
    headerText: "",
    renewalMessage: "",
    links: [],
    documentLinks: [],
    changeRequestLinks: [],
    renewalLinks: [],
    videoLinks: []
  };
  public loading: boolean = true;
  public facilitatorLicenses: IFacilitatorLicense[] = [];
  public trainingPrograms: ITrainingProgram[] = [];
  public workerLicenses: IWorkerLicense[] = [];
  public manufacturerLicenses: IManufacturerLicense[] = [];
  public serviceCenterLicenses: IServiceCenterLicense[] =[];
  public testingLabLicenses: ITestingLabLicense[] = [];
  public hasApproved: boolean = false;
  public licensesLoaded: boolean = false;
  constructor(public sharedService: SharedService,
              public licensingService: LicensingService,
              private facilitatorService: FacilitatorService,
              private workerService: WorkerService,
              private trainingService: TrainingProgramService,
              private serviceCenterService: ServiceCenterService,
              private testingLabService: TestingLabService,
              private manufacturerService: ManufacturerService) { }

  ngOnInit(): void {
    this.facilitatorService.getFacilitatorLicenses().subscribe(
      response => this.facilitatorLicenses = response,
      error => console.log('error', error),
      () => {
        this.trainingService.getTrainingPrograms().subscribe(
          response => this.trainingPrograms = response,
          error => console.log('error', error),
          () => {
            this.workerService.getWorkerLicenses().subscribe(
              response => this.workerLicenses = response,
              error => console.log('error', error),
              () => {
                this.manufacturerService.getLicenses().subscribe(
                  response => this.manufacturerLicenses = response,
                  error => console.log('error', error),
                  () => {
                    this.serviceCenterService.getLicenses().subscribe(
                      response => this.serviceCenterLicenses = response,
                      error => console.log('error', error),
                    () => {
                      this.testingLabService.getLicenses().subscribe(
                        response => this.testingLabLicenses = response,
                        error => console.log('error', error),
                      () => {
                      this.checkLicenses();
                      this.licensesLoaded = true;
                    });
                  });
                  });

              });
          });
       });

    this.licensingService.getAdminSettings().subscribe(
      response => {
        this.adminSettings = response;
        this.orderLinks();
        this.loading = false;
      },
      error => console.log('error', error)
    );
  }

orderLinks(): void {
    this.adminSettings.links.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
    this.adminSettings.changeRequestLinks.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
    this.adminSettings.renewalLinks.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
    this.adminSettings.documentLinks.sort((a, b) => {
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
  }

  checkLicenses(){
    if(this.facilitatorLicenses.filter(fl => fl.status == "Approved").length > 0)
      this.hasApproved = true;
    if(this.manufacturerLicenses.filter(fl => fl.status == "Approved").length > 0)
      this.hasApproved = true;
    if(this.serviceCenterLicenses.filter(fl => fl.status == "Approved").length > 0)
      this.hasApproved = true;
    if(this.testingLabLicenses.filter(fl => fl.status == "Approved").length > 0)
      this.hasApproved = true;
    if(this.workerLicenses.filter(fl => fl.status == "Approved").length > 0)
      this.hasApproved = true;
    if(this.trainingPrograms.filter(fl => fl.status == "Approved").length > 0)
      this.hasApproved = true;
  }

}
