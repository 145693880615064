import { Inject, Injectable } from "@angular/core";
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class AuthService {
  public loading: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService) { }

  isAuthenticated(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  public get username(): string {
    let account = this.authService.instance.getAllAccounts()[0];
    if (account) {
      return this.formatName(account.idTokenClaims["given_name"] as string)
             + " " +
             this.formatName(account.idTokenClaims["family_name"] as string);
    }
    return null;
  }

  formatName(name: string): string{
    return name[0].toUpperCase() + name.slice(1).toLowerCase();
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    this.loading = true;
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.loading = false;
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.loading = false;
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
