import { trigger, transition, style, animate } from "@angular/animations";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { IComplaint } from "app/models/complaints/complaint";
import { ComplaintsService } from "app/services/complaints/complaints.service";
import { SharedService } from 'app/services/core/shared.service';
import { Subscription } from "rxjs/internal/Subscription";

const MOBILE_MEDIAQUERY = "screen and (max-width: 599px)";
const TABLET_MEDIAQUERY = "screen and (min-width: 600px) and (max-width: 959px)";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class ComplaintsComponent {
  public selectedParent: string = 'TrainingProgram';
  public entityTypes: { [type: string]: string } =
    {
      "TrainingProgram": "Training Program Curriculum",
      "Facilitator": "Facilitator",
      "ServiceCenter": "Service Center",
      "Manufacturer": "Manufacturer",
      "TestingLab": "Testing Lab",
      "WorkerPermit": "Worker Permit"
  };
  public showForm: boolean = false;
  public showSuccess: boolean = false;
  private layoutChangesSubscription: Subscription;
  public today: Date = new Date(Date.now());
  public complaintForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required]),
    contactEmail: new UntypedFormControl("", [Validators.required]),
    contactNumber: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    preferredContact: new UntypedFormControl("email", [Validators.required]),
    identityConcern: new UntypedFormControl("", [Validators.required]),
    parentLicenseName: new UntypedFormControl("", [Validators.required]),
    complaintText: new UntypedFormControl("", [Validators.required]),
    previousReporting: new UntypedFormControl("", [Validators.required]),
    signature: new UntypedFormControl("", [Validators.required]),
    incidentDate: new UntypedFormControl("")
  });

  constructor(public sharedService: SharedService,
              private breakpointObserver: BreakpointObserver,
              public complaintService: ComplaintsService) {
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY])
      .subscribe((state) => {
        this.sharedService.mobile = state.breakpoints[MOBILE_MEDIAQUERY];
        this.sharedService.tablet = state.breakpoints[TABLET_MEDIAQUERY];
      });
  }

  openForm(): void {
      this.showForm = true;
  }

  cancel() {
    this.selectedParent = 'TrainingProgram';
    this.showSuccess = false;
    this.showForm = false;
  }

  submitComplaint(): void {
    let form = this.complaintForm.value;
    let complaint: IComplaint = {
      id: 0,
      entityType: this.selectedParent,
      name: form.name,
      contactEmail: form.contactEmail,
      contactNumber: form.contactNumber,
      preferredContact: form.preferredContact,
      identityConcern: form.identityConcern,
      parentId: '',
      parentName: '',
      complaintText: form.complaintText,
      previousReporting: form.previousReporting,
      signature: form.signature,
      incidentDate: form.incidentDate,
      parentLicenseName: form.parentLicenseName
    }
    this.complaintService.submitComplaint(complaint).subscribe(
      () => {
        this.selectedParent = 'Training Program';
        this.showSuccess = true;
        this.showForm = false;
        this.clearForm();
      },
      error => console.log('error', error)
    );
  }

  clearForm(): void {
    this.complaintForm.patchValue({
      name: '',
      contactEmail: '',
      contactNumber: '',
      preferredContact: 'email',
      identityConcern: '',
      parentLicenseName: '',
      parentAddress: '',
      parentEmail: '',
      parentWebsite: '',
      complaintText: '',
      previousReporting: '',
      signature: '',
      incidentDate: ''
    });
  }

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }

}
