import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage, EventType } from "@azure/msal-browser";
import { SettingsService } from "@core";
import { environment } from "@env/environment";
import { AuthService } from "app/auth/auth.service";
import { SharedService } from "app/services/core/shared.service";
import { UserService } from "app/services/user/user.service";
import { filter } from "rxjs/operators";

interface IWelcomeDialogModel {
  route: string;
  icon: string;
  title: string;
  description: string;
  backgroundColor: string;
}

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"]
})

export class WelcomeComponent implements OnInit {
  defaultRoute: string = 'license-dashboard';
  route: string = 'route';
  redirectFromProfile: string = 'redirectFromProfile';
  loggingIn: boolean = false;
  allActive: boolean = environment.activeLicenses.all;
  facilitatorActive: boolean = environment.activeLicenses.facilitator;
  manufacturerActive: boolean = environment.activeLicenses.manufacturer;
  serviceCenterActive: boolean = environment.activeLicenses.serviceCenter;
  testingLabActive: boolean = environment.activeLicenses.testingLab;
  workerPermitActive: boolean = environment.activeLicenses.workerPermit;

  facilitatorModel: IWelcomeDialogModel =
  {
    route: 'license/facilitator',
    icon: 'group',
    title: 'Facilitators',
    description: 'To apply for a facilitator license through Oregon Psilocybin Services, start here. For more information about the facilitator license application requirements and process, please visit',
    backgroundColor: this.sharedService.purple
  }

  manufacturerModel: IWelcomeDialogModel =
  {
    route: '/license/manufacturer',
    icon: 'scale',
    title: 'Manufacturers',
    description: 'To apply for a manufacturer license through Oregon Psilocybin Services, start here. For more information about the manufacturer license application requirements and process, please visit',
    backgroundColor: this.sharedService.gold
  }
  serviceCenterModel: IWelcomeDialogModel =
  {
    route: '/license/service-center',
    icon: 'store_mall_directory',
    title: 'Service Centers',
    description: 'To apply for a service center license through Oregon Psilocybin Services, start here. For more information about the service center license application requirements and process, please visit',
    backgroundColor: this.sharedService.green
  }
  testingLabModel: IWelcomeDialogModel =
  {
    route: '/license/testing-lab',
    icon: 'domain',
    title: 'Laboratory',
    description: 'To apply for a laboratory license through Oregon Psilocybin Services, start here. For more information about the laboratory license application requirements and process, please visit',
    backgroundColor: this.sharedService.orange
  }
  workerPermitModel: IWelcomeDialogModel =
  {
    route: '/license/worker-permit',
    icon: 'book',
    title: 'Worker Permit',
    description: 'To apply for a worker permit through Oregon Psilocybin Services, start here. For more information about the worker permit application requirements and process, please visit',
    backgroundColor: this.sharedService.pink
  }
  trainingProgramModel: IWelcomeDialogModel =
  {
    route: '/training',
    icon: 'description',
    title: 'Training Program',
    description: 'training',
    backgroundColor: this.sharedService.blue
  }

  constructor(
    public settings: SettingsService,
    private router: Router,
    private userService: UserService,
    public sharedService: SharedService,
    private authService: AuthService,
    public dialog: MatDialog,
    private msalBroadcastService: MsalBroadcastService) { }

  ngOnInit(): void {
    if(this.authService.isAuthenticated()){
      this.loggingIn = true;
      this.userService.getUserInfo().subscribe(
        response => this.sharedService.userProfile = response,
        error => this.sharedService.consoleLog(error),
        () => {
          this.sharedService.toggleTheme();
          this.router.navigateByUrl('licensing-home');
        }
      );
    }
    else{
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe(() => {
        this.loggingIn = true;
        this.userService.getUserInfo().subscribe(
          response => this.sharedService.userProfile = response,
          error => this.sharedService.consoleLog(error),
          () =>{
            this.sharedService.toggleTheme();
            let redirectFromProfile = window.localStorage.getItem(this.redirectFromProfile);
            if(redirectFromProfile != null){
              window.localStorage.removeItem(this.redirectFromProfile);
              this.router.navigateByUrl('licensing-home');
            }
            else{
              let route = window.localStorage.getItem(this.route);
              if(route != null){
                window.localStorage.removeItem(this.route);
                this.router.navigateByUrl(route);
              }
              else{
                this.router.navigateByUrl(this.defaultRoute);
              }
            }
          }
        )
      });
    }
  }

  continue(): void{
    window.localStorage.setItem(this.redirectFromProfile, 'true');
    this.authService.login();
  }

  launchLicenseDialog(model: IWelcomeDialogModel): void {
    const dialogRef = this.dialog.open(LicenseDialog, {
      data: model,
      role: 'dialog',
      ariaLabel: model.title + ' Dialog',
      autoFocus: false,
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: this.sharedService.mobile? '600px' : '700px',
      panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.loginRedirect(response)
    });
  }

  loginRedirect(redirect: string): void {
    if (redirect == 'cancel' || redirect == null || redirect == '') {
      return;
    }
    window.localStorage.setItem(this.route, redirect);
    this.authService.login();
  }

}

@Component({
  selector: "license-dialog",
  templateUrl: "./dialog-license.html",
  styleUrls: ["./welcome.component.scss"]
})

export class LicenseDialog implements OnInit {
  private route: string = '';
  public icon: string = '';
  public title: string = '';
  public description: string = '';
  public backgroundColor: string = '';

  constructor(public dialogRef: MatDialogRef<LicenseDialog>,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public model: IWelcomeDialogModel) { }

  ngOnInit(): void {
    this.route = this.model.route;
    this.icon = this.model.icon;
    this.title = this.model.title;
    this.description = this.model.description;
    this.backgroundColor = this.model.backgroundColor;
  }

  signUp(): void {
    this.dialogRef.close(this.route)
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
