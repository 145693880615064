<div *ngIf="currentLicense.status == 'Approved'">

<div *ngIf='!loading'>
 <img src="assets/images/ohaLogoTransparent-small.png" alt="OHA Logo">
<mat-card [style.background-color]="color">
  <mat-card-content>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-icon class="large-icon m-16">badge</mat-icon>
      <span class="f-s-20 p-l-16">{{currentLicense.licenseType}} Number: </span>
      <span class="f-s-16 p-r-16">{{currentLicense.licenseNumber}}</span>
      <span fxFlex></span>
      <a [routerLink]="['/license-information', type, id]" fxLayoutAlign="end end">
        <qrcode [qrdata]=" url + '/license-information/' + type + '/' + id"></qrcode>
      </a>
    </div>
  </mat-card-content>
</mat-card>
<div fxLayout="column" fxLayoutGap="8px">
<!-- Start of Statuses -->
  <mat-card>
    <mat-card-title>
      License Status
    </mat-card-title>
    <mat-card-content>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" class="p-t-24">
        <div fxLayout="column" fxLayoutGap="8px">
          <span><strong>Status</strong></span>
          <div fxLayout="row" fxLayoutGap="8px">
             <span>{{currentLicense.status}}</span>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="8px">
          <span><strong>Active Date</strong></span>
          <div fxLayout="row" fxLayoutGap="8px">
            <span>{{currentLicense.activeDate | date}}</span>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="8px">
          <span><strong>Expiration Date</strong></span>
          <span>{{currentLicense.expirationDate | date}}</span>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" *ngIf="currentLicense.isRenewal">
          <span><strong>Date Renewal Approved</strong></span>
          <span>{{currentLicense.renewedDate | date}}</span>
        </div>
      </div>
      <br />
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutAlign="start center">
        <span class="f-s-16 p-r-16" *ngIf="currentLicense.canOperate"><i>Acceptable to operate - Renewal under review</i></span>
      </div>
    </mat-card-content>
  </mat-card>
<!-- End of Statuses -->
</div>
</div>
</div>

<mat-card *ngIf="currentLicense.status != 'Approved'">
  <mat-card-content>
    <div>
      <h2>We're sorry, the license you are looking for cannot be found</h2>
    </div>
  </mat-card-content>
</mat-card>
