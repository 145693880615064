import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPublicTrainingProgramReference } from 'app/models/training/PublicTrainingProgramReference';
import { TrainingProgramService } from 'app/services/training/training-program.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-public-program-list',
  templateUrl: './public-program-list.component.html',
  styleUrls: ['./public-program-list.component.scss']
})
export class PublicProgramListComponent implements OnInit {
  public trainingPrograms: IPublicTrainingProgramReference[] = [];
  private layoutChangesSubscription: Subscription;
  public isMobileScreen: boolean = false;

  public programColumns: string[] = ['programName', 'website', 'address'];
  public programSource = new MatTableDataSource<IPublicTrainingProgramReference>(this.trainingPrograms);
  @ViewChild('programPaginator', { static: false }) programPaginator: MatPaginator;
  @ViewChild('programSort', { static: false }) programSort: MatSort;

  constructor(public trainingProgramService: TrainingProgramService) {
  }

  ngOnInit(): void {
    this.trainingProgramService.getPublicTrainingProgramList().subscribe({
      next: response => this.trainingPrograms = response,
      error: error => console.log('An error has occured'),
      complete: () => {
        this.programSource.data = this.trainingPrograms;
        this.programSource.sort = this.programSort;
        this.programSource.paginator = this.programPaginator;
      }
    });
  }

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }

}


