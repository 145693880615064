import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/auth/auth.service";
import { SharedService } from "app/services/core/shared.service";
import { UserService } from "app/services/user/user.service";

@Component({
  selector: 'account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss']
})

export class AccountInformationComponent implements OnInit {

  public email: string = '';
  public username: string = '';

  constructor(
              public sharedService: SharedService,
              private authService: AuthService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.sharedService.getUserInfo().subscribe(
      response =>
      {
        this.username = response.firstName + ' ' + response.lastName;
        this.email = response.email;
      },
      error => console.log('error', error));

  }

}
