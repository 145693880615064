import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IDocument } from 'app/models/documents/document';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IDocumentType } from 'app/models/documents/documentType';
import { ITestingLabLicense } from 'app/models/licenses/testing-lab-license';
import { IWorkerLicense } from 'app/models/licenses/worker-license';

@Injectable(
  { providedIn: 'root' }
)

export class TestingLabService {
  private api: string = environment.privateApi + 'TestingLab/';

  constructor(private http: HttpClient) { }

  getLicenses(): Observable<ITestingLabLicense[]> {
    return this.http.get<ITestingLabLicense[]>(this.api + 'GetLicenses');
  }

  createLicense(): Observable<ITestingLabLicense> {
    return this.http.post<ITestingLabLicense>(this.api + 'CreateLicense', null);
  }

  updateLicense(license: ITestingLabLicense) {
    this.http.post<boolean>(this.api + 'UpdateLicense', license).subscribe(
      error => console.log('error', error)
    );
  }

  requestWithdraw(id: number): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + "requestWithdraw/" + id, null);
  }

  requestSurrender(id: number): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + "requestSurrender/" + id, null);
  }

  getDocumentTypes(): Observable<IDocumentType[]>{
    return this.http.get<IDocumentType[]>(this.api + 'GetDocumentTypes');
  }

  uploadDocument(files: FormData): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadDocument', files)
  }

  downloadFile(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadDocument/" + fileId, { responseType: 'blob' as 'json' })
  }

  deleteDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocument/" + id)
  }

  submitApplication(license: ITestingLabLicense, renewal: boolean): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + "SubmitLicense/" + renewal, license);
  }

  withdrawApplication(license: ITestingLabLicense): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + "WithdrawLicense", license);
  }

  reopenApplication(license: ITestingLabLicense): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + "reopenLicense", license);
  }

  addWorkerPermit(licenseId: number, permitIds: string[]): Observable<IWorkerLicense[]>{
    return this.http.post<IWorkerLicense[]>(this.api + 'AddWorkerPermit/' + licenseId, permitIds);
  }

  removeWorkerPermit(licenseId: number, permitId: string): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'RemoveWorkerPermit/' + licenseId + '/' + permitId);
  }

  createRenewalLicense(licenseId: number): Observable<ITestingLabLicense>{
    return this.http.post<ITestingLabLicense>(this.api + 'CreateTestingLabRenewal/' + licenseId, null);
  }

}
