<div fxLayout="column" fxLayoutGap="12px">
  <mat-card [@slideInOut]>
    <mat-card-title>
      <span >Primary Point of Contact</span>
    </mat-card-title>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="8px">

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
            <span class="f-s-16"><strong>Contact Name</strong></span>
            {{data.legalFirstName}} {{data.legalLastName}}
          </div>
          <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
            <span class="f-s-16"><strong>Contact Phone</strong></span>
            {{data.phone | mask: '(000) 000-0000 000000'}}
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
            <span class="f-s-16"><strong>Email Address</strong></span>
            <span>{{data.email}}</span>
          </div>
          <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
            <span class="f-s-16"><strong>Preferred Language</strong></span>
            <span>{{data.language}}</span>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>

  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
    <span style="text-align: center"><strong>
      You will only receive communication if the email address shown above has a TLC account. Oregon Psilocybin Services communicates through TLC-Messages.
    </strong></span>
    <span style="text-align: center"><strong>
      If the email address shown above is the email address that was used to create the TLC account for this application, you will receive TLC-Messages on the TLC account that was created for this application.
    </strong></span>
    <span style="text-align: center"><strong>
      If the email address shown above does NOT have a TLC account, you will need to make a new TLC account for it to receive TLC-Messages.
    </strong></span>
  </div>

  <div mat-dialog-actions fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="12px">
      <span style="text-align: center">Does {{data.legalFirstName}} {{data.legalLastName}} have a TLC account to receive communication? Do you want them as the primary point of contact?</span>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
        <button type="button" mat-raised-button color="primary" (click)="confirm()">Yes</button>
        <button type="button" mat-raised-button color="warm" (click)="cancel()">No</button>
      </div>
    </div>
  </div>
</div>

