<section>
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
    <span class="f-s-20" fxFlex>Add 303 Data Reporting Portal Worker Permits</span>
    <button mat-raised-button (click)="save()" color="primary" *ngIf="selectedPermits.length > 0">Add Selected Permits</button>
    <button mat-raised-button (click)="cancel()" color="warn">Cancel</button>
  </div>
</section>

<mat-dialog-content>
  <div fxLayout="column" class="m-t-8">
    <form [formGroup]="workerPermitForm">
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
        <mat-form-field fxFlex="60" fxFlex.lt-lg="100">
          <mat-label>Worker Permit Number</mat-label>
          <input matInput type="text" formControlName="permitId" placeholder="Permit Id">
          <mat-error *ngIf="workerPermitForm.get('permitId').invalid">
            Worker Permit could not be found
          </mat-error>
        </mat-form-field>

        <button mat-button type="button" color="primary" (click)="addPermit()" *ngIf="workerPermitForm.valid">
          Select
        </button>
      </div>
    </form>

    <div fxFlex="100" class="m-t-8" fxLayout="column">
      <span class="f-s-20">Selected Permits</span>
      <div fxLayout="column" fxFlex="100">
        <div *ngFor="let permit of selectedPermits" fxFlex="80" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
          <span fxFlex="50">{{ permit }}</span>
          <button mat-button type="button" color="warn" (click)="removePermit(permit)">
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
