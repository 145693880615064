import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MenuService } from '@core';
import { IMenu } from '../../models/core/menu';
import { environment } from 'environments/environment';
import { SB303Service } from 'app/services/303/303.service';
import { map } from 'rxjs/operators';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;
  @Input() isMobile = false;

  menu$ = this.menu.getAll();
  menuResponse: IMenu[] = [];
  public loading: boolean = false;
  buildRoute = this.menu.buildRoute;
  @Output() toggleCollapsed = new EventEmitter<void>();
  @Output() toggleSidenav = new EventEmitter<void>();

  constructor(private menu: MenuService,
    private sb303Service: SB303Service,
    public sharedService: SharedService) { }

  ngOnInit() {
    this.loading = true;
    this.sb303Service.getLicenses().subscribe({
      next: response => {
        if (response.length <= 0) {
          this.menu$ = this.menu$.pipe(
            map(items => {
              return items.filter(item => item.name !== '303 Data Reporting Portal');
            })
          );
        }
      },
      error: e => console.log('error', e),
      complete: () => {
        this.loading = false;
        //This sets up the namespace required for translating the sidebar options
        this.menu$.subscribe(
          response => {
            if (environment.translateText) {
              this.menu.addNamespace(response, 'menu');
            }
          }
        );
      }
    });
  }

  showLink(active?: boolean): boolean{
    if(active === null || active === undefined || active) return true;
    return false;
  }

}
