<img src="../../../assets/images/translucentOhaLogo.png" class="bg hideOnMobile" alt="Oregon Health Authority Logo" aria-label="Oregon Health Authority Logo">

<img src="../../../assets/images/translucentOhaLogo.png" class="bg-mobile showOnMobile" alt="Oregon Health Authority Logo" aria-label="Oregon Health Authority Logo">

<div *ngIf="!loggingIn" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="150px" fxLayoutGap.lt-sm="25px">
    <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start center">
      <span style="font-size: x-large;"> <strong>Already have an account?</strong> </span>
      <span class="f-s-20"> Continue where you left off </span>
      <button mat-stroked-button (click)="continue()" color="primary">
        <span class="f-s-20"> Continue</span>
      </button>
    </div>
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start center">
        <span style="font-size: x-large;"> <strong>New user?</strong> </span>
        <span class="f-s-20">Select an option to get started</span>
      </div>

      <div fxLayout="column" fxLayoutGap="24px" *ngIf="!sharedService.isMobile">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <button mat-button (click)="launchLicenseDialog(trainingProgramModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" class="icon-button training-program-button p-8">
              <mat-icon class="large-icon m-32">description</mat-icon>
              <span class="f-s-18">Training Programs</span>
            </div>
          </button>
          <button mat-button (click)="launchLicenseDialog(facilitatorModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="facilitatorActive"
                 class="icon-button facilitator-button p-y-8 p-x-32">
              <mat-icon class="large-icon m-32">group</mat-icon>
              <span class="f-s-18">Facilitators</span>
            </div>
          </button>

          <button mat-button (click)="launchLicenseDialog(manufacturerModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="manufacturerActive"
              class="icon-button manufacturer-button p-y-8 p-x-16">
              <mat-icon class="large-icon m-32">scale</mat-icon>
              <span class="f-s-18">Manufacturers</span>
            </div>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <button mat-button (click)="launchLicenseDialog(serviceCenterModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="serviceCenterActive"
              class="icon-button service-center-button p-y-8 p-x-16">
              <mat-icon class="large-icon m-32">store_mall_directory</mat-icon>
              <span class="f-s-18">Service Centers</span>
            </div>
          </button>
          <button mat-button (click)="launchLicenseDialog(testingLabModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="testingLabActive"
              class="icon-button testing-lab-button p-y-8 p-x-24">
              <mat-icon class="large-icon m-32">domain</mat-icon>
              <span class="f-s-18">Laboratory</span>
            </div>
          </button>
          <button mat-button (click)="launchLicenseDialog(workerPermitModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="workerPermitActive"
              class="icon-button worker-permit-button p-y-8 p-x-16">
              <mat-icon class="large-icon m-32">badge</mat-icon>
              <span class="f-s-18">Worker Permit</span>
            </div>
          </button>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="24px" *ngIf="sharedService.isMobile">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <button mat-button (click)="launchLicenseDialog(trainingProgramModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" class="icon-button training-program-button p-8">
              <mat-icon class="large-icon m-32">description</mat-icon>
              <span class="f-s-18">Training Programs</span>
            </div>
          </button>
          <button mat-button (click)="launchLicenseDialog(facilitatorModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="facilitatorActive"
                 class="icon-button facilitator-button p-y-8 p-x-32">
              <mat-icon class="large-icon m-32">group</mat-icon>
              <span class="f-s-18">Facilitators</span>
            </div>
          </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around center">
          <button mat-button (click)="launchLicenseDialog(manufacturerModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="manufacturerActive"
              class="icon-button manufacturer-button p-y-8 p-x-16">
              <mat-icon class="large-icon m-32">scale</mat-icon>
              <span class="f-s-18">Manufacturers</span>
            </div>
          </button>
          <button mat-button (click)="launchLicenseDialog(serviceCenterModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="serviceCenterActive"
              class="icon-button service-center-button p-y-8 p-x-16">
              <mat-icon class="large-icon m-32">store_mall_directory</mat-icon>
              <span class="f-s-18">Service Centers</span>
            </div>
          </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around center">
          <button mat-button (click)="launchLicenseDialog(testingLabModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="testingLabActive"
              class="icon-button testing-lab-button p-y-8 p-x-24">
              <mat-icon class="large-icon m-32">domain</mat-icon>
              <span class="f-s-18">Testing Labs</span>
            </div>
          </button>
          <button mat-button (click)="launchLicenseDialog(workerPermitModel)">
            <div fxLayout="column" fxLayoutAlign="space-between center" *ngIf="workerPermitActive"
              class="icon-button worker-permit-button p-y-8 p-x-16">
              <mat-icon class="large-icon m-32">badge</mat-icon>
              <span class="f-s-18">Worker Permit</span>
            </div>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<div *ngIf="loggingIn">
  <div id="globalLoader" class="global-loader">
    <h1>Loading</h1>
  </div>
</div>
