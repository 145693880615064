import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IFacilitatorLicense } from 'app/models/licenses/facilitator-license';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IApplicant } from 'app/models/licenses/applicant';

@Injectable(
  { providedIn: 'root' }
)

export class FacilitatorService {
  private api: string = environment.privateApi + 'Facilitator/';

  constructor(private http: HttpClient) { }

  getFacilitatorLicenses(): Observable<IFacilitatorLicense[]> {
    return this.http.get<IFacilitatorLicense[]>(this.api + 'GetFacilitatorLicenses');
  }

  createFacilitatorLicense(): Observable<IFacilitatorLicense> {
    return this.http.post<IFacilitatorLicense>(this.api + 'CreateFacilitatorLicense', null);
  }

  updateApplicant(applicant: IApplicant){
    this.http.post<boolean>(this.api + 'UpdateApplicant', applicant).subscribe(
      () => {},
      error => console.log('error', error)
    )
  }

  updateLicense(license: IFacilitatorLicense) {
    this.http.post<boolean>(this.api + 'UpdateLicense', license).subscribe(
      () => {},
      error => console.log('error', error)
    );
  }

  requestWithdraw(id: number): Observable<IFacilitatorLicense>{
    return this.http.post<IFacilitatorLicense>(this.api + "requestWithdraw/" + id, null);
  }

  requestSurrender(id: number): Observable<IFacilitatorLicense>{
    return this.http.post<IFacilitatorLicense>(this.api + "requestSurrender/" + id, null);
  }

  getFacilitatorDocumentTypes(): Observable<IDocumentType[]>{
    return this.http.get<IDocumentType[]>(this.api + 'GetFacilitatorDocumentTypes');
  }

  uploadDocument(file: FormData, applicant: boolean): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadDocument/' + applicant, file)
  }

  downloadFile(fileId: number, applicant: boolean): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadDocument/" + fileId + '/' + applicant, { responseType: 'blob' as 'json' })
  }

  deleteDocument(id: number, applicant: boolean): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocument/" + id + '/' + applicant)
  }

  submitApplication(license: IFacilitatorLicense, renewal: boolean): Observable<IFacilitatorLicense>{
    return this.http.post<IFacilitatorLicense>(this.api + "SubmitLicense/" + renewal, license);
  }
  withdrawApplication(license: IFacilitatorLicense): Observable<IFacilitatorLicense>{
    return this.http.post<IFacilitatorLicense>(this.api + "WithdrawLicense", license);
  }
  getApprovedTrainingPrograms():Observable<string[]>{
    return this.http.get<string[]>(this.api + 'GetApprovedTrainngPrograms');
  }

  createRenewalLicense(licenseId: number): Observable<IFacilitatorLicense>{
    return this.http.post<IFacilitatorLicense>(this.api + 'CreateFacilitatorRenewal/' + licenseId, null);
  }
}
