import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IResidentialHistory } from 'app/models/licenses/residential-history';
import { Observable } from 'rxjs';
import { IDocument } from 'app/models/documents/document';
import { IApplicant } from 'app/models/licenses/applicant';
import { IEntityApplicant } from 'app/models/licenses/entity-applicant';

@Injectable({providedIn: 'root'})
export class ApplicantService {
  private api: string = environment.privateApi + 'Applicant/';

  constructor(private http: HttpClient) { }

  //Individual Applicants
  getApplicants(licenseType: string, licenseId: number): Observable<IApplicant[]>{
    return this.http.get<IApplicant[]>(this.api + 'GetApplicants/' + licenseType + '/' + licenseId);
  }

  createApplicant(licenseType: string, licenseId: number): Observable<IApplicant>{
    return this.http.post<IApplicant>(this.api + 'CreateApplicant/' + licenseType + '/' + licenseId, null);
  }

  editApplicant(applicant: IApplicant, licenseType: string, licenseId: number ): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'EditApplicant/' + licenseType + '/' + licenseId, applicant);
  }

  deleteApplicant(id: number, licenseId: number, licenseType: string): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteApplicant/' + id + '/' + licenseId + '/' + licenseType);
  }

  //EntityApplicants
  getEntityApplicants(licenseType: string, licenseId: number): Observable<IEntityApplicant[]>{
    return this.http.get<IEntityApplicant[]>(this.api + 'GetEntityApplicants/' + licenseType + '/' + licenseId);
  }

  getAssociatedApplicants(applicantId: number): Observable<IApplicant[]>{
    return this.http.get<IApplicant[]>(this.api + 'GetAssociatedApplicants/' + applicantId);
  }

  createEntityApplicant(licenseType: string, licenseId: number): Observable<IEntityApplicant>{
    return this.http.post<IEntityApplicant>(this.api + 'CreateEntityApplicant/' + licenseType + '/' + licenseId, null);
  }

  editEntityApplicant(applicant: IEntityApplicant, licenseType: string, licenseId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'EditEntityApplicant/' + licenseType + '/' + licenseId, applicant);
  }

  addIndividualApplicant(entityId: number, applicantId: number): Observable<boolean>{
    return this.http.post<boolean>(this.api + 'AddIndividualApplicant/' + entityId + '/' + applicantId, null);
  }

  deleteIndividualApplicant(entityId: number, applicantId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteIndividualApplicant/' + entityId + '/' + applicantId);
  }

  deleteEntityApplicant(id: number, licenseType: string, licenseId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteEntityApplicant/' + id + '/' + licenseType + '/' + licenseId);
  }

  //Documents
  uploadDocument(files: FormData): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadDocument', files)
  }

  uploadEntityDocument(files: FormData): Observable<IDocument>{
    return this.http.post<IDocument>(this.api + 'UploadEntityDocument', files)
  }

  downloadFile(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadDocument/" + fileId, { responseType: 'blob' as 'json' })
  }

  deleteDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteDocument/" + id)
  }

  //Residential History
  updateResidentialHistory(residence: IResidentialHistory): Observable<IResidentialHistory>{
    return this.http.post<IResidentialHistory>(this.api + 'UpdateResidentialHistory', residence);
  }

  deleteResidentialHistory(id: number): Observable<boolean>{
    return this.http.delete<boolean>(this.api + 'DeleteResidentialHistory/' + id);
  }
}
