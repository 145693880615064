<form [formGroup]="residenceForm">
  <div fxLayout="column" class="m-t-8" fxLayoutGap="8">
    <span><strong>Residential history outside of Oregon in the past 5 years</strong></span>
    <span><strong>*</strong>This information is used for the purposes of the background check.</span>
    <span><strong>*</strong>City, state, country, from month/year to month/year.</span>
    <div class="m-t-8" fxLayout="row" *ngFor="let residence of residentialHistory">
      <div fxLayout="column">
        <div fxLayout="row"
             fxLayout.lt-lg="column"
             fxLayoutGap="8px"
             fxLayoutAlign="start center"
             fxLayoutAlign.lt-lg=""
             *ngIf="editingResidenceId !== residence.id"
             fxFlex="100">
          <div fxLayout="row" fxLayoutGap="4px">
            <span>{{residence.city}}</span>
            <span>{{residence.state}},</span>
            <span>{{residence.country}}</span>
            <span class="m-l-8"><strong>From:</strong></span>
            <span>{{residence.from | date: 'MMM, yyyy'}}</span>
            <span class="m-l-8"><strong>To:</strong></span>
            <span>{{residence.to | date: 'MMM, yyyy'}}</span>
          </div>
          <div fxLayout="row" *ngIf="!licenseLocked">
            <button mat-icon-button class="m-8" (click)="editResidentialHistory(residence)" color="primary"
              aria-label="Add Residence">
              <mat-icon>create</mat-icon>
            </button>
            <button mat-icon-button class="m-8" (click)="deleteResidentialHistory(residence.id)" color="warn"
              aria-label="Delete Residence">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

        <div fxLayout="column" *ngIf="editingResidenceId === residence.id">
          <div fxLayout="row" class="text-red-400" *ngIf="residenceHistoryIncomplete">
            Please provide all fields
          </div>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex.lt-lg="100">
              <mat-form-field fxFlex="15">
                <input matInput placeholder="City" formControlName="city">
              </mat-form-field>

              <mat-form-field fxFlex="15">
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex="15">
                <input matInput placeholder="Country" formControlName="country">
              </mat-form-field>

              <mat-form-field fxFlex="20">
                <mat-label>From Month and Year</mat-label>
                <input matInput [matDatepicker]="from" formControlName="from">
                <mat-hint>MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                <mat-datepicker #from
                                startView="multi-year"
                                (monthSelected)="setMonthAndYear($event, from, 'from')">
                </mat-datepicker>
              </mat-form-field>

              <mat-form-field fxFlex="20">
                <mat-label>To Month and Year</mat-label>
                <input matInput [matDatepicker]="to" formControlName="to">
                <mat-hint>MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                <mat-datepicker #to
                                startView="multi-year"
                                (monthSelected)="setMonthAndYear($event, to, 'to')">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxLayout="row" *ngIf="!licenseLocked">
              <button mat-icon-button class="m-8" (click)="updateResidentialHistory()" color="primary" aria-label="Add Residence">
                <mat-icon>save</mat-icon>
              </button>
              <button mat-icon-button class="m-8" (click)="cancelResidentialHistoryEdit()" color="warn"
                aria-label="Cancel Edit">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" *ngIf="editingResidenceId === 0 && !licenseLocked">
      <div fxLayout="row" class="text-red-400" *ngIf="residenceHistoryIncomplete">
        Please provide all fields
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
        <mat-form-field fxflex="15">
          <input matInput placeholder="City" formControlName="city">
        </mat-form-field>

        <mat-form-field fxFlex="15">
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="15">
          <input matInput placeholder="Country" formControlName="country">
        </mat-form-field>

        <mat-form-field fxFlex="15">
          <mat-label>From Month and Year</mat-label>
          <input matInput [matDatepicker]="from" formControlName="from">
          <mat-hint>MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
          <mat-datepicker #from
                          startView="multi-year"
                          (monthSelected)="setMonthAndYear($event, from, 'from')">
          </mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="15">
          <mat-label>To Month and Year</mat-label>
          <input matInput [matDatepicker]="to" formControlName="to">
          <mat-hint>MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
          <mat-datepicker #to
                          startView="multi-year"
                          (monthSelected)="setMonthAndYear($event, to, 'to')">
          </mat-datepicker>
        </mat-form-field>

        <button mat-icon-button class="m-8" type="button" (click)="addResidentialHistory()" color="primary" aria-label="Add Residence">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

  </div>
</form>
