import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IApplicant } from 'app/models/licenses/applicant';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'primary-contact-dialog',
  templateUrl: './primary-contact-dialog.component.html'
})
export class PrimaryContactDialogComponent {

  constructor( @Inject(MAT_DIALOG_DATA) public data: IApplicant,
               private dialogRef: MatDialogRef<PrimaryContactDialogComponent>) { }

  public cancel(): void{
    this.dialogRef.close(false);
  }

  public confirm(): void{
    this.dialogRef.close(true);
  }

  @HostListener("keydown.esc")
  public onEsc(): void{
    this.dialogRef.close(false);
  }

}
