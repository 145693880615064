import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PreloaderService } from '@core';
import { environment } from '../environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  isIframe:boolean = false;

  constructor(private preloader: PreloaderService, public router: Router) {
    if (environment.useAppInsights) {
      var angularPlugin = new AngularPlugin();
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsInstrumentationKey,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
          }
        }
      });
      appInsights.loadAppInsights();
    }
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    //redirect to https for non-development modes
    if (environment.build != "Development" && environment.enableHttpsRedirect) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
