import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { RoutesRoutingModule } from "./routes-routing.module";

import {
  LicenseDashboardComponent, PrintDetailsDialog
 } from "./components/license-dashboard/license-dashboard.component";
import {
  WelcomeComponent,
  LicenseDialog
} from "./components/welcome/welcome.component";
import { LicenseInformationComponent } from './components/license-dashboard/license-information/license-information/license-information.component';
import { WorkerDetailsDialog} from "./components/license/worker-permit/worker-permit.component";
import { AddUserDialogComponent } from "./components/add-user/add-user.component";
import { TestingLabDetailsDialog } from "./components/license/testing-lab/testing-lab.component";
import { ServiceCenterDetailsDialog } from "./components/license/service-center/service-center.component";
import { ManufacturerDetailsDialog } from "./components/license/manufacturer/manufacturer.component";
import { FacilitatorDetailsDialog } from "./components/license/facilitator/facilitator.component";
import { LicensingHomeComponent } from "./components/licensing-home/licensing-home.component";

const COMPONENTS = [
  LicenseDashboardComponent,
  LicensingHomeComponent,
  WelcomeComponent,
  LicenseDialog,
  WorkerDetailsDialog,
  ServiceCenterDetailsDialog,
  ManufacturerDetailsDialog,
  TestingLabDetailsDialog,
  FacilitatorDetailsDialog,
  LicenseInformationComponent,
  AddUserDialogComponent,
  PrintDetailsDialog

];
const COMPONENTS_DYNAMIC = [];

@NgModule({
    imports: [SharedModule, RoutesRoutingModule],
    declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, LicenseInformationComponent]
})
export class RoutesModule {}
