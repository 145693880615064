<div mat-dialog-content>
  {{data.message}}
</div>
<div mat-dialog-actions *ngIf="data.confirmation">
  <button type="button" mat-raised-button color="primary" (click)="confirm()">Yes</button>
  <button type="button" mat-raised-button color="warm" (click)="cancel()">No</button>
</div>

<div mat-dialog-actions *ngIf="!data.confirmation">
  <button type="button" mat-raised-button color="warm" (click)="cancel()">Ok</button>
</div>
