<mat-card [style.background-color]="backgroundColor">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-icon class="large-icon m-16">{{icon}}</mat-icon>
      <span class="f-s-20 p-l-16">{{title}}</span>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="m-b-0">
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="32px">
      <div fxLayout="column" fxLayoutGap="8px" *ngIf="description === 'training'" [class]="!sharedService.mobile ? 'f-s-20': 'f-s18'">
        <span>
          To apply for psilocybin facilitator training program curriculum approval through Oregon Psilocybin Services (OPS) - Training Program, Licensing, and Compliance
          system (TLC), start here.
        </span>
        <span>
          <strong>Important Note: The Responsible Party for the training program should create the TLC
          account registration.</strong> This individual must be legally authorized to make
          decisions and be legally responsible for the training program. This person will
          serve as the primary contact during the training program application process
          and while the program is approved. Please be sure to enter the Responsible Party's email address at
          account registration or important communications about the application and training program may be missed or delayed.
        </span>
        <span>
          For more information about the training program curriculum application requirements and process, please visit the
           <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-Training-Program-Approval.aspx" target="_blank" rel="noreferrer noopener">
            OPS - Apply for Training Program Curriculum Approval
          </a>
          webpage
        </span>
      </div>
      <span *ngIf="description !== 'training'" [class]="!sharedService.mobile ? 'f-s-20': 'f-s18'">
        {{description}} <a href="https://oregon.gov/psilocybin" target="_blank" rel="noreferrer noopener"> https://oregon.gov/psilocybin</a>
      </span>
      <div fxLayout="row" fxLayoutGap="8px">
        <button mat-raised-button color="primary" (click)="signUp()">Get Started!</button>
        <button mat-raised-button (click)="cancel()">Close</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
