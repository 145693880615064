<mat-toolbar style="min-height: 40px; max-height: 40px;">

    <span fxFlex></span>
    <span class="f-s-12 p-r-16">Oregon Health Authority</span>
    <a class="f-s-12 p-r-16" href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Oregon-Psilocybin-Services.aspx" target="_blank" rel="noreferrer noopener">
      Oregon Psilocybin Licensing
    </a>
    <span class="f-s-12">
      {{environment.build}} Version {{environment.version}}
    </span>

</mat-toolbar>
