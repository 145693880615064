
<div *ngIf="processing">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>

<div *ngIf="!processing">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-b-16">
      <span>Online payment successfully submitted!</span>
    </div>

    <mat-card class="note" *ngIf="isValid">
      <span >You have <strong>successfully</strong> submitted  your payment!  Please remember: <br/><br />
        <ul>
          <li>For payments to completely clear through the system, it can take upto 3 business days.  Most payments are processed within 24 hrs. </li>
          <li>Your payment will not immediately show as paid/cleared.  Thank you for your patience as your payment is processed.</li>
          <li>You can revisit your <strong><a [routerLink]="['/payments']" >Payments</a></strong> to view the status of your payment. </li>
          <li>If your payment has not cleared within 3 business days, then please contact the appropriate team via the <strong><a [routerLink]="['/messages']" >Messaging System</a></strong> to look into the issue. </li>

        </ul>
      </span>
    </mat-card>
  </div>

