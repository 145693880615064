<mat-card-content id="printPageButton">
  <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
    <button mat-raised-button color="primary" (click)="print()">Print</button>
  </div>
</mat-card-content>

  <div>
  <img src="assets/images/ohaLogoTransparent-small.png" alt="OHA Logo">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutAlign="start center">
        <mat-icon class="large-icon m-16">domain</mat-icon>
        <span class="f-s-20 p-l-16">Laboratory License ID:</span>
        <span class="f-s-16 p-r-16">{{license.licenseId}}</span>
        <qrcode  [qrdata]=" url + '/license-information/'+ 'TestingLab/' + license.id"></qrcode>
      </div>
    </mat-card-content>
  </mat-card>
  <div fxLayout="column" fxLayoutGap="8px">
    <mat-card>
      <mat-card-title>
        Name and Address
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" class="p-t-24">
          <div fxLayout="column" fxLayoutGap="8px">
            <span><strong>Operational Name</strong></span>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.entityName}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <span><strong>Address</strong></span>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.physicalStreet}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.physicalCity}},</span>
              <span>{{license.physicalState}}</span>
              <span>{{license.physicalZip}}</span>
              <span>{{license.physicalCounty}}</span>
            </div>
          </div>


        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxLayout="column" fxLayoutGap="8px">
    <mat-card>
      <mat-card-title>
        Laboratory License Information
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" class="p-t-24">
          <div fxLayout="column" fxLayoutGap="8px">
            <span><strong>Approved Date</strong></span>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.approvedDate}}</span>
            </div>
            <span><strong>Expiration Date</strong></span>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.expirationDate}}</span>
            </div>
            <span class="f-s-16 p-r-16" *ngIf="license.canOperate"><i>Acceptable to operate - Renewal under review</i></span>
            <span *ngIf="license.isRenewal"><strong>Date Renewal Approved</strong></span>
            <div fxLayout="row" fxLayoutGap="8px" *ngIf="license.isRenewal">
              <span>{{license.renewedDate}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <span><strong>Applicants</strong></span>
            <div fxLayout="row" fxLayoutGap="8px" *ngFor="let applicant of applicants">
              <span>{{applicant.legalFirstName}} {{applicant.legalLastName}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <span><strong>Entity Applicants</strong></span>
            <div fxLayout="row" fxLayoutGap="8px" *ngFor="let entity of entityApplicants">
              <span>{{entity.entityName}}</span>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
