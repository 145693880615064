<div fxLayout="column" *ngIf="applicant.id === 0">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
    <span class="f-s-18"><strong>Entity Applicants</strong></span>
    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event)" placeholder="Filter Applicants" />
    </mat-form-field>
    <span fxFlex></span>
    <span>Click to Edit</span>
    <button *ngIf="!licenseLocked && !renewal"
            mat-icon-button
            type="button"
            color="primary"
            (click)="addApplicant()"
            aria-label="Add Applicant"
            matTooltip="Add Applicant"
            matTooltipPosition="below"
            [matTooltipShowDelay]="500">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <mat-table [dataSource]="dataSource" matSort #sort="matSort" aria-describedby="applicants"
    class="mat-elevation-z8 p-t-0">
    <ng-container matColumnDef="entityName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Entity</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Entity</span>
        {{ applicant.entityName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="complete">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Status</span>
        <span *ngIf="applicant.complete" class="text-light-green-600">Complete</span>
        <span *ngIf="!applicant.complete" class="text-red-400">Incomplete</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
        <button *ngIf="!licenseLocked && !renewal && status == 'Pending'" mat-icon-button (click)="deleteApplicant(applicant)" color="warn" aria-label="Delete Applicant">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 20, 50]" #paginator="matPaginator">
  </mat-paginator>
</div>

<mat-card [style.background-color]="cardColor" *ngIf="applicant.id > 0">
  <mat-card-title>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center">
      <span>{{addingApplicant? 'Add An Entity Applicant' : 'Editing ' + applicant.entityName}}</span>
      <span fxFlex></span>
      <button mat-flat-button type="button" color="warn" (click)="cancel()">{{addingApplicant? 'Cancel' : 'Close'}}</button>
      <button *ngIf="!licenseLocked" mat-flat-button type="button" color="primary"
        (click)="saveApplicant(false)">Save</button>
      <button *ngIf="!licenseLocked" mat-flat-button type="button" color="primary"
        (click)="saveApplicant(true)">Save and Close</button>
    </div>
  </mat-card-title>

  <div fxLayout="row" *ngIf="renewal">
    <span fxFlex></span>
    <form [formGroup]="applicantForm">
      <span fxFlex></span>
      <mat-card fxLayoutGap="8px" fxLayout="column">
        <div fxLayout="row">
            <div fxLayout="column" fxLayoutGap="8px">
              <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('applicantPageCorrect').hasError('required')? 'red' : 'black'}">Is all the information on this screen current and correct?</span>
              <span class="text-wrap"><i>Some information may be outdated or incorrect but you cannot change it on this application.
                These changes require additional documentation and possibly additional fees. These changes are made by a Change Request.
                If information is incorrect or outdated, please select "No," below and follow the instructions for a Change Request.</i></span>
              <mat-radio-group aria-label="Select an option" formControlName="applicantPageCorrect" fxLayoutGap="8px">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="applicantForm.get('applicantPageCorrect').hasError('required')">This field is required</mat-error>
            </div>
        </div>

        <div fxLayout="row" *ngIf="applicantForm.get('applicantPageCorrect').value == false">
          <div fxLayout="column">
             <mat-form-field>
              <textarea rows="2" matInput placeholder="Please list the information that is not current and correct" formControlName="applicantPageCorrectReason"></textarea>
               <mat-error *ngIf="validateApplicantPageCorrect()">
                This field is required
              </mat-error>
             </mat-form-field>
             <span class="text-wrap">You are required to submit a Change Request to update this information. For more information on Change Requests, please see the TLC Home Page, Change Requests section. This is the first page you see when you log into TLC.</span>
          </div>
        </div>
      </mat-card>
    </form>
  </div>
  <mat-card-content>
    <!--Applicant Form-->
    <form [formGroup]="applicantForm">
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <!--Contact Information-->
          <mat-card>
            <mat-card-title>
              Contact Information
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
                  <span class="f-s-16"><strong>Legal entity name</strong></span>
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput formControlName="entityName">
                    <mat-placeholder style="color:red" *ngIf="applicantForm.get('entityName').value == ''">Entity Name*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="applicantForm.get('entityName').value != ''">Entity Name</mat-placeholder>
                    <mat-error *ngIf="applicantForm.get('entityName').invalid">
                      Please provide the legal entity name
                    </mat-error>
                  </mat-form-field>

                  <span class="f-s-16"><strong>Registered Business</strong></span>
                  <div fxLayout="row">
                    <div fxLayout="column">
                      <span class="text-wrap">Is the applicant registered as a business with the Secretary of State (SOS) in Oregon?</span>
                      <mat-radio-group aria-label="Select an option" formControlName="businessInOregon" fxLayoutGap="8px" [disabled]="renewal">
                        <mat-radio-button [value]="true" >Yes</mat-radio-button>
                        <mat-radio-button [value]="false" >No</mat-radio-button>
                      </mat-radio-group>
                      <mat-error *ngIf="validateBusinessInOregon()">This field is required</mat-error>
                    </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="8px" *ngIf="applicantForm.get('businessInOregon').value == true">
                      <div fxLayout="column">
                        <span>Oregon Secretary of State Registration Number</span>
                        <mat-form-field>
                          <input matInput placeholder="Registry Number" formControlName="secretaryofStateNumber" [readonly]="renewal">
                        </mat-form-field>
                        <mat-error *ngIf="validateSecretaryofStateNumber()">
                          Secretary of State Registration Number is required
                        </mat-error>
                      </div>
                    </div>
                    <div fxLayout="row" *ngIf="!applicantForm.get('businessInOregon').value">
                      <div fxLayout="column">
                      <span class="text-wrap">Is the applicant registered as a business in another state, US territory or a freely associated state?</span>
                      <mat-radio-group aria-label="Select an option" formControlName="registeredBusiness" fxLayoutGap="8px" [disabled]="renewal">
                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false">No</mat-radio-button>
                      </mat-radio-group>
                      <mat-error *ngIf="validateBusinessInOtherState()">This field is required</mat-error>
                    </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="8px" *ngIf="applicantForm.get('registeredBusiness').value == true && applicantForm.get('businessInOregon').value == false">
                      <div fxLayout="column">
                        <span>Please provide the location (state, US territory, or freely associated state) where the Service Center is registered as a business and the registration number.</span>

                        <mat-form-field>
                          <input matInput placeholder="Location Registered" formControlName="registeredIn" [readonly]="renewal">
                        </mat-form-field>
                        <mat-error *ngIf="validateRegisteredLocation()">Business Location is required</mat-error>

                        <mat-form-field>
                          <input matInput placeholder="Registration Number" formControlName="registryNumber" [readonly]="renewal">
                        </mat-form-field>
                        <mat-error *ngIf="validateRegistryNumber()">Registration Number is required</mat-error>
                      </div>
                    </div>
                    <mat-checkbox formControlName="registrationAttestation">
                      <span [ngClass]="!applicantForm.get('registrationAttestation').value && applicantForm.get('registrationAttestation').touched? 'text-red-400' : ''"
                            class="text-wrap"><strong>I understand licensees that are required to be registered with the Oregon Secretary of State must register prior
                              to receiving a license and maintain registration per OAR 333-333-4400 (11).</strong></span>
                    </mat-checkbox>

                  <div fxLayout="column">
                    <span class="f-2-20"><strong>Other OPS Licenses or Permits</strong></span>
                    <mat-checkbox formControlName="previousLicense" (change)="validatePreviousLicense()">
                      <span class="text-wrap">
                        Check the box if the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services
                      </span>
                    </mat-checkbox>
                    <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                      <span>Please provide the license id(s) for each Oregon Psilocybin Services license.</span>
                      <mat-form-field>
                        <input matInput formControlName="affiliatedLicenses"
                          (blur)="validatePreviousLicense()">
                          <mat-placeholder style="color:red" *ngIf="applicantForm.get('affiliatedLicenses').value == ''">Affiliated Licenses*</mat-placeholder>
                          <mat-placeholder style="color:grey" *ngIf="applicantForm.get('affiliatedLicenses').value != ''">Affiliated Licenses</mat-placeholder>
                        <mat-error *ngIf="validatePreviousLicense()">
                          Please provide all license numbers
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                      <span class="f-s-16"><strong>Contact Phone</strong></span>
                      <mat-form-field>
                        <input matInput formControlName="primaryContactNumber"
                          mask="(000) 000-0000 000000" [validation]="false">
                          <mat-placeholder style="color:red" *ngIf="applicantForm.get('primaryContactNumber').value == ''">Phone*</mat-placeholder>
                          <mat-placeholder style="color:grey" *ngIf="applicantForm.get('primaryContactNumber').value != ''">Phone</mat-placeholder>
                        <mat-error *ngIf="applicantForm.get('primaryContactNumber').invalid">
                          A name is required
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                      <span class="f-s-16"><strong>Preferred Language</strong></span>
                      <mat-form-field>
                        <input matInput placeholder="Language" formControlName="language">
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                      <span class="f-s-16"><strong>Email Address</strong></span>
                      <mat-form-field>
                        <input matInput formControlName="primaryContactEmail">
                        <mat-placeholder style="color:red" *ngIf="applicantForm.get('primaryContactEmail').value == ''">Email*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('primaryContactEmail').value != ''">Email</mat-placeholder>
                        <mat-error *ngIf="applicantForm.get('primaryContactEmail').hasError('required')">
                          Please enter an email adress
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('primaryContactEmail').hasError('email')">
                          This doesn't look like a valid email address
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                      <span class="f-s-16"><strong>Confirm Email Address</strong></span>
                      <mat-form-field>
                        <input matInput formControlName="confirmEmail">
                        <mat-placeholder style="color:red" *ngIf="applicantForm.get('confirmEmail').value == ''">Confirm Email*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('confirmEmail').value != ''">Confirm Email</mat-placeholder>
                        <mat-error *ngIf="validateEmail()">
                          Email does not match
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <!--Addresses-->
          <mat-card fxFlex="60" fxFlex.lt-md="100">
            <mat-card-title>Addresses</mat-card-title>
            <mat-card-content>
              <div fxLayout="column">
                <span class="f-s-18"><strong>Headquarters Address</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                    <input matInput formControlName="headquartersStreet">
                    <mat-placeholder style="color:red" *ngIf="applicantForm.get('headquartersStreet').value == ''">Street Apt/Unit/Suite*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="applicantForm.get('headquartersStreet').value != ''">Street Apt/Unit/Suite</mat-placeholder>
                    <mat-error *ngIf="applicantForm.get('headquartersStreet').invalid">
                      Please provide a street
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                    <input matInput formControlName="headquartersCity">
                    <mat-placeholder style="color:red" *ngIf="applicantForm.get('headquartersCity').value == ''">City*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="applicantForm.get('headquartersCity').value != ''">City</mat-placeholder>
                    <mat-error *ngIf="applicantForm.get('headquartersCity').invalid">
                      Please provide a city
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <mat-label style="color:red" *ngIf="applicantForm.get('headquartersState').value == ''">State/US territory/freely associated state*</mat-label>
                    <mat-label *ngIf="applicantForm.get('headquartersState').value != ''">State/US territory/freely associated state</mat-label>
                    <mat-select formControlName="headquartersState">
                      <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="applicantForm.get('headquartersState').invalid">
                      Please select a state
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                    <input matInput formControlName="headquartersZip">
                    <mat-placeholder style="color:red" *ngIf="applicantForm.get('headquartersZip').value == ''">Zip*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="applicantForm.get('headquartersZip').value != ''">Zip</mat-placeholder>
                    <mat-error *ngIf="applicantForm.get('headquartersZip').hasError('required')">
                      Please provide a zipcode
                    </mat-error>
                    <mat-error *ngIf="applicantForm.get('headquartersZip').hasError('pattern')">
                      Zipcode should match this format: 99999 or 99999-1234
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                    *ngIf="applicantForm.get('headquartersState').value === 'OR'">
                    <mat-placeholder style="color:red" *ngIf="applicantForm.get('headquartersCounty').value == ''">County*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="applicantForm.get('headquartersCounty').value != ''">County</mat-placeholder>
                    <mat-select formControlName="headquartersCounty">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="column">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                  <span class="f-s-18"><strong>Physical Address</strong></span>
                  <mat-checkbox formControlName="physicalIsHeadquarters" (change)="physicalIsSame()">
                    Same as Headquarters
                  </mat-checkbox>
                </div>
                <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('physicalIsHeadquarters').value">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalStreet"
                        (blur)="validatePhysicalStreet()">
                        <mat-placeholder style="color:red" *ngIf="applicantForm.get('physicalStreet').value == ''">Street Apt/Unit/Suite*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('physicalStreet').value != ''">Street Apt/Unit/Suite</mat-placeholder>
                      <mat-error *ngIf="validatePhysicalStreet()">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalCity" (blur)="validatePhysicalCity()">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('physicalCity').value == ''">City*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('physicalCity').value != ''">City</mat-placeholder>
                      <mat-error *ngIf="validatePhysicalCity()">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label style="color:red" *ngIf="applicantForm.get('physicalState').value == ''">State/US territory/freely associated state*</mat-label>
                      <mat-label *ngIf="applicantForm.get('physicalState').value != ''">State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="physicalState" (blur)="validatePhysicalState()">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="validatePhysicalState()">
                        Please provide a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalZip" (blur)="validatePhysicalZip()">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('physicalZip').value == ''">Zip*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('physicalZip').value != ''">Zip</mat-placeholder>
                      <mat-error *ngIf="validatePhysicalZip()">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('physicalState').value === 'OR'">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('physicalCounty').value == ''">County*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('physicalCounty').value != ''">County</mat-placeholder>
                      <mat-select formControlName="physicalCounty">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div fxLayout="column">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <span class="f-s-18"><strong>Mailing Address</strong></span>
                  <mat-checkbox formControlName="mailingIsHeadquarters" (change)="mailingIsSame()"
                    *ngIf="!applicantForm.get('mailingIsPhysical').value">
                    Same as Headquarters
                  </mat-checkbox>
                  <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()"
                    *ngIf="!applicantForm.get('mailingIsHeadquarters').value">
                    Same as Physical
                  </mat-checkbox>
                </div>

                <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsHeadquarters').value &&
                                                        !applicantForm.get('mailingIsPhysical').value">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="mailingStreet"
                        (blur)="validateMailingStreet()">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('mailingStreet').value == ''">Street Apt/Unit/Suite*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('mailingStreet').value != ''">Street Apt/Unit/Suite</mat-placeholder>
                      <mat-error *ngIf="validateMailingStreet()">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="mailingCity" (blur)="validateMailingCity()">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('mailingCity').value == ''">City*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('mailingCity').value != ''">City</mat-placeholder>
                      <mat-error *ngIf="validateMailingCity()">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label style="color:red" *ngIf="applicantForm.get('mailingState').value == ''">State/US territory/freely associated state*</mat-label>
                      <mat-label *ngIf="applicantForm.get('mailingState').value != ''">State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="mailingState" (blur)="validateMailingState()">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="validateMailingState()">
                        Please provide a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="mailingZip" (blur)="validateMailingZip()">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('mailingZip').value == ''">Zip*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('mailingZip').value != ''">Zip</mat-placeholder>
                      <mat-error *ngIf="validateMailingZip()">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('mailingState').value === 'OR'">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('mailingCounty').value == ''">County*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('mailingCounty').value != ''">County</mat-placeholder>
                      <mat-select formControlName="mailingCounty">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

              </div>

            </mat-card-content>
          </mat-card>

          <!--Individual Applicants-->
          <mat-card fxFlex="40" fxFlex.lt-md="100">
            <mat-card-title>
              Individual Applicants
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <span>Available Applicants</span>
                  <span fxFlex></span>
                  <span *ngIf="!renewal">Click </span>
                  <mat-icon color="primary" *ngIf="!renewal">add</mat-icon>
                  <span *ngIf="!renewal">To Associate An Applicant</span>
                </div>
                <mat-table [dataSource]="applicantSource" matSort #applicantSort="matSort" aria-describedby="applicants"
                  class="mat-elevation-z8 p-t-0">
                  <ng-container matColumnDef="legalFirstName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
                    <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
                      <span class="mobile-label">First</span>
                      {{ applicant.legalFirstName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="legalLastName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
                    <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
                      <span class="mobile-label">Last</span>
                      {{ applicant.legalLastName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
                      <button *ngIf="!licenseLocked && !renewal" type="button" mat-icon-button
                        (click)="addIndividualApplicant(applicant.id)" color="primary" aria-label="Associate Applicant">
                        <mat-icon>add</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="applicantColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: applicantColumns"></mat-row>
                </mat-table>
                <mat-paginator [pageSizeOptions]="[5, 20, 50]" #applicantPaginator="matPaginator">
                </mat-paginator>

                <span>Associated Applicants</span>
                <mat-table [dataSource]="associatedApplicantSource" matSort #associatedApplicantSort="matSort"
                  aria-describedby="Associated Applicants" class="mat-elevation-z8 p-t-0">
                  <ng-container matColumnDef="legalFirstName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
                    <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
                      <span class="mobile-label">First</span>
                      {{ applicant.legalFirstName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="legalLastName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
                    <mat-cell [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
                      <span class="mobile-label">Last</span>
                      {{ applicant.legalLastName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
                      <button *ngIf="!licenseLocked && !renewal" type="button" mat-icon-button class="m-8"
                        (click)="deleteIndividualApplicant(applicant.id)" color="warn"
                        aria-label="Remove Applicant Association">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="applicantColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: applicantColumns"></mat-row>
                </mat-table>
                <mat-paginator [pageSizeOptions]="[5, 20, 50]" #associatedApplicantPaginator="matPaginator">
                </mat-paginator>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <!--Upload Documents-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <span *ngIf="documentsValid">
                Supporting Documents
              </span>
              <span *ngIf="!documentsValid" class="text-red-400">
                Supporting Documents Incomplete
              </span>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap.lt-lg="8px">
                <span>You must provide a completed entity applicant information form</span>
                <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
                  <input type="file" accept=".doc,.docx,.rtf,.xls,.xlsx,.pdf,.txt,.png,.jpg,.jpeg,.bmp,.mp4" class="file-input" (change)="uploadDocument($event)" #entityApplicantFileUpload />
                  <div fxLayout="row" fxLayoutGap="8px">
                    <div class="file-upload">
                      <button type="button" mat-raised-button color="primary" class="upload-btn"
                        (click)="entityApplicantFileUpload.click()">
                        Browse
                        <mat-icon>attach_file</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <!--Current Documents-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <span *ngIf="documentsValid">Current Documents</span>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column">
                <div fxLayout="row" *ngIf="!sharedService.mobile">
                  <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                  <span class="f-s-16"><strong>Requirement</strong></span>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                  *ngFor="let document of applicant.applicantDocuments">
                  <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer"
                    (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>
                  <div fxFlex fxFlex.lt-md="" fxLayout="row" fxLayoutAlign="start center">
                    <span>Applicant Information</span>
                    <button *ngIf="!licenseLocked && !document.deprecated" type="button" mat-icon-button class="m-8"
                      (click)="deleteFile(document.id, document.name)" color="warn" aria-label="Delete">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                  <div fxFlex fxFlex.lt-md="" fxLayout="row" fxLayoutAlign="start center">
                    <span *ngIf="document.deprecated" style="color:red">Deprecated</span>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
