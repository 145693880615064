import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { environment } from '@env/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InternalApplicationMessage, InternalApplicationNewMessage, InternalApplicationRecipient } from './internal-app-message';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {InternalApplicationMessagingService} from './internal-app-messaging.service'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SharedService } from 'app/services/core/shared.service';
import { UserLicense } from 'app/models/user/user-profile';
import { UserService } from 'app/services/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-internal-app-messaging',
  templateUrl: './internal-app-messaging.component.html',
  styleUrls: ['./internal-app-messaging.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class InternalAppMessagingComponent implements OnInit {
  public environment = environment;
  messages: InternalApplicationMessage[] = [];
  includeArchived: boolean = false;
  expandedElement: InternalApplicationMessage | null;
  currentView: string = "";
  processing: boolean = false;
  displayedColumns: string[] = ['icons','subject', 'recipient', 'creator', 'license', 'createdDate'];
  dataSource = new MatTableDataSource<InternalApplicationMessage>(this.messages);

  @ViewChild('TableOnePaginator') paginator: MatPaginator;
  @ViewChild('TableOneSort') sort: MatSort;

  constructor(public dialog: MatDialog,
              public sharedService: SharedService,
              public internalApplicationMessagingService: InternalApplicationMessagingService,
              private router: Router) {}

  ngOnInit() {
    if(!environment.internalMessaging.enabled) {
      this.router.navigate(['sessions/403']);
    }
    this.loadInbox();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filterTable(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  open(message: InternalApplicationMessage) {
    let messageIndex = this.messages.indexOf(message);
    if(messageIndex >= 0){
      if(!this.messages[messageIndex].opened) {
        if(this.messages[messageIndex].isBroadcast) {
          this.internalApplicationMessagingService.openedBroadcast(this.messages[messageIndex].id);
        }
        else {
          this.internalApplicationMessagingService.opened(this.messages[messageIndex].id);
        }
        this.messages[messageIndex].opened = true;
      }
    }
  }

  archive(messageId: number) {
    let m = this.messages.find(f => f.id == messageId);
    if(m != undefined && m != null) {
      this.processing = true;
      if(m.isBroadcast) {
        this.internalApplicationMessagingService.archiveBroadcast(messageId).subscribe(result => {
          this.processing = false;
          this.refresh();
        }, error => {
          console.error(error);
          this.processing = false;
        });
      }
      else {
        this.internalApplicationMessagingService.archive(messageId).subscribe(result => {
          this.processing = false;
          this.refresh();
        }, error => {
          console.error(error);
          this.processing = false;
        });
      }
    }
  }

  unarchive(messageId: number) {
    let m = this.messages.find(f => f.id == messageId);
    if(m != undefined && m != null) {
      this.processing = true;
      if(m.isBroadcast) {
        this.internalApplicationMessagingService.unarchiveBroadcast(messageId).subscribe(result => {
          this.processing = false;
          m.archived = false;
        }, error => {
          console.error(error);
          this.processing = false;
        });
      }
      else {
        this.internalApplicationMessagingService.unarchive(messageId).subscribe(result => {
          this.processing = false;
           m.archived = false;
        }, error => {
          console.error(error);
          this.processing = false;
        });
      }
    }
  }

  downloadAttachment(id: number, name: string) {
    this.internalApplicationMessagingService.download(id).subscribe(
      (response) => { this.internalApplicationMessagingService.saveFile(name, response); },
      (error) => console.log("error", error)
    );
  }

  refresh(){
    if(this.currentView == this.internalApplicationMessagingService.inboxView){
      this.loadInbox();
    }
    else if(this.currentView == this.internalApplicationMessagingService.unreadView){
      this.loadUnread();
    }
    else if(this.currentView == this.internalApplicationMessagingService.sentView){
      this.loadSent();
    }
  }

  updateMessageTable(): void {
    this.dataSource.data = this.messages;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadInbox() {
    this.processing = true;
    this.internalApplicationMessagingService.loadUnreadCount();
    this.internalApplicationMessagingService.getInbox(this.includeArchived).subscribe(result => {
      this.messages = result;
      this.currentView = this.internalApplicationMessagingService.inboxView;
      this.processing = false;
    }, error => {
      console.error(error);
      this.processing = false;
    },
    () => {
      this.updateMessageTable();
    });
  }

  loadSent() {
    this.processing = true;
    this.internalApplicationMessagingService.getSent(this.includeArchived).subscribe(result => {
      this.messages = result;
      this.currentView = this.internalApplicationMessagingService.sentView;
      this.processing = false;
    }, error => {
      console.error(error);
      this.processing = false;
    },
    () => {
      this.updateMessageTable();
    });
  }

  loadUnread() {
    this.processing = true;
    this.internalApplicationMessagingService.loadUnreadCount();
    this.internalApplicationMessagingService.getUnread().subscribe(result => {
      this.messages = result;
      this.processing = false;
      this.currentView = this.internalApplicationMessagingService.unreadView;
    }, error => {
      console.error(error);
      this.processing = false;
    },
    () => {
      this.updateMessageTable();
    });
  }

  openAddMessageDialog(messageId: string) {
    const dialogRef = this.dialog.open(DialogAddMessageComponent, {
      width: '1000px',
      data: {
        id: messageId
      },
      panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != "Cancel") {
        this.refresh();
      }
    });
  }

 }

/*
 * Add message conponent
 */
@Component({
  selector: 'dialog-add-message',
  templateUrl: 'add-message.html',
  styleUrls: ['./internal-app-messaging.component.scss']
})
export class DialogAddMessageComponent implements OnInit  {
  //@ViewChild("messageBody") messageBody;
  public loading: boolean = false;
  public sending: boolean = false;
  public recipients: InternalApplicationRecipient[] = [];
  public licenses: UserLicense[] = [];
  public currentFiles: string[] = [];
  public fileNames: string[] = [];
  public files: File[] = [];

  public message: InternalApplicationNewMessage = {
    id:0,
    subject: '',
    message:'',
    canRespond: true,
    creator:'',
    highPriority: false,
    isBroadcast: false,
    recipient: '',
    recipientEmail: '',
    recipientName: '',
    threadId: '',
    attachments: [],
    active: true,
    entityId: '',
    entityType: '',
    entityName: ''
  }

  public sendNewMessageForm = new UntypedFormGroup({
    recipient: new UntypedFormControl(''),
    subject: new UntypedFormControl(''),
    license: new UntypedFormControl(''),
    message: new UntypedFormControl(''),
    highPriority: new UntypedFormControl(false)
  });

  public env = environment;
  public replyId = 0;

  constructor(private toastr: ToastrService,
              private dialogRef: MatDialogRef<DialogAddMessageComponent>,
              public internalApplicationMessagingService: InternalApplicationMessagingService,
              public userService: UserService,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                if(this.data != undefined  && this.data.id != undefined && this.data.id > 0) {
                  this.replyId = data.id;
                }
  }

  ngOnInit(): void {
    this.loadRecipients();
    if (this.replyId == 0) {
      this.loadUserLicenses();
    }
  }


  onFileSelected(event): void {
    let newFiles: File[] = Array.from(event.target.files);
    newFiles.forEach((file) => {
      if(this.sharedService.validateFile(file))
      {
        this.files.push(file);
        this.fileNames.push(file.name);
      }
      else
      this.toastr.error("Unsupported File Type");
    });
  }

  removeStagedFile(fileName: string): void {
    this.files = this.files.filter((file) => file.name !== fileName);
    this.fileNames = this.fileNames.filter((name) => name !== fileName);
  }

  loadUserLicenses() {
    this.userService.getUserLicenses().subscribe(
      result => {
        if (result != undefined && result != null) {
          result.forEach(r => {
            const existingLicense = this.licenses.find(l => l.licenseId === r.licenseId);
            if (!existingLicense)
            this.licenses.push({
              id: r.id,
              name: r.name,
              payments: [],
              licenseId: r.licenseId,
              status: r.status,
              type: r.type
            });
          });
          if (result.length == 1) {
            this.message.entityId = result[0].licenseId;
            this.message.entityType = result[0].type;
            this.message.entityName = result[0].name;
            this.sendNewMessageForm.get('license').setValue(result[0].licenseId);
          }
        }
      },
      error => {
        this.toastr.error('Unable get user infomation');
      });
  }

  loadRecipients() {
    this.loading = true;
    if (this.replyId > 0) {
      //Go get the sender info based on the message id
      this.internalApplicationMessagingService.getMessage(this.replyId).subscribe(result => {
        this.recipients.push({
          recipient: result.creator,
          name: result.creatorName,
          email: result.creatorEmail
        });
        this.sendNewMessageForm.get('subject').setValue("RE: " + result.subject);
        this.sendNewMessageForm.get('message').setValue('\n\n--------------- <From: ' + result.creatorName + '> ---------------\n' + result.message);
        this.sendNewMessageForm.get('recipient').setValue(result.creator);
        this.message.recipient = result.creator;
        this.message.recipientName = result.creatorName;
        this.message.recipientEmail = result.creatorEmail;
        this.message.entityId = result.entityId;
        this.message.entityType = result.entityType;
        this.message.entityName = result.entityName;
        this.sendNewMessageForm.get('license').setValue(result.entityId);
        this.licenses.push(
          {
            type: result.entityType,
            name: result.entityName,
            payments: undefined,
            licenseId: result.entityId,
            id: 0,
            status: ''
          });
        this.loading = false;
      }, error => {
        this.loading = false;
        this.toastr.error('Unable get reply message infomation');
      });
    }
    else {
      //Add in the default options
      this.env.internalMessaging.defaultStaffRecipients.forEach(element => {
        this.recipients.push({
          recipient: element.recipient,
          name: element.name,
          email: element.email
        })
      });
      this.loading = false;
    }
  }

  sendMessage() {
    this.message.subject = this.sendNewMessageForm.value.subject;
    this.message.message = this.sendNewMessageForm.value.message;
    this.message.highPriority = this.sendNewMessageForm.value.highPriority;

    if (this.message.recipient == undefined || this.message.recipient.length == 0) {
      this.toastr.error("Please select a To recipient");
      return;
    }
    if (this.message.subject == undefined || this.message.subject.length == 0) {
      this.toastr.error("Please enter a Subject");
      return;
    }
    if (this.message.message == undefined || this.message.message.length == 0) {
      this.toastr.error("Please enter a Message");
      return;
    }

    if (this.message.entityId == undefined || this.message.entityId.length == 0 ||
      this.message.entityType == undefined || this.message.entityType.length == 0) {
      this.toastr.error("Select the license, permit. or training program you are messaging about.");
      return;
    }
    const formData = new FormData();
    formData.append("message", JSON.stringify(this.message));

    //attachements
    if (this.files.length > 0 && this.env.internalMessaging.canAttach) {
      this.files.forEach((file, index) => {
        return formData.append("file" + index, file, file.name);
      });
    }

    this.sending = true;
    this.internalApplicationMessagingService.sendMessage(formData).subscribe(result => {
      this.sending = false;
      this.toastr.success('We\'ve received your message. Thank you for your patience and understanding as we work to answer a high volume of questions');
      this.dialogRef.close();
    }, error => {
      this.sending = false;
      this.toastr.error('Unable to send Message');
      console.log(error);
    });
  }

  selectedToValue(event: MatSelectChange) {
    this.message.recipient = event.value;
    this.message.recipientName = event.source.triggerValue;
    let recipient = this.recipients.find(f => f.name == event.source.triggerValue);
    if (recipient != undefined) {
      this.message.recipientEmail = recipient.email;
    }
  }

  selectedLicenseValue(event: MatSelectChange) {
    let l = this.licenses.find(s => s.licenseId + " (" + s.name + ")" == event.source.triggerValue);
    if (l != undefined && l != null) {
      this.message.entityId = l.licenseId;
      this.message.entityType = l.type;
      this.message.entityName = l.name;
    }
  }

}



