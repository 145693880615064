import { Component, OnDestroy, ViewChild,
  HostBinding, ElementRef, ViewEncapsulation,
  ChangeDetectionStrategy,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { environment } from "environments/environment";
import { SettingsService } from "@core";
import { AuthService } from "app/auth/auth.service";
import { SharedService } from "app/services/core/shared.service";

const MOBILE_MEDIAQUERY = "screen and (max-width: 599px)";
const TABLET_MEDIAQUERY = "screen and (min-width: 600px) and (max-width: 959px)";
const MONITOR_MEDIAQUERY = "screen and (min-width: 960px)";

@Component({
  selector: "app-default-layout",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DefaultLayoutComponent implements OnDestroy {
  @ViewChild("sidenav") sidenav: MatSidenav;
  @ViewChild("content", { static: true }) content: MatSidenavContent;

  options = this.settings.getOptions();
  isIframe: boolean = false;

  private environment = environment;
  private layoutChangesSubscription: Subscription;
  private themeSubscription: Subscription;
  public build: string = null;
  public production: boolean = true;
  public useTimeout: boolean = false;
  private isMobileScreen = false;
  private isContentWidthFixed = true;
  private isCollapsedWidthFixed = false;

  get isMobile(): boolean {
    return this.isMobileScreen;
  }

  @HostBinding("class.matero-content-width-fix") get contentWidthFix() {
    return (
      this.isContentWidthFixed &&
      this.options.navPos === "side" &&
      this.options.sidenavOpened &&
      !this.isMobile
    );
  }

  @HostBinding("class.matero-sidenav-collapsed-fix") get collapsedWidthFix() {
    return (
      this.isCollapsedWidthFixed &&
      (this.options.navPos === "top" ||
        (this.options.sidenavOpened && this.isMobile))
    );
  }

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private element: ElementRef,
    public sharedService: SharedService,
    private authService: AuthService,
    private settings: SettingsService
  ) {
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY, MONITOR_MEDIAQUERY])
      .subscribe((state) => {
        if (this.sidenavOpen()) {
          this.options.sidenavOpened = true;
        }
        this.isMobileScreen = state.breakpoints[MOBILE_MEDIAQUERY];
        this.sharedService.mobile = state.breakpoints[MOBILE_MEDIAQUERY];
        this.options.sidenavCollapsed = state.breakpoints[TABLET_MEDIAQUERY];
        this.sharedService.tablet = state.breakpoints[TABLET_MEDIAQUERY];
        this.isContentWidthFixed = state.breakpoints[MONITOR_MEDIAQUERY];
      });

    this.themeSubscription = this.sharedService.themeChanged$.subscribe(
      () => this.setTheme()
    );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        this.content.scrollTo({ top: 0 });
      });

    // Initialize project theme
    this.setTheme();
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.useTimeout = environment.useTimeout;
    this.build = environment.build;
    this.production = environment.production;
    if (!this.isMobileScreen && this.environment.collapsibleSidebar) {
      this.toggleCollapsed();
    }
    if (!this.sidenavOpen()) {
      this.sidenavOpenedChange(false);
    }
  }

  sidenavOpen(): boolean {
    return (
      this.options.navPos === "side" &&
      this.options.sidenavOpened &&
      !this.isMobile &&
      environment.displaySidebar &&
      this.authService.isAuthenticated()
    );
  }

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
    this.themeSubscription.unsubscribe();
  }

  toggleCollapsed() {
    this.isContentWidthFixed = false;
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }

  resetCollapsedState(timer = 400) {
    setTimeout(() => this.settings.setOptions(this.options), timer);
  }

  sidenavCloseStart() {
    this.isContentWidthFixed = false;
  }

  sidenavOpenedChange(isOpened: boolean) {
    this.isCollapsedWidthFixed = !this.isMobile;
    this.options.sidenavOpened = isOpened;
    this.settings.setOptions(this.options);
  }

  setTheme() {
    if (this.sharedService.userProfile.theme === "dark") {
      this.element.nativeElement.classList.add("theme-dark");
    } else {
      this.element.nativeElement.classList.remove("theme-dark");
    }
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }
}
