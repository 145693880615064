import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IDirectoryItem } from "app/models/directory/directory-item";
import { IQrCodeInformation } from "app/models/licenses/Qr-Code-Information";
import { Observable } from "rxjs";

@Injectable(
  { providedIn: 'root' }
)
export class PublicLicensesService {
  private api: string = environment.publicApi + 'ApprovedLicenses/';

  constructor(private http: HttpClient) { }

  getApprovedLicenses(licenseType: string, licenseId: string): Observable<IQrCodeInformation>{
    return this.http.get<IQrCodeInformation>(this.api + 'GetLicense/' + licenseType + '/' + licenseId);
  }

  getConsentingLicenses(): Observable<IDirectoryItem[]>{
    return this.http.get<IDirectoryItem[]>(this.api + 'GetConsentingLicenses');
  }
}
