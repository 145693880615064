import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmDialog {
  message: string;
  confirmation: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmDialog,
              private dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  public cancel(): void{
    this.dialogRef.close(false);
  }

  public confirm(): void{
    this.dialogRef.close(true);
  }

  @HostListener("keydown.esc")
  public onEsc(): void{
    this.dialogRef.close(false);
  }

}
