import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { IQrCodeInformation } from 'app/models/licenses/Qr-Code-Information';
import { SharedService } from 'app/services/core/shared.service';
import { QrCodeService } from 'app/services/QrCode/Qr-Code.service';

@Component({
  selector: 'app-license-information',
  templateUrl: './license-information.component.html'
})
export class LicenseInformationComponent implements OnInit {
 id: string;
 type: string;
 color: string;
 public url = environment.baseUrl;
 public currentLicense: IQrCodeInformation = {
   licenseNumber: '',
   status: '',
   activeDate: '',
   expirationDate: '',
   licenseType: '',
   renewedDate: '',
   isRenewal: false,
   canOperate: false
 }
 public loading: boolean = true;
  constructor(private route: ActivatedRoute,
          private qrCodeService: QrCodeService,
          public sharedService: SharedService,
          private router: Router,) { }

  ngOnInit(): void {
    this.loading = true;
    this.id = this.route.snapshot.paramMap.get('id');
    this.type = this.route.snapshot.paramMap.get('type');
    this.qrCodeService.getLicense(this.type, this.id).subscribe(
      response => this.currentLicense = response,
      error => console.log('error', error),
      () =>
      {
        this.loading = false;}
    );
    this.getColor();

  }

  getColor():void{
    if(this.type == 'FacilitatorLicense')
      this.color = this.sharedService.purple;
    if(this.type == 'WorkerPermit')
      this.color = this.sharedService.red;
    if(this.type == 'TrainingProgram')
      this.color = this.sharedService.blue;
    if(this.type == 'ManufacturerLicense')
      this.color = this.sharedService.gold;
    if(this.type == 'ServiceCenter')
      this.color = this.sharedService.green;
    if(this.type == 'TestingLab')
      this.color = this.sharedService.orange;
  }
}
