import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IExamAttempt } from 'app/models/exam/exam-attempt';

@Injectable({providedIn: 'root'})
export class ExamService {
  private api: string = environment.privateApi + 'Exam/';

  constructor(private http: HttpClient) { }

  GetRandomizedExamMap(): Observable<IExamAttempt>{
    return this.http.get<IExamAttempt>(this.api + 'GetRandomizedExamMap');
  }

  submitExamAttemptMap(examMap: IExamAttempt): Observable<IExamAttempt>{
    return this.http.post<IExamAttempt>(this.api + 'submitExamAttemptMap', examMap);
  }

}
