<div fxLayout="column" fxLayoutGap="8px" style="margin: 20px;">
  <div fxLayout="row" fxLayout.lt-md="column">
    <span fxFlex style="font-family: Oswald, sans-serif; font-size: 20pt;">The Oregon Psilocybin Services (OPS) License Directory contains information provided and owned by OPS licensees who have consented to having their information published.
      Please Note: The directory is not a comprehensive list of all OPS licensees and is not used for license verification. Please visit the <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-Licensee-Status.aspx" target="_blank" rel="noreferrer noopener" > OPS - Search for Licensee and Worker Permit Status webpage</a> to verify license or worker permit status.</span>
  </div>
  <mat-card>
    <mat-card>
      <mat-card-content>
        <form [formGroup]="SearchForm">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="8px">
            <mat-form-field fxFlex="35">
              <input matInput placeholder="Licensee" formControlName="licenseName">
            </mat-form-field>

            <mat-form-field fxFlex="35">
              <mat-label>
                License Type
              </mat-label>
              <mat-select formControlName="licenseType">
                <mat-option *ngFor="let type of licenseTypes" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button mat-stroked-button type="submit" color="primary" (click)="searchLicenses()"
              matTooltip="Search Worker Permits" matTooltipShowDelay=800 aria-label="Search Worker Permits">
              Search
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <div *ngIf="loading">
          <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
            <h1>Loading</h1>
          </div>
        </div>

        <mat-table [dataSource]="licensesSource" matSort aria-describedby="Directory" #licensesSort="matSort" class="mat-elevation-z8">
          <ng-container matColumnDef="licenseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Licensee</mat-header-cell>
            <mat-cell *matCellDef="let license" fxLayout="row" fxLayoutGap="4px">
              <span class="mobile-label">Licensee</span>
              <span>{{license.licenseName}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="licenseType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>License Type</mat-header-cell>
            <mat-cell *matCellDef="let license" fxLayout="row" fxLayoutGap="4px">
              <span class="mobile-label">License Type</span>
              <span>{{license.licenseType}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="directoryWebsite">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Website</mat-header-cell>
            <mat-cell *matCellDef="let license" fxLayout="row" fxLayoutGap="4px" style="padding: 8px 80px 8px 0;">
              <span class="mobile-label">Website</span>
              <span><a [href]=license.directoryWebsite target="_blank" rel="noreferrer noopener" style="word-break: break-all;
                      white-space: normal;">{{license.directoryWebsite}}</a></span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="directoryPhone">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</mat-header-cell>
            <mat-cell *matCellDef="let license" fxLayout="row" fxLayoutGap="4px">
              <span class="mobile-label">Phone Number</span>
              <span>{{license.directoryPhone | mask: '(000) 000-0000 000000'}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="directoryEmail">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
            <mat-cell *matCellDef="let license" fxLayout="row" fxLayoutGap="4px">
              <span class="mobile-label">Email</span>
              <span>{{license.directoryEmail}}</span>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="licensesColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: licensesColumns"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[25, 50, 100]" #licensesPaginator="matPaginator">
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </mat-card>
</div>


