<div fxLayout="column" *ngIf="applicant.id === 0">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="">
    <span class="f-s-18"><strong>Individual Applicants</strong></span>
    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
      <input matInput (keyup)="filterTable($event)" placeholder="Filter Applicants" />
    </mat-form-field>
    <span fxFlex></span>
    <span *ngIf="!licenseLocked">Click to Edit</span>
    <button *ngIf="!licenseLocked && !renewal"
            mat-icon-button
            type="button"
            color="primary"
            (click)="addApplicant()"
            aria-label="Add Applicant"
            matTooltip="Add Applicant"
            matTooltipPosition="below"
            [matTooltipShowDelay]="500">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <mat-table [dataSource]="dataSource" matSort #sort="matSort" aria-describedby="applicants"
    class="mat-elevation-z8 p-t-0">
    <ng-container matColumnDef="legalFirstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">First</span>
        {{ applicant.legalFirstName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="legalLastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Last</span>
        {{ applicant.legalLastName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="complete">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell (click)="editApplicant(applicant)" [ngClass]="tableColor" tabindex="0" *matCellDef="let applicant">
        <span class="mobile-label">Status</span>
        <span *ngIf="applicant.complete" class="text-light-green-600">Complete</span>
        <span *ngIf="!applicant.complete" class="text-red-400">Incomplete</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell [ngClass]="tableColor" *matCellDef="let applicant">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center" fxLayoutGap="8px" fxLayoutAlign.lt-lg="space-evenly start">
          <button *ngIf="!licenseLocked && applicant.applicantId !== primaryContactId && !renewal" mat-flat-button class="m-8"
            (click)="selectPointOfContact(applicant)" color="primary" aria-label="Select As Point of Contact"
            style="line-height: normal">
            <span class="text-wrap">Make Primary Contact</span>
          </button>

          <button *ngIf="!licenseLocked && !renewal && status == 'Pending'" mat-icon-button (click)="deleteApplicant(applicant)" color="warn"
            aria-label="Delete Applicant">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 20, 50]" #paginator="matPaginator">
  </mat-paginator>
</div>

<mat-card [style.background-color]="cardColor" *ngIf="applicant.id > 0">
  <mat-card-title>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center">
      <span>{{addingApplicant? 'Add New Applicant' : 'Editing ' + applicant.legalFirstName + ' ' + applicant.legalLastName}}</span>
      <span fxFlex></span>
      <button mat-flat-button type="button" color="warn" (click)="cancel()">{{addingApplicant? 'Cancel' : 'Close'}}</button>
      <button *ngIf="!licenseLocked" mat-flat-button type="button" color="primary"
        (click)="saveApplicant(false)">Save</button>
      <button *ngIf="!licenseLocked" mat-flat-button type="button" color="primary"
        (click)="saveApplicant(true)">Save and Close</button>
    </div>
  </mat-card-title>

  <div fxLayout="row" *ngIf="renewal">
    <span fxFlex></span>
    <form [formGroup]="applicantForm">
      <span fxFlex></span>
      <mat-card fxLayoutGap="8px" fxLayout="column">
        <div fxLayout="row">
            <div fxLayout="column" fxLayoutGap="8px">
              <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('applicantPageCorrect').value != true && applicantForm.get('applicantPageCorrect').value != false? 'red' : ''}">Is all the information on this screen current and correct?</span>
              <span class="text-wrap"><i>Some information may be outdated or incorrect but you cannot change it on this application.
                These changes require additional documentation and possibly additional fees. These changes are made by a Change Request.
                If information is incorrect or outdated, please select "No," below and follow the instructions for a Change Request.</i></span>
              <mat-radio-group aria-label="Select an option" formControlName="applicantPageCorrect" fxLayoutGap="8px">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="applicantForm.get('applicantPageCorrect').hasError('required')">This field is required</mat-error>
            </div>
        </div>

        <div fxLayout="row" *ngIf="applicantForm.get('applicantPageCorrect').value == false">
          <div fxLayout="column">
             <mat-form-field>
              <textarea rows="2" matInput placeholder="Please list the information that is not current and correct" formControlName="applicantPageCorrectReason"></textarea>
               <mat-error *ngIf="validateApplicantPageCorrect()">
                This field is required
              </mat-error>
             </mat-form-field>
             <span class="text-wrap">You are required to submit a Change Request to update this information. For more information on Change Requests, please see the TLC Home Page, Change Requests section. This is the first page you see when you log into TLC.</span>
          </div>
        </div>
      </mat-card>
    </form>
  </div>

  <mat-card-content>
    <!--Applicant Form-->
    <form [formGroup]="applicantForm">
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <!--Personal Information-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <div fxLayout="row" fxLayout.lt-lg="column">
                <span fxFlex>Personal Information</span>
              </div>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <span><strong>Legal Name</strong><span style="color: red" *ngIf="applicantForm.get('legalFirstName').value =='' || applicantForm.get('legalLastName').value ==''">*</span></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput  formControlName="legalFirstName">
                    <mat-placeholder style="color:red" *ngIf="applicantForm.get('legalFirstName').value ==''">First*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="applicantForm.get('legalFirstName').value !=''">First</mat-placeholder>
                    <mat-error *ngIf="applicantForm.get('legalFirstName').invalid">
                      Please enter your first name
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput formControlName="legalLastName">
                    <mat-placeholder style="color:red" *ngIf="applicantForm.get('legalLastName').value ==''">Last*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="applicantForm.get('legalLastName').value !=''">Last</mat-placeholder>
                    <mat-error *ngIf="applicantForm.get('legalLastName').invalid">
                      Please enter your last name
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Middle" formControlName="legalMiddleName">
                  </mat-form-field>
                </div>

                <span><strong>Preferred Name</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="First" formControlName="preferredFirstName">
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <input matInput placeholder="Last" formControlName="preferredLastName">
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Middle" formControlName="preferredMiddleName">
                  </mat-form-field>
                </div>

                <div fxLayout="column">
                  <span><strong>Prior names and aliases </strong></span>
                  <span>This information is used for the purposes of the background check</span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
                    <mat-form-field fxFlex="80" fxFlex.lt-md="100">
                      <input matInput placeholder="Prior names and aliases " formControlName="alternateNames">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label style="color: red" *ngIf="applicantForm.get('dob').value == '' ||
                                                             applicantForm.get('dob').value == null">Date of Birth*</mat-label>
                      <mat-label *ngIf="applicantForm.get('dob').value != '' &&
                                        applicantForm.get('dob').value != null">Date of Birth</mat-label>
                      <input placeholder="mm/dd/yyyy" formControlName="dob" matInput [matDatepicker]="dob"
                        [max]="sharedService.today"/>
                      <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                      <mat-datepicker #dob [disabled]="renewal">
                        <mat-datepicker-actions>
                          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                          <button mat-button matDatepickerCancel>Cancel</button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                      <mat-error *ngIf="applicantForm.get('dob').value > sharedService.today">
                        Please choose an earlier date.
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('dob').hasError('required')">
                        Date of birth is required
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>

                <div fxLayout="column">
                  <span><strong>*SOCIAL SECURITY NUMBER DISCLOSURE:</strong></span>
                    <span style="padding-top: 1em; padding-left: 1em"><strong>• </strong>
                      As part of your application for an initial license or license renewal, Federal and
                      State laws require you to provide your Social Security Number (SSN), if you have one, to Oregon Psilocybin Services for child support enforcement
                      purposes (42 USC § 666(a)(13) & ORS 25.785). If you are an applicant or licensee and you have an SSN but fail to provide your SSN, Oregon
                      Psilocybin Services may refuse to process your application. Your SSN will be used only for child support enforcement purposes
                      unless you confirm your agreement that OPS can use it for administrative purposes as well.
                    </span>

                    <span style="padding-top: 1em; padding-left: 1em">
                      <strong>IMPORTANT NOTE: If you do not have a social security number, you may still be licensed or permitted by Oregon Psilocybin Services. </strong>
                    </span>

                    <span style="padding-top: 1em; padding-left: 1em"><strong>• </strong>
                      Based on our authority under OAR 333-333-4100, we are requesting your voluntary consent to use your SSN for the following
                      administrative purposes only: to positively confirm your identity during the criminal records check. Oregon Psilocybin Services will not
                      deny you any rights, benefits or privileges otherwise provided by law if you do not consent to use of your SSN for these administrative
                      purposes (5 USC§ 552(a)).
                      <strong>Please check the appropriate box next to the social security field indicating whether you consent or do not consent.</strong>
                    </span>

                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg=""
                    fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                    <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                      <input matInput formControlName="ssn" mask="000-00-0000">
                      <mat-placeholder style="color:red" *ngIf="!applicantForm.get('noSsn').value && applicantForm.get('ssn').value == ''">SSN*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('noSsn').value || applicantForm.get('ssn').value != ''">SSN</mat-placeholder>
                      <mat-error *ngIf="validateSsn()">
                        SSN is Required
                      </mat-error>
                    </mat-form-field>
                    <mat-checkbox formControlName="noSsn">
                      <span class="text-wrap">Applicant doesn't have a social security number</span>
                    </mat-checkbox>
                  </div>
                  <span [ngStyle]="{'color': validateSsnAttestation()? 'red' : ''}">Do you consent to use of SSN for purposes of confirming identity during a criminal records check?</span>
                  <div fxLayout="row" fxLayoutGap="8px">
                    <div fxLayout="column" fxLayoutGap="8px">
                      <mat-checkbox formControlName="ssnAttestation" (click)="!renewal ? changeAttestation(true) : null">
                        Yes
                      </mat-checkbox>
                    </div>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <mat-checkbox formControlName="ssnNoAttestation" (click)="!renewal ? changeAttestation(false) : null">
                        No
                      </mat-checkbox>
                    </div>
                  </div>

                  <span class="text-wrap" *ngIf="renewal" [ngClass]="applicantForm.get('changeInConvictionHistory').hasError('required') && renewal? 'text-red-400' : ''">Since the last time you applied for a license/renewal have you had any updates to your convictions history related to misdemeanors or felonies?</span>
                  <div fxLayout="row" fxLayoutGap="8px">
                    <mat-radio-group aria-label="Select an option" formControlName="changeInConvictionHistory" fxLayoutGap="8px" *ngIf="renewal">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="applicantForm.get('changeInConvictionHistory').hasError('required') && renewal">This field is required</mat-error>
                  </div>
                  <span class="text-wrap" *ngIf="renewal"><i>Oregon Background Check Unit provides background checks for OPS. We will reach out if additional background check work is required.</i></span>

                </div>
                <div fxLayout="column" fxLayoutGap="12px">
                  <span><strong>
                    *For purposes of these questions, “license” means any professional or occupational license, permit or certification that authorizes an individual
                    to engage in an occupation or business, issued by a local, state, or tribal governmental entity, or by a governmental body in another country.
                  </strong></span>
                  <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('heldLicense').value == null ? 'red' : ''}">In the last 10 years, have you held a license in Oregon or in another state or country?</span>
                  <mat-radio-group aria-label="Select an option" formControlName="heldLicense" fxLayoutGap="8px">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="validateHeldLicense()">This field is required</mat-error>

                  <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('deniedLicense').value == null ? 'red' : ''}">In the last 10 years, have you been denied a license? If yes, please upload documentation explaining why and provide all relevant information.</span>
                  <mat-radio-group aria-label="Select an option" formControlName="deniedLicense" fxLayoutGap="8px">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="validateDeniedLicense()">This field is required</mat-error>
                  <mat-error *ngIf="!validDeniedLicenseDocument()">You must upload a supporting document</mat-error>
                  <div *ngIf="applicantForm.get('deniedLicense').value == true">
                    <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, deniedLicenseType)"
                      #deniedLicenseUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button type="button" [disabled]="licenseLocked"
                          mat-raised-button color="primary" class="upload-btn" (click)="deniedLicenseUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('subjectToDiscipline').value == null ? 'red' : ''}">For any license you have held, have you been subject to discipline by the entity that issued the license or had the license suspended or revoked in the last 10 years?
                    If so, please upload documentation explaining the reasons for the discipline or revocation and provide all relevant documentation.</span>
                  <mat-radio-group aria-label="Select an option" formControlName="subjectToDiscipline" fxLayoutGap="8px">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="validateSubjectToDiscipline()">This field is required</mat-error>
                  <mat-error *ngIf="!validSubjectToDisciplineDocument()">You must upload a supporting document</mat-error>
                  <div *ngIf="applicantForm.get('subjectToDiscipline').value == true">
                    <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, subjectToDisciplineType)"
                      #subjectToDisciplineUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button type="button" [disabled]="licenseLocked"
                          mat-raised-button color="primary" class="upload-btn" (click)="subjectToDisciplineUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('suedForDamages').value == null ? 'red' : ''}">In the last 10 years, have you been sued for money damages related to work you provided under a license? If yes, please upload documentation to explain and provide all relevant documentation.</span>
                  <mat-radio-group aria-label="Select an option" formControlName="suedForDamages" fxLayoutGap="8px">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="validateSuedForDamages()">This field is required</mat-error>
                  <mat-error *ngIf="!validSuedForDamagesDocument()">You must upload a supporting document</mat-error>
                  <div *ngIf="applicantForm.get('suedForDamages').value == true">
                    <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, suedForDamagesType)"
                      #suedForDamagesUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button type="button" [disabled]="licenseLocked"
                          mat-raised-button color="primary" class="upload-btn" (click)="suedForDamagesUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('settledAllegations').value == null ? 'red' : ''}">In the last 10 years, have you settled allegations or claims against you related to a license? If so, please upload documentation to explain and provide all relevant documentation.</span>
                  <mat-radio-group aria-label="Select an option" formControlName="settledAllegations" fxLayoutGap="8px">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="validateSettledAllegations()">This field is required</mat-error>
                  <mat-error *ngIf="!validSettledAllegationsDocument()">You must upload a supporting document</mat-error>
                  <div *ngIf="applicantForm.get('settledAllegations').value == true">
                    <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, settledAllegationsType)"
                      #settledAllegationsUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button type="button" [disabled]="licenseLocked"
                          mat-raised-button color="primary" class="upload-btn" (click)="settledAllegationsUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('allegedAbuse').value == null ? 'red' : ''}">Has anyone ever alleged that you abused a child or an adult? If you answered yes, please upload documentation to explain the events and provide all relevant documentation.</span>
                  <mat-radio-group aria-label="Select an option" formControlName="allegedAbuse" fxLayoutGap="8px">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="validateAllegedAbuse()">This field is required</mat-error>

                  <div *ngIf="applicantForm.get('allegedAbuse').value == true">
                    <div fxLayout="row">
                      <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('substantiatedAllegation').value == null ? 'red' : ''}">Was the allegation substantiated? If you answered yes, please upload documentation to explain the events and provide all relevant documentation.</span>
                    </div>
                    <div fxLayout="row">
                      <mat-radio-group aria-label="Select an option" formControlName="substantiatedAllegation" fxLayoutGap="8px">
                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false">No</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div fxLayout="row">
                      <mat-error *ngIf="validateSubstantiatedAllegation()">This field is required</mat-error>
                    </div>
                  </div>
                  <mat-error *ngIf="!validAllegedAbuseDocument()">You must upload a supporting document</mat-error>
                  <div *ngIf="applicantForm.get('allegedAbuse').value == true">
                    <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, allegedAbuseType)"
                      #allegedAbuseUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button type="button" [disabled]="licenseLocked"
                          mat-raised-button color="primary" class="upload-btn" (click)="allegedAbuseUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <!--Contact Information-->
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              Contact Information
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Phone</strong><span style="color: red" *ngIf="applicantForm.get('phone').value ==''">*</span></span>
                    <mat-form-field>
                      <input matInput placeholder="" formControlName="phone" mask="(000) 000-0000 000000"
                        [validation]="false">
                        <mat-placeholder style="color:red" *ngIf="applicantForm.get('phone').value == ''">Phone*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('phone').value != ''">Phone</mat-placeholder>
                      <mat-error *ngIf="applicantForm.get('phone').hasError('required')">
                        A primary phone number is required
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('phone').hasError('pattern')">
                        Phone should match this format: 999-999-9999 x1234
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Alternate Phone</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Alt Phone" formControlName="alternatePhone"
                        mask="(000) 000-0000 000000" [validation]="false">
                      <mat-error *ngIf="applicantForm.get('alternatePhone').hasError('pattern')">
                        Phone should match this format: 999-999-9999 x1234
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Primary Email Address</strong><span style="color: red" *ngIf="applicantForm.get('email').value ==''">*</span></span>
                    <mat-form-field>
                      <input matInput formControlName="email">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('email').value == ''">Email*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('email').value != ''">Email</mat-placeholder>
                      <mat-error *ngIf="applicantForm.get('email').hasError('required')">
                        Please enter an email adress
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('email').hasError('email')">
                        This doesn't look like a valid email address
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-20"><strong>Confirm Email Address</strong><span style="color: red" *ngIf="applicantForm.get('confirmEmail').value ==''">*</span></span>
                    <mat-form-field>
                      <input matInput formControlName="confirmEmail">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('confirmEmail').value == ''">Confirm Email*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('confirmEmail').value != ''">Confirm Email</mat-placeholder>
                      <mat-error *ngIf="validateEmail()">
                        Email does not match
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <span class="f-2-18"><strong>Preferred Language</strong></span>
                    <mat-form-field>
                      <input matInput placeholder="Language" formControlName="language">
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column">
                  <span class="f-2-20"><strong>Other OPS Licenses or Permits</strong></span>
                  <mat-checkbox formControlName="previousLicense" (change)="validatePreviousLicense()">
                    <span class="text-wrap">
                      Check the box if the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services</span>
                  </mat-checkbox>
                  <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                    <span>Please provide the license id(s) for each Oregon Psilocybin Services license.</span>
                    <mat-form-field>
                      <input matInput formControlName="affiliatedLicenses"
                        (blur)="validatePreviousLicense()">
                        <mat-placeholder style="color:red" *ngIf="applicantForm.get('affiliatedLicenses').value == ''">Affiliated Licenses*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('affiliatedLicenses').value != ''">Affiliated Licenses</mat-placeholder>
                      <mat-error *ngIf="validatePreviousLicense()">
                        Please provide all license numbers
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <!--Addresses-->
          <mat-card fxFlex="60" fxFlex.lt-md="100">
            <mat-card-title>Address</mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">
                <div fxLayout="column">
                  <span><strong>Physical Address</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalStreet">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('physicalStreet').value == ''">Street Apt/Unit/Suite*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('physicalStreet').value != ''">Street Apt/Unit/Suite</mat-placeholder>
                      <mat-error *ngIf="applicantForm.get('physicalStreet').invalid">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalCity">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('physicalCity').value == ''">City*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('physicalCity').value != ''">City</mat-placeholder>
                      <mat-error *ngIf="applicantForm.get('physicalCity').invalid">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label style="color:red" *ngIf="applicantForm.get('physicalState').value == ''">State/US territory/freely associated state*</mat-label>
                      <mat-label *ngIf="applicantForm.get('physicalState').value != ''">State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="physicalState">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="applicantForm.get('physicalState').invalid">
                        Please select a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalZip">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('physicalZip').value == ''">Zip*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('physicalZip').value != ''">Zip</mat-placeholder>
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('required')">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('physicalState').value === 'OR'">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('physicalCounty').value == ''">County*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('physicalCounty').value != ''">County</mat-placeholder>
                      <mat-select formControlName="physicalCounty">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column" class="m-t-8">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                    <span><strong>Mailing Address</strong></span>
                    <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                      Same as physical
                    </mat-checkbox>
                  </div>
                  <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsPhysical').value">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput formControlName="mailingStreet"
                          (blur)="validateMailingStreet()">
                          <mat-placeholder style="color:red" *ngIf="applicantForm.get('mailingStreet').value == ''">Street Apt/Unit/Suite*</mat-placeholder>
                          <mat-placeholder style="color:grey" *ngIf="applicantForm.get('mailingStreet').value != ''">Street Apt/Unit/Suite</mat-placeholder>
                        <mat-error *ngIf="validateMailingStreet()">
                          Please provide a street
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput formControlName="mailingCity" (blur)="validateMailingCity()">
                        <mat-placeholder style="color:red" *ngIf="applicantForm.get('mailingCity').value == ''">City*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('mailingCity').value != ''">City</mat-placeholder>
                        <mat-error *ngIf="validateMailingCity()">
                          Please provide a city
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <mat-label style="color:red" *ngIf="applicantForm.get('mailingState').value == ''">State/US territory/freely associated state*</mat-label>
                      <mat-label *ngIf="applicantForm.get('mailingState').value != ''">State/US territory/freely associated state</mat-label>
                        <mat-select formControlName="mailingState" (blur)="validateMailingState()">
                          <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="validateMailingState()">
                          Please provide a state
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput formControlName="mailingZip" (blur)="validateMailingZip()">
                        <mat-placeholder style="color:red" *ngIf="applicantForm.get('mailingZip').value == ''">Zip*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="applicantForm.get('mailingZip').value != ''">Zip</mat-placeholder>
                        <mat-error *ngIf="validateMailingZip()">
                          Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                          Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100"
                        *ngIf="applicantForm.get('mailingState').value === 'OR'">
                      <mat-placeholder style="color:red" *ngIf="applicantForm.get('mailingCounty').value == ''">County*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="applicantForm.get('mailingCounty').value != ''">County</mat-placeholder>
                        <mat-select formControlName="mailingCounty">
                          <mat-option>-- None --</mat-option>
                          <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <app-residential-history [applicantId]="applicant.id"
                                         [residentialHistory]="applicant.residentialHistory"
                                         [licenseLocked]="licenseLocked">
                </app-residential-history>
              </div>
            </mat-card-content>
          </mat-card>

          <!--Documents-->
          <div fxLayout="column" fxFlex="40" fxFlex.lt-md="100">
            <!--Upload-->
            <mat-card>
              <mat-card-title>
                <span *ngIf="documentsValid">
                	Identity Verification
                </span>
                <span *ngIf="!documentsValid" class="text-red-400">
                	Identity Verification Incomplete
                </span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap.lt-lg="8px">
                  <span *ngIf="licenseType == 'Manufacturer'">Please visit the <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-License-Manufacturer.aspx" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
                    white-space: normal;"> OPS-Manufacturer License webpage</a> to download required forms.</span>
                  <span *ngIf="licenseType == 'TestingLab'">Please visit the <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-License-Lab-Testing.aspx" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
                    white-space: normal;"> OPS-Laboratory License webpage</a> to download required forms.</span>
                  <span *ngIf="licenseType == 'ServiceCenter'">Please visit the <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-License-Service-Center-Operator.aspx" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
                    white-space: normal;"> OPS-Service Center License webpage</a> to download required forms.</span>
                  <span *ngIf="!renewal">You must upload a copy of the front and back of a valid, unexpired government-issued photo identification. Accepted identification includes:</span>
                  <span *ngIf="renewal">If you have an updated form of identification, upload a copy of the front and back of the valid, unexpired government-issued photo identification. Accepted identification includes:</span>
                  <span style="padding-left: 1em"><strong>• </strong>Passport;</span>
                  <span style="padding-left: 1em"><strong>• </strong>Driver license, whether issued by the State of Oregon or by another state of the United States;</span>
                  <span style="padding-left: 1em"><strong>• </strong>Identification card issued under ORS 807.400;</span>
                  <span style="padding-left: 1em"><strong>• </strong>United States military identification card;</span>
                  <span style="padding-left: 1em"><strong>• </strong>An identification card issued by a federally recognized Indian tribe with photo, name and date of birth; or</span>
                  <span style="padding-left: 1em"><strong>• </strong>Any other identification card issued by a state or territory that bears a picture of the person, the name of the person, the person's date of birth and a physical description of the person</span>
                  <span>A copy of proof of having served in the Armed Forces, such as but not limited to, submitting a Veteran's Administration form DD-214.</span>

                  <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
                    <div fxLayout="column">
                      <span class="f-2-14">For each document select the requirements it verifies</span>
                      <mat-checkbox formControlName="identification">
                        <span class="text-wrap"
                          [ngClass]="!documentsValid && missingTypes[personalIdentificationType] && !licenseLocked ? 'text-red-400' : ''">
                          Identification
                        </span>
                      </mat-checkbox>
                      <mat-checkbox formControlName="applicantInfo">
                        <span class="text-wrap"
                              [ngClass]="!documentsValid && missingTypes[applicantInfoType] && !licenseLocked ? 'text-red-400' : ''">
                              Applicant Information Form
                        </span>
                      </mat-checkbox>
                    </div>

                    <input type="file" accept=".doc,.docx,.rtf,.xls,.xlsx,.pdf,.txt,.png,.jpg,.jpeg,.bmp,.mp4" class="file-input" (change)="uploadDocument($event)" #applicantFileUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button [disabled]="!applicantForm.get('identification').value && !applicantForm.get('applicantInfo').value"
                          type="button" mat-raised-button color="primary" class="upload-btn"
                          (click)="applicantFileUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <!--Current-->
            <mat-card>
              <mat-card-title>
                <span>Current Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="50"><strong>File</strong></span>
                    <span class="f-s-16"><strong>Requirement</strong></span>
                  </div>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of applicant.applicantDocuments">
                    <span fxFlex="50" fxFlex.lt-md="" class="text-indigo-A700" style="cursor: pointer"
                      (click)="downloadDocument(document.id, document.name)">{{document.name}}</span>
                    <div fxFlex="50" fxFlex.lt-md="" fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="70" fxFlex.lt-md="" fxLayout="column">
                        <span *ngFor="let type of document.types">
                          {{documentTypeReference[type]}}
                        </span>
                      </div>
                      <button *ngIf="!licenseLocked && !document.deprecated" type="button" mat-icon-button class="m-8"
                        (click)="deleteFile(document.id, document.name)" color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                      <span *ngIf="document.deprecated" style="color:red">Deprecated</span>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

      </div>
    </form>
  </mat-card-content>
</mat-card>
