<div *ngIf="!loading">

  <!--Approved Licenses-->
  <div  *ngIf="approvedTiles.length >= 1" (window:resize)="onResize($event)">
    <h2>Approved Licenses</h2>
    <mat-grid-list role="list"
                 [cols]="!sharedService.mobile? width: 1"
                 [rowHeight]="!sharedService.mobile? '300px' : '400px'"
                 gutterSize="16px">

      <mat-grid-tile *ngFor="let tile of approvedTiles" role="listitem"
                      [colspan]="tile.cols"
                      [rowspan]="tile.rows"
                      [style.background]="tile.color">
        <mat-grid-tile-header [routerLink]="tile.link" style="cursor: pointer;">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <mat-icon class="medium-icon m-24">{{tile.icon}}</mat-icon>
            <span class="f-s-20">{{tile.title}}</span>
          </div>
        </mat-grid-tile-header>
        <div fxLayout="row" flex style="color: #303030">

            <div fxLayout="column" fxFlex="70" fxLayoutAlign="center stretch" style="margin-top: 0;
              margin-bottom: 0;">
              <div>
                <span *ngIf="tile.licenseType == 'Permit'"><strong>Permit Number: </strong></span>
                <span *ngIf="tile.licenseType == 'License'"><strong>License Id: </strong></span>
                <span *ngIf="tile.licenseType == 'Program'"><strong>Program Id: </strong></span>
                <span style="cursor: pointer; color:black"  [routerLink]="[tile.link]"> {{tile.licenseIdenitfier}}</span>
                <button mat-icon-button (click)="copyToClipboard(tile.licenseIdenitfier)" style="color:black"
                  matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                    <mat-icon>content_copy</mat-icon>
                </button>
              </div>
              <span *ngIf="tile.status != sharedService.submittedForApproval && tile.status != sharedService.renewalSubmittedForApproval"><strong>Status: </strong> {{tile.status}}</span>
              <span *ngIf="tile.status == sharedService.submittedForApproval || tile.status == sharedService.renewalSubmittedForApproval"><strong>Status: </strong> {{sharedService.underReview}}</span>
              <span *ngIf="tile.approvedDate"><strong>Active Date: </strong> {{tile.approvedDate | date}} </span>
              <span *ngIf="!tile.approvedDate"><strong>Active Date: </strong> Pending</span>
              <span *ngIf="tile.expirationDate"><strong>Expiration Date: </strong> {{tile.expirationDate | date}} </span>
              <span *ngIf="!tile.expirationDate"><strong>Expiration Date: </strong> Pending</span>
              <span *ngIf="tile.isRenewal"><strong>Date Renewal Approved: </strong> {{tile.renewedDate | date}} </span>
              <div fxLayout="column" fxLayoutAlign="start start">
                <button mat-flat-button type="button" *ngIf="tile.type == 'WorkerPermit'" (click)="viewPrintScreen(tile)" style="padding-left:1em">Print Permit</button>
                <button mat-flat-button type="button" *ngIf="tile.type != 'WorkerPermit' && tile.type != 'TrainingProgram'" (click)="viewPrintScreen(tile)" style="padding-left:1em">Print License</button>
              </div>
            </div>
            <div fxLayout="column">
            <a [routerLink]="['/license-information', tile.type, tile.id]" >
              <qrcode  [qrdata]=" url + '/license-information/' + tile.type + '/' + tile.id"></qrcode>
            </a>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <!--Pending Licenses-->
  <div fxLayout='column' fxLayoutGap="16px" *ngIf="tiles.length >= 1">
    <h2>Pending Licenses</h2>
    <mat-grid-list role="list"
                 [cols]="!sharedService.mobile? width: 6"
                 [rowHeight]="!sharedService.mobile? '200px' : '250px'"
                 gutterSize="16px">
      <mat-grid-tile *ngFor="let tile of tiles" role="listitem"
                      [colspan]="!sharedService.mobile? tile.cols: 6"
                      [rowspan]="tile.rows"
                      [style.background]="tile.color">

        <mat-grid-tile-header [routerLink]="tile.link" style="cursor: pointer;">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <mat-icon class="medium-icon m-24">{{tile.icon}}</mat-icon>
            <span class="f-s-20">{{tile.title}}</span>
          </div>
        </mat-grid-tile-header>
        <div fxLayout="row" flex fxLayoutGap="50px">
          <div fxLayout="row" flex fxLayoutGap="10px">
            <div fxLayout="column" fxFlex="100" fxLayoutGap="8px" fxLayoutAlign="center center" style="color: #303030">
              <span>
                <span *ngIf="tile.licenseType == 'Permit'"><strong>Permit Number: </strong></span>
                <span *ngIf="tile.licenseType == 'License'"><strong>License Id: </strong></span>
                <span *ngIf="tile.licenseType == 'Program'"><strong>Program Id: </strong></span>
                <span fxLayout.lt-md="row" style="cursor: pointer; color:black" [routerLink]="[tile.link]"> {{tile.licenseIdenitfier}}</span>
                <button mat-icon-button (click)="copyToClipboard(tile.licenseIdenitfier)"
                  matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                    <mat-icon>content_copy</mat-icon>
                </button>
              </span>
              <span *ngIf="tile.status != sharedService.submittedForApproval && tile.status != sharedService.renewalSubmittedForApproval"><strong>Status: </strong> {{tile.status}}</span>
              <span *ngIf="tile.status == sharedService.submittedForApproval || tile.status == sharedService.renewalSubmittedForApproval"><strong>Status: </strong> {{sharedService.underReview}}</span>
            </div>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <!--Expired Licenses-->
  <div fxLayout='column' fxLayoutGap="16px" *ngIf="expiredTiles.length >= 1">
    <h2>Expired Licenses</h2>
    <mat-grid-list role="list"
                 [cols]="!sharedService.mobile? width: 6"
                 [rowHeight]="!sharedService.mobile? '200px' : '250px'"
                 gutterSize="16px">
      <mat-grid-tile *ngFor="let tile of expiredTiles" role="listitem"
                      [colspan]="!sharedService.mobile? tile.cols: 6"
                      [rowspan]="tile.rows"
                      [style.background]="tile.color">

        <mat-grid-tile-header [routerLink]="tile.link" style="cursor: pointer;">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <mat-icon class="medium-icon m-24">{{tile.icon}}</mat-icon>
            <span class="f-s-20">{{tile.title}}</span>
          </div>
        </mat-grid-tile-header>
        <div fxLayout="row" flex fxLayoutGap="50px">
          <div fxLayout="row" flex fxLayoutGap="10px">
            <div fxLayout="column" fxFlex="100" fxLayoutGap="8px" fxLayoutAlign="center center" style="color: #303030">
              <span>
                <span *ngIf="tile.licenseType == 'Permit'"><strong>Permit Number: </strong></span>
                <span *ngIf="tile.licenseType == 'License'"><strong>License Id: </strong></span>
                <span *ngIf="tile.licenseType == 'Program'"><strong>Program Id: </strong></span>
                <span fxLayout.lt-md="row" style="cursor: pointer; color:black" [routerLink]="[tile.link]"> {{tile.licenseIdenitfier}}</span>
                <button mat-icon-button (click)="copyToClipboard(tile.licenseIdenitfier)"
                  matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                    <mat-icon>content_copy</mat-icon>
                </button>
              </span>
              <span *ngIf="tile.status != sharedService.submittedForApproval && tile.status != sharedService.renewalSubmittedForApproval"><strong>Status: </strong> {{tile.status}}</span>
              <span *ngIf="tile.status == sharedService.submittedForApproval || tile.status == sharedService.renewalSubmittedForApproval"><strong>Status: </strong> {{sharedService.underReview}}</span>
            </div>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <!--Apply-->
  <div fxLayout='column' fxLayoutGap="16px" (window:resize)="onResize($event)" *ngIf="applyTiles.length >= 1">
    <h2>Apply For a License</h2>
    <mat-grid-list role="list"
                 [cols]="!sharedService.mobile? width: applyTiles.length"
                 [rowHeight]="!sharedService.mobile? '350px' : '350px'"
                 gutterSize="16px">

      <mat-grid-tile *ngFor="let tile of applyTiles" role="listitem"
                      [colspan]="!sharedService.mobile? 1: applyTiles.length"
                      [rowspan]="tile.rows"
                      [style.background]="tile.color">
        <mat-grid-tile-header [routerLink]="tile.link" style="cursor: pointer;">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <mat-icon class="medium-icon m-24">{{tile.icon}}</mat-icon>
            <span class="f-s-20">{{tile.title}}</span>
          </div>
        </mat-grid-tile-header>
        <span class="p-32" *ngIf="tile.title !== 'Training Program'">{{tile.description}}  <a href="https://oregon.gov/psilocybin" target="_blank" rel="noreferrer noopener" style="color: #303030"> https://oregon.gov/psilocybin</a></span>
        <div fxLayout="column" *ngIf="tile.title == 'Training Program'">
          <span class="p-32">{{tile.description}} <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-Training-Program-Approval.aspx" target="_blank" rel="noreferrer noopener" style="color: #303030; word-break: break-all;
            white-space: normal;"> https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-Training-Program-Approval.aspx</a></span>
        </div>
        <mat-grid-tile-footer [routerLink]="tile.link" style="cursor: pointer;">
          <span mat-line
                [routerLink]="tile.link"
                class="f-s-20">{{tile.action}}</span>
        </mat-grid-tile-footer>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  </div>
  <div *ngIf="loading">
    <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
      <h1>Loading</h1>
    </div>
  </div>
