<mat-card>
  <mat-card-title>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <mat-icon class="icon-20">email</mat-icon>
      <span>Messages</span>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="column">
      <span class="f-s-16">
        'Messages' is a platform to communicate with the Oregon Psilocybin Services Licensing and Compliance teams.
        Here, you are able to send messages to and receive messages from the license and compliance teams.
        Each message has the ability to include attachments and an open text field to write a message.
        To write a new message select the 'Write a message' button. Then select which team you are sending the message to.
        Please include the license or permit ID number you are reaching out about with your message.
        If your question is related to applying, or reapplying for licensure, worker permits or training programs,
        please direct your message to the licensing team. If your message is related to compliance or rules questions,
        please direct your message to the compliance team. If you are unsure which team to send the message to,
        please select the team you think is most relevant and our teams will work together to answer the message.
      </span>

      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-y-16">
        <span *ngIf="environment.internalMessaging.canCreate">
          <a mat-button color="primary" aria-label="Send New Message" title="Send New Message" (click)="openAddMessageDialog('')">
            <mat-icon>add_box</mat-icon><span>Write a message</span>
          </a>
        </span>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign="start center">
        <button *ngIf="currentView=='Inbox'" mat-stroked-button color="primary">Inbox</button>
        <button *ngIf="currentView!='Inbox'" mat-button (click)='loadInbox()'>Inbox</button>
        <button *ngIf="currentView=='Unread'" mat-stroked-button color="primary">Unread</button>
        <button *ngIf="currentView!='Unread'" mat-button (click)='loadUnread()'>Unread</button>
        <button *ngIf="currentView=='Sent' && environment.internalMessaging.canRespond" mat-stroked-button color="primary">Sent</button>
        <button *ngIf="currentView!='Sent' && environment.internalMessaging.canRespond" mat-button (click)='loadSent()'>Sent</button>
        <button mat-button (click)='refresh()'>
          <mat-icon class="icon-20" aria-placeholder='refresh'>refresh</mat-icon>
        </button>
        <mat-checkbox form="addNewInternalMessage" *ngIf="environment.internalMessaging.canViewArchived" (change)="refresh()" [(ngModel)]="includeArchived">
          Include Archived
        </mat-checkbox>
      </div>

    </div>
    <div style="overflow-x: auto;">
      <section *ngIf="processing">
        <h2>Loading...</h2>
      </section>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort aria-describedby="messages"
        #TableOneSort="matSort" class="mat-elevation-z8 demo-table">
        <ng-container matColumnDef="icons">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>&nbsp;</th>
          <td mat-cell *matCellDef="let message" [ngClass]="{'is-unopened': !message.opened}">
            <mat-icon class="icon-20" *ngIf="message.isBroadcast" matTooltip="Announcement message">announcement
            </mat-icon> &nbsp;
            <mat-icon class="icon-20" *ngIf="message.highPriority" matTooltip="High priority message">priority_high
            </mat-icon> &nbsp;
            <mat-icon class="icon-20" *ngIf="message.archived" aria-placeholder="archived"
              matTooltip="Message archived">archive</mat-icon> {{message.Archived}}
            <mat-icon class="icon-20" *ngIf="message.attachments != undefined && message.attachments.length > 0"
              aria-placeholder="archived" matTooltip="Message has attachments">attach_file</mat-icon>
            {{message.Archived}}
          </td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
          <td mat-cell *matCellDef="let message" [ngClass]="{'is-unopened': !message.opened}">
            {{ message.subject }}
          </td>
        </ng-container>
        <ng-container matColumnDef="recipient">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened}">{{
            message.recipients }}</td>
        </ng-container>
        <ng-container matColumnDef="creator">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened}">{{
            message.creatorName }}</td>
        </ng-container>
        <ng-container matColumnDef="license">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>License/Permit/Program</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened}">{{
            message.entityId }} ({{message.entityName}})</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let message" fxHide.xs [ngClass]="{'is-unopened': !message.opened}">{{
            message.createdDate }}</td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let message" [attr.colspan]="displayedColumns.length">
            <div class="expansion-element-detail" [@detailExpand]="
              message == expandedElement ? 'expanded' : 'collapsed'
              ">
              <div class="expansion-element-diagram">

                <mat-card>
                  <mat-card-subtitle>To: {{message.recipients}}</mat-card-subtitle>
                  <mat-card-subtitle>From: {{message.creatorName}}</mat-card-subtitle>
                  <mat-card-subtitle *ngIf="environment.internalMessaging.canAnnounce && message.isBroadcast">Active:
                    {{message.active}}</mat-card-subtitle>
                  <h3>{{message.subject}}</h3>
                  <mat-card-content>
                    <br />
                    <p style="white-space: pre-wrap">{{ message.message }}</p>
                  </mat-card-content>
                  <div *ngIf="message.attachments != undefined && message.attachments.length > 0">
                    <mat-divider inset></mat-divider>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start top">
                      <div *ngFor="let attachment of message.attachments">
                        <span (click)="downloadAttachment(attachment.id, attachment.name)">
                          <mat-icon class="icon-20" color="success" matTooltip="Downlaod file: {{attachment.name}}">
                            cloud_download</mat-icon>
                          &nbsp;&nbsp;
                          {{ attachment.name }}
                          &nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                  <mat-divider inset></mat-divider>
                  <mat-card-actions>
                    <button mat-button *ngIf="environment.internalMessaging.canRespond && message.canRespond"
                      (click)="openAddMessageDialog(message.id)">Reply</button>
                    <button mat-button (click)="archive(message.id)" *ngIf="!message.archived">Archive</button>
                    <button mat-button (click)="unarchive(message.id)" *ngIf="message.archived">UnArchive</button>
                  </mat-card-actions>
                  <mat-card-footer>
                  </mat-card-footer>
                </mat-card>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="expansion-element-row"
          [class.expansion-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row" (click)="open(row)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expansion-detail-row"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" #TableOnePaginator="matPaginator" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>
