<img [@slideLeft]  *ngIf="!showForm"
     src="../../../assets/images/translucentOhaLogo.png"
     class="bg hideOnMobile"
     alt="Oregon Health Authority Logo"
     aria-label="Oregon Health Authority Logo">

<img [@slideLeft]  *ngIf="!showForm"
     src="../../../assets/images/translucentOhaLogo.png"
     class="bg-mobile showOnMobile"
     alt="Oregon Health Authority Logo"
     aria-label="Oregon Health Authority Logo">

<div fxLayout="column" fxFlexFill>
  <mat-toolbar>
    <span *ngIf="!sharedService.mobile && !sharedService.tablet" fxFlex class="p-l-16 m-y-24" style="color: #005595">
      Oregon Psilocybin Services (OPS) - Training Program, Licensing, and Compliance (TLC) System
    </span>

    <span *ngIf="sharedService.tablet || sharedService.mobile" fxFlex class="p-l-16 m-y-24" style="color: #005595">
      Oregon Psilocybin Services (OPS) - (TLC)
    </span>
  </mat-toolbar>

  <div [@slideRight]  fxLayout="column" *ngIf="!showForm && !showSuccess" style="font-family: Oswald, sans-serif;">
    <div fxLayout="row" fxLayoutAlign="center start" class="m-x-8">
      <h1>Register a Complaint With Oregon Psilocybin Services</h1>
    </div>

    <div fxLayout="column" fxLayoutAlign="start center">
      <h2>Complaint Is In Reference To</h2>
      <mat-radio-group fxLayout="row"
                       fxLayout.lt-lg="column"
                       aria-label="Complaint Type"
                       class="radio-group"
                       style="font-family: Oswald, sans-serif; font-size: large;"
                       [(ngModel)]="selectedParent">
        <mat-radio-button aria-label="Training Programs" class="radio-button" [value]="sharedService.trainingProgram">Training Programs</mat-radio-button>
        <mat-radio-button aria-label="Facilitators" class="radio-button" [value]="sharedService.facilitator">Facilitators</mat-radio-button>
        <mat-radio-button aria-label="Service Centers" class="radio-button" [value]="sharedService.serviceCenter">Service Centers</mat-radio-button>
        <mat-radio-button aria-label="Manufacturers" class="radio-button" [value]="sharedService.manufacturer">Manufacturers</mat-radio-button>
        <mat-radio-button aria-label="Testing Labs" class="radio-button" [value]="sharedService.testingLab">Testing Labs</mat-radio-button>
        <mat-radio-button aria-label="Permitted Workers" class="radio-button" [value]="sharedService.workerPermit">Worker Permits</mat-radio-button>
      </mat-radio-group>
      <button mat-stroked-button type="button" color="primary" (click)="openForm()" [disabled]="selectedParent === ''">Start</button>
    </div>
  </div>

  <div [@slideLeft]  fxLayout="row" *ngIf="showForm">
    <div fxLayout="column" fxFlexOffset="25" fxFlex="50" fxFlexOffset.lt-lg="5" fxFlex.lt-lg="90" style="font-family: Oswald, sans-serif; font-size: x-large">

      <div fxLayout="column" *ngIf="selectedParent === sharedService.trainingProgram" fxLayoutGap="16px" class="m-t-32">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="8px" fxLayoutAlign.gt-md="center center">
          <span style="font-size:xx-large" fxFlexOffset="15"><strong>Psilocybin Training Program Curriculum Complaint Form</strong></span>
          <span fxFlex></span>
          <button mat-stroked-button color="warn" type="button" (click)="cancel()">Cancel</button>
        </div>
        <div fxLayout="column">
          <span>For general career school complaints please contact the Higher Education Coordinating Commission</span>
          <span><strong>Private Career School License - Student Complaints</strong></span>
          <span>Matthew Altman - Phone: 503-881-2738</span>
          <a href="mailto:Matthew.ALTMAN@hecc.oregon.gov">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start">
              <span>
                Matthew.ALTMAN@hecc.oregon.gov</span>
              <mat-icon>email</mat-icon>
            </div>
          </a>
        </div>
        <span>
          If you have a complaint or a concern about a Psilocybin training program not meeting the Oregon Health Authority, Oregon Psilocybin Services (OPS)
          Standards in OAR 333-333-3020 to 333-333-3090, please use this form. Please note that OPS approves training program curriculum,
          but does not license or regulate training programs.
        </span>
        <span>
          OPS can only respond to complaints that allege a violation of OPS rules.
        </span>
        <span>
          If your complaint is related to criminal activity, please contact law enforcement.
        </span>
        <span>
          Complaints are subject to public disclosure under Oregon's Public Records Law, although if you have concerns about your privacy or safety,
          OPS will make effort to keep your identity confidential, to the extent permitted by law.
        </span>
        <span>
          The information provided will be carefully reviewed against the applicable Oregon Revised Statutes, and Oregon
          Administrative Rules. The review will determine if there are potential violations and if OHA is the agency with jurisdiction to take further action.
        </span>
        <span>
          Thank you for sharing your concerns with the Oregon Psilocybin Services Section (OPS).
        </span>
        <span><span class="f-s-20 text-red-700">*</span> Required</span>
      </div>

      <div fxLayout="column" *ngIf="selectedParent !== sharedService.trainingProgram && selectedParent !== sharedService.workerPermit" fxLayoutGap="16px" class="m-t-32">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="8px" fxLayoutAlign.gt-md="center center">
          <span style="font-size:xx-large" fxFlexOffset="25"><strong>Psilocybin {{entityTypes[selectedParent]}} Complaint Form</strong></span>
          <span fxFlex></span>
          <button mat-stroked-button color="warn" type="button" (click)="cancel()">Cancel</button>
        </div>
        <span>
          If you have a complaint or a concern about a psilocybin licensee, please use this form. The information provided will be carefully reviewed.
          Oregon Psilocybin Services will determine if there are potential violations of ORS 475A or OAR 333-333 and whether the Oregon Health Authority
          is the agency with jurisdiction to take further action.
        </span>
        <span>
          Oregon Psilocybin Services will only investigate violations of ORS 475A or ORS 333-333. If your complaint is related to criminal activity, please contact law enforcement.
        </span>
        <span>
          Complaints are subject to public disclosure under Oregon's Public Records Law. If OHA receives a public records request for information that you submitted and you are concerned about your privacy or safety,
          Oregon Psilocybin Services will make every effort to keep your identity confidential to the fullest extent permitted by law.
        </span>
        <span>
          Thank you for sharing your concerns with the Oregon Psilocybin Services Section (OPS).
        </span>
        <span><span class="f-s-20 text-red-700">*</span> Required</span>
      </div>

      <div fxLayout="column" *ngIf="selectedParent == sharedService.workerPermit" fxLayoutGap="16px" class="m-t-32">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="8px" fxLayoutAlign.gt-md="center center">
          <span style="font-size:xx-large" fxFlexOffset="25"><strong>Psilocybin {{entityTypes[selectedParent]}} Complaint Form</strong></span>
          <span fxFlex></span>
          <button mat-stroked-button color="warn" type="button" (click)="cancel()">Cancel</button>
        </div>
        <span>
          If you have a complaint or a concern about a psilocybin permitted worker, please use this form. The information provided will be carefully reviewed.
          Oregon Psilocybin Services will determine if there are potential violations of ORS 475A or OAR 333-333 and whether the Oregon Health Authority
          is the agency with jurisdiction to take further action.
        </span>
        <span>
          Oregon Psilocybin Services will only investigate violations of ORS 475A or ORS 333-333. If your complaint is related to criminal activity, please contact law enforcement.
        </span>
        <span>
          Complaints are subject to public disclosure under Oregon's Public Records Law. If OHA receives a public records request for information that you submitted and you are concerned about your privacy or safety,
          Oregon Psilocybin Services will make every effort to keep your identity confidential to the fullest extent permitted by law.
        </span>
        <span>
          Thank you for sharing your concerns with the Oregon Psilocybin Services Section (OPS).
        </span>
        <span><span class="f-s-20 text-red-700">*</span> Required</span>
      </div>

      <form [formGroup]="complaintForm">
        <div fxLayout="column" class="m-t-24">
          <span>Name <span class="f-s-20 text-red-700">*</span></span>
          <span class="f-s-18">We may reach out to you if further details are needed</span>
          <mat-form-field fxFlex="50">
            <input matInput placeholder="First and Last Name" formControlName="name" aria-label="Complainant Name">
            <mat-error *ngIf="complaintForm.get('name').invalid">
              Please provide your name
            </mat-error>
          </mat-form-field>

          <span>Email <span class="f-s-20 text-red-700">*</span></span>
          <mat-form-field fxFlex="50">
            <input matInput placeholder="Email" formControlName="contactEmail" aria-label="Complainant Email">
            <mat-error *ngIf="complaintForm.get('contactEmail').hasError('email')">
              This doesn't look like a valid email address
            </mat-error>
            <mat-error *ngIf="complaintForm.get('contactEmail').hasError('required')">
              Please provide your email
            </mat-error>
          </mat-form-field>

          <span>Phone <span class="f-s-20 text-red-700">*</span></span>
          <mat-form-field fxFlex="50">
            <input matInput placeholder="Phone" formControlName="contactNumber" mask="(000) 000-0000 000000" [validation]="false" aria-label="Complainant Phone">
            <mat-error *ngIf="complaintForm.get('contactNumber').hasError('required')">
              Please provide a phone number
            </mat-error>
            <mat-error *ngIf="complaintForm.get('contactNumber').hasError('pattern')">
              Phone should match this format: (999) 999-9999 999999
            </mat-error>
          </mat-form-field>

          <span>Preferred method of contact</span>
          <mat-radio-group fxLayout="row" aria-label="Preferred Contact Method" formControlName="preferredContact"
            class="radio-group">
            <mat-radio-button aria-label="Email" class="radio-button" value="email">Email</mat-radio-button>
            <mat-radio-button aria-label="Phone" class="radio-button" value="phone">Phone</mat-radio-button>
          </mat-radio-group>

          <span>
            Do you have any concerns about the release of your identity in relation to this feedback? <span class="f-s-20 text-red-700">*</span>
          </span>
          <mat-form-field fxFlex>
            <textarea matInput rows="2" fxFlex="100" type="text" placeholder="Concerns" formControlName="identityConcern" aria-label="Identity Concerns"></textarea>
            <mat-error *ngIf="complaintForm.get('identityConcern').invalid">
              Please provide a response
            </mat-error>
          </mat-form-field>

          <span>
            Please enter the name of the {{entityTypes[selectedParent]}} associated with your complaint <span class="f-s-20 text-red-700">*</span>
          </span>
          <mat-form-field fxFlex="30" fxFlex.lt-md="100">
            <input matInput type="text" [placeholder]="'Relevant ' + entityTypes[selectedParent]" formControlName="parentLicenseName" aria-label="License">
          </mat-form-field>

          <span>
            Date of incident (if unknown leave blank, if multiple dates, note the dates in the complaint section below)
          </span>
          <mat-form-field>
            <mat-label>Date of Incident</mat-label>
            <input placeholder="mm/dd/yyyy"
                   formControlName="incidentDate"
                   matInput
                   [matDatepicker]="incidentDate"
                   [max]="today" />
            <mat-datepicker-toggle matSuffix [for]="incidentDate"></mat-datepicker-toggle>
            <mat-datepicker #incidentDate>
              <mat-datepicker-actions>
                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                <button mat-button matDatepickerCancel>Cancel</button>
              </mat-datepicker-actions>
            </mat-datepicker>
            <mat-error *ngIf="complaintForm.get('incidentDate').value > today">
              Please choose an earlier date.
            </mat-error>
          </mat-form-field>

          <span>What is your complaint? <span class="f-s-20 text-red-700">*</span></span>
          <span>
            Please describe your complaint in detail. Include any persons involved, where the rules violation occurred, when
            it occurred,
            and any other pertinent information that will aid in the investigation of this complaint.
          </span>
          <mat-form-field fxFlex>
            <textarea matInput rows="3" fxFlex="100" type="text" placeholder="Complaint" formControlName="complaintText" aria-label="Complaint"></textarea>
          </mat-form-field>

          <span>Have you reported this to, or filed a complaint or action with, any other agency or organization, such as law
            enforcement,
            Adult Protective Services, professional licensing boards, etc.? If so, please list agencies,
            date and actions or findings associated with the complaint. <span class="f-s-20 text-red-700">*</span>
          </span>
          <mat-form-field fxFlex>
            <textarea matInput rows="3" fxFlex="100" type="text" placeholder="Previous Reporting" formControlName="previousReporting" aria-label="Previous Reporting"></textarea>
          </mat-form-field>

          <span>To the best of my knowledge, I attest this complaint is true. <span class="f-s-20 text-red-700">*</span></span>
          <mat-form-field fxFlex="50">
            <input matInput placeholder="First and Last Name" formControlName="signature" aria-label="Affirmation Signature">
            <mat-error *ngIf="complaintForm.get('signature').invalid">
              A signature is required
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" class="m-b-24">
            <span fxFlex></span>
            <button mat-stroked-button type="submit" color="primary" [disabled]="!complaintForm.valid" (click)="submitComplaint()" aria-label="Submit Complaint">Submit</button>
            <button mat-stroked-button color="warn" type="button" (click)="cancel()" aria-label="Cancel Submission">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div [@slideLeft]  fxLayout="row" *ngIf="showSuccess">
    <div fxLayout="column" fxFlexOffset="25" fxFlex="50" fxFlexOffset.lt-lg="5" fxFlex.lt-lg="90">
      <div fxLayout="column" fxLayoutGap="16px" class="m-t-32" style="font-family: Oswald, sans-serif; font-size: x-large">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span style="font-size:xx-large" fxFlexOffset="30"><strong>Thank You For Your Feedback</strong></span>
          <span fxFlex></span>
          <button mat-button color="warn" type="button" (click)="cancel()" aria-label="Close">Close</button>
        </div>
        <span>
          Please be patient with your complaint processing. Complaint investigations can can take weeks or months to reach
          a conclusion.
        </span>
      </div>
    </div>
  </div>
  <span fxFlex></span>
  <app-footer *ngIf="!sharedService.mobile"></app-footer>
</div>
