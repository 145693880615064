import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IComplaint } from 'app/models/complaints/complaint';

@Injectable({providedIn: 'root'})
export class ComplaintsService {
  private publicApi: string = environment.publicApi + 'Complaint/';

  constructor(private http: HttpClient) { }

  submitComplaint(complaint: IComplaint): Observable<boolean>{
    return this.http.post<boolean>(this.publicApi + "SubmitComplaint", complaint);
  }

}
