import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ILicenseTile } from 'app/models/apply/license-tile';
import { IFacilitatorLicense } from 'app/models/licenses/facilitator-license';
import { IManufacturerLicense } from 'app/models/licenses/manufacturer-license';
import { IServiceCenterLicense } from 'app/models/licenses/service-center-license';
import { ITestingLabLicense } from 'app/models/licenses/testing-lab-license';
import { IWorkerLicense } from 'app/models/licenses/worker-license';
import { ITrainingProgram } from 'app/models/training/training-program';
import { SharedService } from 'app/services/core/shared.service';
import { FacilitatorService } from 'app/services/licenses/facilitator.service';
import { ManufacturerService } from 'app/services/licenses/manufacturer.service';
import { ServiceCenterService } from 'app/services/licenses/service-center.service';
import { TestingLabService } from 'app/services/licenses/testing-lab.service';
import { WorkerService } from 'app/services/licenses/worker.service';
import { TrainingProgramService } from 'app/services/training/training-program.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../payments/service/payment.service';
import { ApplicantService } from 'app/services/licenses/applicant.service';

@Component({
  selector: 'app-home',
  templateUrl: './license-dashboard.component.html',
  styleUrls: ['./license-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class LicenseDashboardComponent implements OnInit {

  public url = environment.baseUrl;

  iconReference: {[ref: string]: string} = {
    'Facilitator' : 'group',
    'Manufacturer' : 'scale',
    'Service Center' : 'store_mall_directory',
    'Testing Lab' : 'domain',
    'Worker Permit' : 'badge',
    'Training Program' : 'description'
  }
  width: number = 6;
  tiles: ILicenseTile[] = [];
  applyTiles: ILicenseTile[] = [];
  approvedTiles: ILicenseTile[] = [];
  expiredTiles: ILicenseTile[] = [];
  private page: string = 'license-dashboard';
  private opacity: string = '95';

  colorReference: {[ref: string]: string} = {
    'Facilitator' : this.sharedService.purple + this.opacity,
    'Manufacturer' : this.sharedService.blue + this.opacity,
    'Service Center' : this.sharedService.green + this.opacity,
    'Testing Lab' : this.sharedService.gold + this.opacity,
    'Worker Permit' : this.sharedService.orange + this.opacity
  }

  routeReference: {[ref: string]: string} = {
    'Facilitator' : 'facilitator',
    'Manufacturer' : 'manufacturer',
    'Service Center' : 'service-center',
    'Testing Lab' : 'testing-lab',
    'Worker Permit' : 'worker-permit'
  }
  loading: boolean = true;


  loremIpsum: string = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
  in culpa qui officia deserunt mollit anim id est laborum.`

  facilitatorLicenses: IFacilitatorLicense[] = [];
  trainingPrograms: ITrainingProgram[] = [];
  workerLicenses: IWorkerLicense[] = [];
  manufacturerLicenses: IManufacturerLicense[] = [];
  serviceCenterLicenses: IServiceCenterLicense[] =[];
  testingLabLicenses: ITestingLabLicense[] = [];

  constructor(private facilitatorService: FacilitatorService,
              private workerService: WorkerService,
              private trainingService: TrainingProgramService,
              private serviceCenterService: ServiceCenterService,
              private testingLabService: TestingLabService,
              public sharedService: SharedService,
              public dialog: MatDialog,
              private router: Router,
              private toastr: ToastrService,
              private manufacturerService: ManufacturerService,
              public applicantService: ApplicantService,) { }

  ngOnInit(): void {
    this.setSize(window.innerWidth);

    this.facilitatorService.getFacilitatorLicenses().subscribe(
      response => this.facilitatorLicenses = response,
      error => console.log('error', error),
      () => {
        this.addFacilitatorTiles();
        this.trainingService.getTrainingPrograms().subscribe(
          response => this.trainingPrograms = response,
          error => console.log('error', error),
          () => {
            this.addTrainingProgramTiles();
            this.workerService.getWorkerLicenses().subscribe(
              response => this.workerLicenses = response,
              error => console.log('error', error),
              () => {
                this.addWorkerPermitTiles();
                this.manufacturerService.getLicenses().subscribe(
                  response => this.manufacturerLicenses = response,
                  error => console.log('error', error),
                  () => {
                   this.addManufacturerLicenseTiles();
                    this.serviceCenterService.getLicenses().subscribe(
                      response => this.serviceCenterLicenses = response,
                      error => console.log('error', error),
                    () => {
                      this.addServiceCenterLicenseTiles();
                      this.testingLabService.getLicenses().subscribe(
                        response => this.testingLabLicenses = response,
                        error => console.log('error', error),
                      () => {
                        this.addTestingLabLicenseTiles();
                      this.setupApplyTiles();
                      this.checkLicenses();
                      this.loading = false;
                    });
                  });
                  });

              });
          });
       });


    this.sharedService.updateUser(this.page);

  }

  viewPrintScreen(license: ILicenseTile): void {
    if(license.type == 'ManufacturerLicense' || license.type == 'ServiceCenter' || license.type == 'TestingLab')
    {
      this.loading = true;
      let type = '';
      if(license.type == 'ManufacturerLicense')
        type = this.sharedService.manufacturer;
      if(license.type == 'ServiceCenter')
        type = this.sharedService.serviceCenter;
      if(license.type == 'TestingLab')
        type = this.sharedService.testingLab;
      this.applicantService.getApplicants(type, license.id).subscribe({
        next: response => {
          license.applicants = response;
          this.applicantService.getEntityApplicants(type, license.id).subscribe({
            next: response => license.entityApplicants = response,
            error: e => console.log('error', e)
          });
        },
        error: e => console.log('error', e),
        complete: () => {
          this.loading = false;
          this.dialog.open(PrintDetailsDialog, {
            data: license,
            maxWidth: !this.sharedService.mobile ? '900px' : '800px',
            maxHeight: this.sharedService.mobile ? '1000px' : '900px',
            autoFocus: false,
            panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
          });
        }
      });
    }
    else
      this.dialog.open(PrintDetailsDialog, {
        data: license,
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '1000px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
  }

  checkLicenses(){
    if(this.tiles.length == 0 && this.approvedTiles.length == 0 && this.expiredTiles.length == 0)
      this.router.navigateByUrl('/apply');
  }

  setSize(element: number){
    if(element > 2250)
      this.width = 5;
    if(element < 2250)
      this.width = 4;
    if(element < 1980)
      this.width = 3;
    if(element < 1550)
      this.width = 2;
    if(element < 1150)
      this.width = 1;
  }

  onResize(event) {
    const element = event.target.innerWidth;
    if(element > 2250)
      this.width = 5;
    if(element < 2250)
      this.width = 4;
    if(element < 1980)
      this.width = 3;
    if(element < 1550)
      this.width = 2;
    if(element < 1150)
      this.width = 1;
  }

  addFacilitatorTiles(){
  for(let license of this.facilitatorLicenses)
  {
    const hasExpired = new Date(license.expirationDate) < new Date();
    const hasRenewalUnderReview = this.facilitatorLicenses.some(l =>
      l.licenseId === license.licenseId &&
      (l.status === 'Renewal Under Review/Timely') || l.status === 'Renewal Approved, Pending Fee Payment and Clearance');

    let tile: ILicenseTile = {
      title: 'Facilitator License',
      icon: this.iconReference['Facilitator'],
      description: license.status,
      color: this.sharedService.purple,
      link: "/license/facilitator",
      action: "",
      rows: 1,
      cols: 1,
      id: license.id,
      status: license.status,
      licenseIdenitfier: license.licenseId,
      approvedDate: license.approvedDate,
      renewedDate: license.renewedDate,
      isRenewal: license.isRenewal,
      expirationDate: license.expirationDate,
      licenseType: 'License',
      type: 'FacilitatorLicense',
      entityName: license.applicants[0].legalFirstName + ' ' + license.applicants[0].legalLastName,
      physicalStreet: license.applicants[0].physicalStreet,
      physicalCity: license.applicants[0].physicalCity,
      physicalState: license.applicants[0].physicalState,
      physicalZip: license.applicants[0].physicalZip,
      physicalCounty: license.applicants[0].physicalCounty,
      entityApplicants: [],
      applicants: [],
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: hasExpired && hasRenewalUnderReview
    };
  if(tile.status == "Approved")
    this.approvedTiles.push(tile);
  else if(tile.status == "Expired")
  this.expiredTiles.push(tile);
  else
     this.tiles.push(tile);
  }
}

  addWorkerPermitTiles(){
  for(let permit of this.workerLicenses)
  {
    let tile: ILicenseTile = {
      title: 'Worker Permit',
      icon: this.iconReference['Worker Permit'],
      description: permit.status,
      color: this.sharedService.red,
      link: "/license/worker-permit",
      action: "",
      rows: 1,
      cols: 1,
      id: permit.id,
      status: permit.status,
      licenseIdenitfier: permit.licenseId,
      approvedDate: permit.approvedDate,
      expirationDate: permit.expirationDate,
      renewedDate: "",
      isRenewal: false,
      licenseType: 'Permit',
      type: 'WorkerPermit',
      entityName: permit.applicants[0].legalFirstName + ' ' + permit.applicants[0].legalLastName,
      physicalStreet: permit.applicants[0].physicalStreet,
      physicalCity: permit.applicants[0].physicalCity,
      physicalState: permit.applicants[0].physicalState,
      physicalZip: permit.applicants[0].physicalZip,
      physicalCounty: permit.applicants[0].physicalCounty,
      entityApplicants: [],
      applicants: [],
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: false
    };
  if(tile.status == "Approved")
    this.approvedTiles.push(tile);
  else if(tile.status == "Expired")
    this.expiredTiles.push(tile);
  else
     this.tiles.push(tile);
  }
}

  addTrainingProgramTiles(){
  for(let program of this.trainingPrograms)
  {
    let tile: ILicenseTile = {
      title: 'Training Program',
      icon: this.iconReference['Training Program'],
      description: program.status,
      color: this.sharedService.blue,
      link: "/training",
      action: "",
      rows: 1,
      cols: 1,
      id: program.id,
      status: program.status,
      licenseIdenitfier: program.programId,
      approvedDate: program.grantedDate,
      expirationDate: program.expirationDate,
      renewedDate: "",
      isRenewal: false,
      licenseType: 'Program',
      type: 'TrainingProgram',
      entityName: program.programName,
      physicalStreet: program.headquartersStreet,
      physicalCity: program.headquartersCity,
      physicalState: program.headquartersState,
      physicalZip: program.headquartersZip,
      physicalCounty: program.headquartersCounty,
      entityApplicants: [],
      applicants: [],
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: false
    };
   if(tile.status == "Approved")
    this.approvedTiles.push(tile);
  else if(tile.status == "Expired")
    this.expiredTiles.push(tile);
  else
     this.tiles.push(tile);
  }
}

  addManufacturerLicenseTiles(){
  for(let program of this.manufacturerLicenses)
  {
    const hasExpired = new Date(program.expirationDate) < new Date();
    const hasRenewalUnderReview = this.manufacturerLicenses.some(l =>
      l.licenseId === program.licenseId &&
      (l.status === 'Renewal Under Review/Timely') || l.status === 'Renewal Approved, Pending Fee Payment and Clearance');

    let tile: ILicenseTile = {
      title: 'Manufacturer License',
      icon: this.iconReference['Manufacturer'],
      description: program.status,
      color: this.sharedService.gold,
      link: "/license/manufacturer",
      action: "",
      rows: 1,
      cols: 1,
      id: program.id,
      status: program.status,
      licenseIdenitfier: program.licenseId,
      approvedDate: program.approvedDate,
      expirationDate: program.expirationDate,
      renewedDate: program.renewedDate,
      isRenewal: program.isRenewal,
      licenseType: 'License',
      type: 'ManufacturerLicense',
      entityName: program.entityName,
      physicalStreet: program.physicalStreet,
      physicalCity: program.physicalCity,
      physicalState: program.physicalState,
      physicalZip: program.physicalZip,
      physicalCounty: program.physicalCounty,
      entityApplicants: program.entityApplicants,
      applicants: program.applicants,
      fungiCultivation: program.fungiCultivation,
      psilocybinExtraction: program.psilocybinExtraction,
      ediblePsilocybinProduction: program.ediblePsilocybinProduction,
      canOperate: hasExpired && hasRenewalUnderReview
    };
   if(tile.status == "Approved")
    this.approvedTiles.push(tile);
  else if(tile.status == "Expired")
    this.expiredTiles.push(tile);
  else
     this.tiles.push(tile);
  }
}

  addServiceCenterLicenseTiles(){
  for(let program of this.serviceCenterLicenses)
  {
    const hasExpired = new Date(program.expirationDate) < new Date();
    const hasRenewalUnderReview = this.serviceCenterLicenses.some(l =>
      l.licenseId === program.licenseId &&
      (l.status === 'Renewal Under Review/Timely') || l.status === 'Renewal Approved, Pending Fee Payment and Clearance');

    let tile: ILicenseTile = {
      title: 'Service Center License',
      icon: this.iconReference['Service Center'],
      description: program.status,
      color: this.sharedService.green,
      link: "/license/service-center",
      action: "",
      rows: 1,
      cols: 1,
      id: program.id,
      status: program.status,
      licenseIdenitfier: program.licenseId,
      approvedDate: program.approvedDate,
      expirationDate: program.expirationDate,
      renewedDate: program.renewedDate,
      isRenewal: program.isRenewal,
      licenseType: 'License',
      type: 'ServiceCenter',
      entityName: program.entityName,
      physicalStreet: program.physicalStreet,
      physicalCity: program.physicalCity,
      physicalState: program.physicalState,
      physicalZip: program.physicalZip,
      physicalCounty: program.physicalCounty,
      entityApplicants: program.entityApplicants,
      applicants: program.applicants,
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: hasExpired && hasRenewalUnderReview
    };
   if(tile.status == "Approved")
    this.approvedTiles.push(tile);
  else if(tile.status == "Expired")
    this.expiredTiles.push(tile);
  else
     this.tiles.push(tile);
  }
}

  addTestingLabLicenseTiles(){
  for(let program of this.testingLabLicenses)
  {
    const hasExpired = new Date(program.expirationDate) < new Date();
    const hasRenewalUnderReview = this.testingLabLicenses.some(l =>
      l.licenseId === program.licenseId &&
      (l.status === 'Renewal Under Review/Timely') || l.status === 'Renewal Approved, Pending Fee Payment and Clearance');

    let tile: ILicenseTile = {
      title: 'Laboratory  License',
      icon: this.iconReference['Testing Lab'],
      description: program.status,
      color: this.sharedService.orange,
      link: "/license/testing-lab",
      action: "",
      rows: 1,
      cols: 1,
      id: program.id,
      status: program.status,
      licenseIdenitfier: program.licenseId,
      approvedDate: program.approvedDate,
      expirationDate: program.expirationDate,
      renewedDate: program.renewedDate,
      isRenewal: program.isRenewal,
      licenseType: 'License',
      type: 'TestingLab',
      entityName: program.entityName,
      physicalStreet: program.physicalStreet,
      physicalCity: program.physicalCity,
      physicalState: program.physicalState,
      physicalZip: program.physicalZip,
      physicalCounty: program.physicalCounty,
      entityApplicants: program.entityApplicants,
      applicants: program.applicants,
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: hasExpired && hasRenewalUnderReview
    };
   if(tile.status == "Approved")
    this.approvedTiles.push(tile);
   else if(tile.status == "Expired")
    this.expiredTiles.push(tile);
  else
     this.tiles.push(tile);
  }
}

  setupApplyTiles(): void{
if(this.facilitatorLicenses.length < 1)
  {
    let FacilitatorTile: ILicenseTile = {
      title: 'Facilitator',
      icon: this.iconReference['Facilitator'],
      description: 'To apply for a facilitator license through Oregon Psilocybin Services, start here. For more information about the facilitator license application requirements and process, please visit:',
      cols: 2,
      rows: 1,
      color: this.sharedService.purple,
      link: '/license/facilitator',
      action: 'Apply',
      id: 0,
      status: "",
      licenseIdenitfier: "",
      approvedDate: "",
      expirationDate: "",
      renewedDate: "",
      isRenewal: false,
      licenseType: "",
      type: '',
      entityName: '',
      physicalStreet: '',
      physicalCity: '',
      physicalState: '',
      physicalZip: '',
      physicalCounty: '',
      entityApplicants: [],
      applicants: [],
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: false
    };
    this.applyTiles.push(FacilitatorTile);
  }
  if(this.manufacturerLicenses.length < 1)
  {
   let ManufacturerTile: ILicenseTile = {
     title: 'Manufacturer',
     icon: this.iconReference['Manufacturer'],
     description: 'To apply for a manufacturer license through Oregon Psilocybin Services, start here. For more information about the manufacturer license application requirements and process, please visit:',
     cols: 2,
     rows: 1,
     color: this.sharedService.gold,
     link: '/license/manufacturer',
     action: 'Apply',
     id: 0,
     status: "",
     licenseIdenitfier: "",
     approvedDate: "",
     expirationDate: "",
     renewedDate: "",
     isRenewal: false,
     licenseType: "",
     type: '',
     entityName: '',
     physicalStreet: '',
     physicalCity: '',
     physicalState: '',
     physicalZip: '',
     physicalCounty: '',
     entityApplicants: [],
     applicants: [],
     fungiCultivation: false,
     psilocybinExtraction: false,
     ediblePsilocybinProduction: false,
     canOperate: false
   };
    this.applyTiles.push(ManufacturerTile);
  }
  if(this.serviceCenterLicenses.length < 1)
  {
    let ServiceCenterTile: ILicenseTile = {
      title: 'Service Center',
      icon: this.iconReference['Service Center'],
      description: 'To apply for a service center license through Oregon Psilocybin Services, start here. For more information about the service center license application requirements and process, please visit:',
      cols: 2,
      rows: 1,
      color: this.sharedService.green,
      link: '/license/service-center',
      action: 'Apply',
      id: 0,
      status: "",
      licenseIdenitfier: "",
      approvedDate: "",
      expirationDate: "",
      renewedDate: "",
      isRenewal: false,
      licenseType: "",
      type: '',
      entityName: '',
      physicalStreet: '',
      physicalCity: '',
      physicalState: '',
      physicalZip: '',
      physicalCounty: '',
      entityApplicants: [],
      applicants: [],
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: false
    };
    this.applyTiles.push(ServiceCenterTile);
  }
  if(this.testingLabLicenses.length < 1)
  {
    let TestingLabTile: ILicenseTile = {
      title: 'Laboratory',
      icon: this.iconReference['Testing Lab'],
      description: 'To apply for a laboratory license through Oregon Psilocybin Services, start here. For more information about the laboratory license application requirements and process, please visit:',
      cols: 2,
      rows: 1,
      color: this.sharedService.orange,
      link: '/license/testing-lab',
      action: 'Apply',
      id: 0,
      status: "",
      licenseIdenitfier: "",
      approvedDate: "",
      expirationDate: "",
      renewedDate: "",
      isRenewal: false,
      licenseType: "",
      type: '',
      entityName: '',
      physicalStreet: '',
      physicalCity: '',
      physicalState: '',
      physicalZip: '',
      physicalCounty: '',
      entityApplicants: [],
      applicants: [],
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: false
    };
    this.applyTiles.push(TestingLabTile);
  }
if(this.workerLicenses.length < 1)
  {
    let WorkerPermitTile: ILicenseTile = {
      title: 'Worker Permit',
      icon: this.iconReference['Worker Permit'],
      description: 'To apply for a worker permit through Oregon Psilocybin Services, start here. For more information about the worker permit application requirements and process, please visit:',
      cols: 2,
      rows: 1,
      color: this.sharedService.red,
      link: '/license/worker-permit',
      action: 'Apply',
      id: 0,
      status: "",
      licenseIdenitfier: "",
      approvedDate: "",
      expirationDate: "",
      renewedDate: "",
      isRenewal: false,
      licenseType: "",
      type: '',
      entityName: '',
      physicalStreet: '',
      physicalCity: '',
      physicalState: '',
      physicalZip: '',
      physicalCounty: '',
      entityApplicants: [],
      applicants: [],
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: false
    };
    this.applyTiles.push(WorkerPermitTile);
  }

if(this.trainingPrograms.length < 1)
  {
    let TrainingProgramTile: ILicenseTile = {
      title: 'Training Program',
      icon: this.iconReference['Training Program'],
      description: 'To apply for a training program through Oregon Psilocybin Services, start here. For more information about the training program application requirements and process, please visit:',
      cols: 2,
      rows: 1,
      color: this.sharedService.blue,
      link: '/training',
      action: 'Apply',
      id: 0,
      status: "",
      licenseIdenitfier: "",
      approvedDate: "",
      renewedDate: "",
      isRenewal: false,
      expirationDate: "",
      licenseType: "",
      type: '',
      entityName: '',
      physicalStreet: '',
      physicalCity: '',
      physicalState: '',
      physicalZip: '',
      physicalCounty: '',
      entityApplicants: [],
      applicants: [],
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      canOperate: false
    };
    this.applyTiles.push(TrainingProgramTile);
  }
}

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
    this.toastr.success("Copied!");
  }

}
@Component({
  selector: "print-details-dialog",
  templateUrl: "./print-details.html",
  styleUrls: ["./license-dashboard.component.scss"]
})

export class PrintDetailsDialog {
  public url = environment.baseUrl;

  constructor(public dialogRef: MatDialogRef<PrintDetailsDialog>,
    public sharedService: SharedService,
    public paymentService: PaymentService,
    @Inject(MAT_DIALOG_DATA) public license: ILicenseTile) { }

    print() {
      window.print();
    }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}


