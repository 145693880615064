import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IQrCodeInformation } from "app/models/licenses/Qr-Code-Information";
import { Observable } from "rxjs";

@Injectable(
  { providedIn: 'root' }
)
export class QrCodeService {
  private api: string = environment.publicApi + 'QrCode/';

  constructor(private httpClient: HttpClient) { }

  getLicense(type: string, id: string): Observable<IQrCodeInformation>{
    return this.httpClient.get<IQrCodeInformation>(this.api + 'GetLicense/' + type + '/' + id);
  }
}
