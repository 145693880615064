import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IDocument } from "app/models/documents/document";
import { ILicenseAdminSettings } from "app/models/product-tracking/admin-settings";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class LicensingService {
  private api: string = environment.privateApi + 'Licensing/';

  constructor(private http: HttpClient) { }

  getAdminSettings(): Observable<ILicenseAdminSettings>{
    return this.http.get<ILicenseAdminSettings>(this.api + 'GetAdminSettings');
  }
}
