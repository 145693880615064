import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { IDocument } from "app/models/documents/document";
import { IDocumentType } from "app/models/documents/documentType";
import { IWorkerLicense } from "app/models/licenses/worker-license";
import { Observable } from "rxjs";

@Injectable(
  { providedIn: 'root' }
)

export class WorkerService {
  private api: string = environment.privateApi + 'Worker/';

  constructor(private httpClient: HttpClient) { }

  getWorkerDocumentTypes(): Observable<IDocumentType[]>{
    return this.httpClient.get<IDocumentType[]>(this.api + 'GetWorkerDocumentTypes');
  }

  requestWithdraw(permitId: number): Observable<IWorkerLicense>{
    return this.httpClient.post<IWorkerLicense>(this.api + "requestWithdraw/" + permitId, null);
  }

  requestSurrender(permitId: number): Observable<IWorkerLicense>{
    return this.httpClient.post<IWorkerLicense>(this.api + "requestSurrender/" + permitId, null);
  }

  getWorkerLicenses(): Observable<IWorkerLicense[]> {
    return this.httpClient.get<IWorkerLicense[]>(this.api + 'GetWorkerLicenses');
  }

  createWorkerLicense(): Observable<IWorkerLicense> {
    return this.httpClient.post<IWorkerLicense>(this.api + 'CreateWorkerLicense', null);
  }

  renewWorkerLicense(): Observable<any>{
    return this.httpClient.get<any>(this.api + 'RenewWorkerLicense');
  }

  updateWorkerApplication(license: IWorkerLicense) {
    this.httpClient.post<IWorkerLicense>(this.api + 'SaveWorkerApplicationState', license).subscribe(
      error => console.log('error', error)
    );
  }

  uploadDocument(file: FormData, applicant: boolean): Observable<IDocument>{
    return this.httpClient.post<IDocument>(this.api + 'UploadWorkerDocument/' + applicant, file)
  }

  downloadFile(fileId: number, applicant: boolean, permitId: number): Observable<Blob>{
    return this.httpClient.get<Blob>(this.api + "DownloadDocument/" + fileId + '/' + applicant + '/' + permitId, { responseType: 'blob' as 'json' })
  }

  deleteDocument(id: number, applicant: boolean, permitId: number): Observable<any>{
    return this.httpClient.delete<any>(this.api + "DeleteDocument/" + id + '/' + applicant + '/' + permitId)
  }

  submitApplication(license: IWorkerLicense): Observable<IWorkerLicense[]>{
    return this.httpClient.post<IWorkerLicense[]>(this.api + "SubmitWorkerApplication", license);
  }

  withdrawPermit(permitId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(this.api + 'WithdrawPermit/' + permitId, null);
  }

  ResubmitPermit(permitId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(this.api + 'ResubmitPermit/' + permitId, null);
  }

  DeletePermit(permitId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(this.api + 'DeletePermit/' + permitId, null);
  }

  DeleteAddress(addressId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(this.api + 'DeleteAddress/' + addressId, null);
  }

  checkWorkerPermitExists(licenseId: string): Observable<boolean>{
    return this.httpClient.get<boolean>(this.api + 'CheckWorkerPermitExists/' + licenseId);
  }
}
