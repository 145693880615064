<div fxLayout="column" fxLayoutGap="8px" style="margin: 20px;">
    <div fxLayout="row" fxLayout.lt-md="column">
      <span fxFlex style="font-family: Oswald, sans-serif; font-size: 20pt;">Active Psilocybin License and Permit Search</span>
    </div>
    <mat-card>
      <mat-card-content>
        <form [formGroup]="SearchForm">
          <div fxLayout="row" fxFlex="80" fxLayout.lt-md="column" fxLayoutAlign.lt-md="" fxLayoutGap="16px" fxLayoutAlign="start center">

          <mat-form-field fxFlex="20" fxFlex.lt-md="100">
            <input matInput placeholder="License Id" formControlName="licenseId">
            <mat-error *ngIf="SearchForm.get('licenseId').hasError('pattern')">
              License Id should match this format: xx-xxxxxxx
            </mat-error>
          </mat-form-field>

            <mat-form-field fxFlex="20">
              <mat-label>
                License Type
              </mat-label>
              <mat-select formControlName="licenseTypes">
                <mat-option *ngFor="let type of licenseTypes" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-stroked-button type="submit" color="primary" (click)="searchPermits()" [disabled]="SearchForm.get('licenseId').value == '' || !SearchForm.valid"
              matTooltip="Search All Licenses" matTooltipShowDelay=800 aria-label="Search All Licenses">
              Search
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxLayoutGap="8px" style="margin: 20px;" *ngIf="searchActive">
  <mat-card>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxFlex="100" fxLayout.lt-md="column" fxLayoutAlign.lt-md="" fxLayoutGap="16px" fxLayoutAlign="start center">
          <span *ngIf="license == null" fxFlex style="font-family: Oswald, sans-serif; font-size: 20pt;">The license you searched for could not be found</span>
           <div *ngIf="license != null"  fxLayoutGap="12px" fxLayout.lt-lg="column" fxLayout="row">
            <div fxLayout="column" fxLayoutGap="8px" >
              <span><strong>License Number</strong></span>
              {{license.licenseNumber}}
            </div>
            <div fxLayout="column" fxLayoutGap="8px">
              <span><strong>Approved Date</strong></span>
                {{license.activeDate | date}}
            </div>
            <div fxLayout="column" fxLayoutGap="8px">
              <span><strong>Expiration Date</strong></span>
                {{license.expirationDate | date}}
            </div>
          </div>
        </div>
        <div fxLayout="row" *ngIf="license != null && license.canOperate"  fxLayoutGap="12px">
          <span class="f-s-16 p-r-16"><i>Acceptable to operate - Renewal under review</i></span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
