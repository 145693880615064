import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ITrainingProgram } from 'app/models/training/training-program';
import { ITrainingProgramContact } from 'app/models/training/training-program-contact';
import { IDocumentType } from 'app/models/documents/documentType';
import { ITrainingProgramRevocation } from 'app/models/training/training-program-revocation';
import { ICurriculumCoreElement } from 'app/models/training/curriculum/curriculum-core-element';
import { ITrainingProgramDocument } from 'app/models/training/training-program-document';
import { ICurriculumPageReference } from 'app/models/training/curriculum/curriculum-page-reference';
import { IPublicTrainingProgramReference } from 'app/models/training/PublicTrainingProgramReference';

@Injectable(
  {providedIn: 'root'}
  )

export class TrainingProgramService {
  private api: string = environment.privateApi + 'TrainingProgram/';
  private publicApi: string = environment.publicApi + 'Application/';
  public SubmittedStatus = "Submitted";
  public ReSubmittedStatus = "Resubmitted";

  constructor(private http: HttpClient) { }

  getTrainingPrograms(): Observable<ITrainingProgram[]>{
    return this.http.get<ITrainingProgram[]>(this.api + 'GetTrainingPrograms');
  }

  createTrainingProgram(): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + 'CreateTrainingProgram', null);
  }

  updateTrainingProgram(program: ITrainingProgram){
    this.http.post<ITrainingProgram>(this.api + 'SaveTrainingProgramState', program).subscribe(
      {
        complete: () => {
          //intentionally blank
        },
        error: err => console.log("error", err)
      }
    );
  }

  getDocumentTypes(): Observable<IDocumentType[]>{
    return this.http.get<IDocumentType[]>(this.api + 'GetDocumentTypes');
  }

  renewTrainingProgram(programId: number): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + 'RenewTrainingProgram/' + programId, null);
  }

  addTrainingProgramContact(contact: ITrainingProgramContact): Observable<ITrainingProgramContact>{
    return this.http.post<ITrainingProgramContact>(this.api + 'AddTrainingProgramContact', contact);
  }

  editTrainingProgramContact(contact: ITrainingProgramContact): Observable<any>{
    return this.http.post<any>(this.api + 'EditTrainingProgramContact', contact);
  }

  deleteTrainingProgramContact(contactId: number): Observable<any>{
    return this.http.delete<any>(this.api + 'DeleteTrainingProgramContact/' + contactId);
  }

  uploadTrainingProgramDocument(file: FormData): Observable<ITrainingProgramDocument>{
    return this.http.post<ITrainingProgramDocument>(this.api + 'UploadTrainingProgramDocument', file)
  }

  uploadTrainingProgramDocumentComments(documents: ITrainingProgramDocument[]): Observable<any> {
    return this.http.post<any>(this.api + 'UpdateTrainingProgramDocumentComments', documents);
  }

  downloadTrainingProgramFile(fileId: number): Observable<Blob>{
    return this.http.get<Blob>(this.api + "DownloadTrainingProgramDocument/" + fileId, { responseType: 'blob' as 'json' })
  }

  deleteTrainingProgramDocument(id: number): Observable<any>{
    return this.http.delete<any>(this.api + "DeleteTrainingProgramDocument/" + id)
  }

  submitTrainingProgram(program: ITrainingProgram): Observable<ITrainingProgram[]>{
    return this.http.post<ITrainingProgram[]>(this.api + "SubmitTrainingProgram", program);
  }

  submitRevocationComment(revocation: ITrainingProgramRevocation): Observable<boolean>{
    return this.http.post<boolean>(this.api + "SubmitRevocationComment", revocation);
  }

  getCurriculumMap(): Observable<ICurriculumCoreElement[]>{
    return this.http.get<ICurriculumCoreElement[]>(this.api + 'GetCurriculumMap');
  }

  uploadCurriculumPageReference(reference: ICurriculumPageReference[]): Observable<ICurriculumPageReference[]>{
    return this.http.post<ICurriculumPageReference[]>(this.api + "UploadCurriculumPageReference", reference);
  }

  requestWithdraw(id: number): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + "requestWithdraw/" + id, null);
  }

  requestSurrender(id: number): Observable<ITrainingProgram>{
    return this.http.post<ITrainingProgram>(this.api + "requestSurrender/" + id, null);
  }

  getPublicTrainingProgramList(): Observable<IPublicTrainingProgramReference[]> {
    return this.http.get<IPublicTrainingProgramReference[]>(this.publicApi + "GetPublicTrainingProgramList")
  }

}
