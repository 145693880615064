import { Injectable } from "@angular/core";
import { Router, CanActivate, CanActivateChild } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(public router: Router, private authSvc: AuthService) {
  }

  async canActivate(): Promise<boolean> {
    if(this.authSvc.isAuthenticated()) {
      return true;
    }

    this.router.navigate(['/welcome']);
    return false;
  }

  canActivateChild(): boolean {
    return this.authSvc.isAuthenticated();
  }
}
