
<div *ngIf="processing">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>

<div *ngIf="!processing">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-b-16">
    <span>Pay Online</span>
  </div>

  <mat-card *ngIf="isValid">
    <span ><strong>NOTE:</strong> <br/><br />
      <ul>
        <li>When ready to pay click the '{{proceedButtonText}}' Button below. </li>
        <li>You will then be redirected to our 3rd party payment processor.  </li>
        <li>Once payment is complete you will be redirected back to this site for further instructions.</li>
      </ul>
    </span>
  </mat-card>

  <mat-card class="note" *ngIf="!isValid">
    <span ><strong> No pending payments found.</strong>
    </span>
  </mat-card>

  <div *ngFor="let payment of payments">

    <mat-card >
      <mat-card-header>
        <mat-card-title>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-b-16">
            <span>{{payment.entityId}} ({{payment.entityName}}) - {{paymentService.getPaymentTypeDescription(payment.entityType, payment.type, false)}}</span>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-b-16">

          <span *ngIf="!payment.paid">
            <button mat-stroked-button type='button' (click)="payNow(payment.id)" color="primary">{{proceedButtonText}}: $ {{payment.amount}}</button>
          </span>
        </div>


      </mat-card-content>
    </mat-card>
  </div>


</div>
