import { Component} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IQrCodeInformation } from 'app/models/licenses/Qr-Code-Information';
import { PublicLicensesService } from 'app/services/licenses/public-licenses.service';

@Component({
  selector: 'app-public-license-search',
  templateUrl: './public-license-search.component.html',
  styleUrls: ['./public-license-search.component.scss']
})
export class PublicLicenseSearchComponent {
  public license: IQrCodeInformation = {
    licenseNumber: '',
    status: '',
    activeDate: '',
    expirationDate: '',
    licenseType: '',
    renewedDate: '',
    isRenewal: false,
    canOperate: false
  };

  public licenseTypes: string[] = ['Worker Permit', 'Facilitator', 'Manufacturer', 'Service Center', 'Laboratory'];
  public SearchForm = new UntypedFormGroup({
    licenseId: new UntypedFormControl('', Validators.pattern( /^[a-zA-Z]{2}[._-][a-zA-Z0-9]+/i )),
    licenseTypes: new UntypedFormControl('', Validators.required),
  });
  public licenseId: string;
  public licenseType: string;
  public searchActive: boolean = false;
  constructor(public publicLicensesService: PublicLicensesService) {
  }


    searchPermits(): void {
    let form = this.SearchForm.value;
    this.licenseId = form.licenseId;
    this.licenseType = form.licenseTypes;
        this.publicLicensesService.getApprovedLicenses(this.licenseType, this.licenseId).subscribe(
        response => this.license = response,
        error => console.log('error', error),
        () =>
        {
            this.searchActive = true;
        }
      );
    }
}


