<mat-card-content id="printPageButton">
  <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
    <button mat-raised-button color="primary" (click)="print()">Print</button>
  </div>
</mat-card-content>

  <div>
  <img src="assets/images/ohaLogoTransparent-small.png" alt="OHA Logo">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutAlign="start center">
        <mat-icon class="large-icon m-16">group</mat-icon>
        <span class="f-s-20 p-l-16">Facilitator License ID:</span>
        <span class="f-s-16 p-r-16">{{license.licenseId}}</span>
        <qrcode  [qrdata]=" url + '/license-information/'+ 'FacilitatorLicense/' + license.id"></qrcode>
      </div>
    </mat-card-content>
  </mat-card>
  <div fxLayout="column" fxLayoutGap="8px">
    <mat-card>
      <mat-card-title>
        Name
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" class="p-t-24">
          <div fxLayout="column" fxLayoutGap="8px">
            <span><strong>Name</strong></span>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.applicants[0].legalFirstName}} {{license.applicants[0].legalMiddleName}} {{license.applicants[0].legalLastName}}</span>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxLayout="column" fxLayoutGap="8px">
    <mat-card>
      <mat-card-title>
        Facilitator License Information
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" class="p-t-24">
          <div fxLayout="column" fxLayoutGap="8px">
            <span><strong>Approved Date</strong></span>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.approvedDate}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <span><strong>Expiration Date</strong></span>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.expirationDate}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px" *ngIf="license.isRenewal">
            <span><strong>Date Renewal Approved</strong></span>
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{license.renewedDate}}</span>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" class="p-t-24">
          <span class="f-s-16 p-r-16" *ngIf="license.canOperate"><i>Acceptable to operate - Renewal under review</i></span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
