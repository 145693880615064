import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../configuration/angular/material.module';
import { MaterialExtensionsModule } from '@ng-matero/extensions';
import { FlexLayoutModule, MediaMarshaller } from '@angular/flex-layout';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ErrorCodeComponent } from './components/error-code/error-code.component';

import { DisableControlDirective } from './directives/disable-control.directive';

import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ToObservablePipe } from './pipes/to-observable.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

import { QRCodeModule } from 'angularx-qrcode';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { DisableScrollDirective } from './directives/disable-scroll.directive';

const MODULES = [
  MaterialModule,
  MaterialExtensionsModule,
  FlexLayoutModule,
  NgProgressModule,
  NgProgressRouterModule,
  NgProgressHttpModule,
  NgSelectModule,
  FormlyModule,
  FormlyMaterialModule,
  ToastrModule,
  TranslateModule,
  NgxMaskModule,
  PDFExportModule,
  QRCodeModule,
  ExcelExportModule
];
const COMPONENTS = [BreadcrumbComponent, PageHeaderComponent, ErrorCodeComponent];
const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [DisableControlDirective, DisableScrollDirective];
const PIPES = [SafeUrlPipe, ToObservablePipe];

@NgModule({
    declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ...MODULES
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ...MODULES,
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES,
    ]
})
export class SharedModule {
  lastValue;

  public constructor (
    m: MediaMarshaller,
  ) {
    // hack until resolve: https://github.com/angular/flex-layout/issues/1201
    // @ts-ignore
    m.subject.subscribe((x) => {
      // @ts-ignore
      if (m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
        // @ts-ignore
        this.lastValue = [...m.activatedBreakpoints];
      } else {
        // @ts-ignore
        m.activatedBreakpoints = [...this.lastValue];
        // @ts-ignore
        m.hook.collectActivations = () => {};
        // @ts-ignore
        m.hook.deactivations = [...this.lastValue];
      }
    });
  }

}
