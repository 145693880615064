import { Injectable } from '@angular/core';
import { IUserProfile } from 'app/models/user/user-profile';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { ILicenseStatusOverview } from 'app/models/licenses/license-status-overview';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, IConfirmDialog } from 'app/components/confirm-dialog/confirm-dialog.component';
import { map, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { IDirectoryItem } from 'app/models/directory/directory-item';

@Injectable({ providedIn: 'root' })
export class SharedService {
  public mobile: boolean = false;
  public tablet: boolean = false;
  public development: boolean = environment.build === 'Development';
  private api: string = environment.privateApi;
  private changeTheme: Subject<string> = new Subject<string>();
  public darkMode: boolean = false;
  public purple: string = '#9355dc';
  public blue: string = '#628ef4';
  public gold: string = '#ffbe2e';
  public green: string = '#70e17b';
  public pink: string = '#e9695f';
  public orange: string = '#EC891D';
  public red: string = '#fc4132';
  public loading: boolean = false;
  public today: Date = new Date(Date.now());

  /*
   * Statuses
   */
  public pending: string = "Pending";
  public submitted: string = "Submitted";
  public submittedForApproval: string = 'Submitted for Approval';
  public renewalSubmittedForApproval: string = 'Renewal Submitted for Approval';
  public resubmitted: string = "Resubmitted";
  public awaitingPayment: string = "Approved, Pending Payment and Clearance";
  public approved: string = "Approved";
  public incomplete: string = "Incomplete";
  public insufficient: string = "Insufficient";
  public underReview: string = "Under Review";
  public denied: string = "Denied";
  public revoked: string = "Revoked";
  public expired: string = "Expired";
  public withdrawn: string = "Withdrawn";
  public surrendered: string = "Surrendered";
  public closed: string = 'Closed';
  public pendingRenewal: string = 'Pending Renewal';
  public renewalSubmitted: string = 'Renewal Submitted/For Review';

  /*
   Entity Types
  */
  public trainingProgram: string = 'TrainingProgram';
  public facilitator: string = 'Facilitator';
  public manufacturer: string = 'Manufacturer';
  public serviceCenter: string = 'ServiceCenter';
  public testingLab: string = 'TestingLab';
  public workerPermit: string = 'WorkerPermit';

  userProfile: IUserProfile = {
    userId: null,
    firstName: null,
    lastName: null,
    email: null,
    city: null,
    county: null,
    dob: null,
    middleInitial: null,
    phone: null,
    state: null,
    street: null,
    zip: null,
    currentPage: null,
    currentStep: null,
    theme: environment.theme
  }

  allowedFiles:string[] = [
    ".doc", ".docx", ".rtf", ".xls", ".xlsx", ".pdf", ".txt", ".png", ".jpg", ".jpeg", ".bmp", ".mp4"
  ];

  stateList: string[] = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL",
    "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT",
    "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI",
    "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "American Samoa" ,
    "Commonwealth of the Northern Mariana Islands", "Guam", "Puerto Rico", "US Virgin Islands",
    "Federated States of Micronesia", "Republic of the Marshall Islands", "Republic of Palau"
  ];

  countyList: string[] = [
    "Baker", "Benton", "Clackamas", "Clatsop", "Columbia", "Coos", "Crook",
    "Curry", "Deschutes", "Douglas", "Gilliam", "Grant", "Harney", "Hood River",
    "Jackson", "Jefferson", "Josephine", "Klamath", "Lake", "Lane", "Lincoln",
    "Linn", "Malheur", "Marion", "Morrow", "Multnomah", "Polk", "Sherman",
    "Tillamook", "Umatilla", "Union", "Wallowa", "Wasco", "Washington", "Wheeler",
    "Yamhill"
  ];

  loremIpsum: string = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
  in culpa qui officia deserunt mollit anim id est laborum.`

  public confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(private httpClient: HttpClient, public dialog: MatDialog, public toastr: ToastrService) { }
  public validateFile(file: File) : boolean{
    let extension = file.name.substring(file.name.lastIndexOf('.'));
    if(this.allowedFiles.includes(extension))
      return true;
    else
      return false;
  }

  public openNotice(message: string): void {
    let data: IConfirmDialog = {
      message: message,
      confirmation: false
    }
    this.openDialog(data);
  }

  public openConfirm(message: string): void {
    let data: IConfirmDialog = {
      message: message,
      confirmation: true
    }
    this.openDialog(data);
  }

  openDialog(data: IConfirmDialog): void {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data,
      role: 'dialog',
      ariaLabel: "Confirmation Dialog",
      minWidth: '300px',
      maxWidth: !this.mobile? '700px' : '300px',
      maxHeight: this.mobile? '600px' : '700px',
      panelClass: this.userProfile.theme === "dark" ? "theme-dark" : "",
    });
  }

  public confirmed(): Observable<any>{
    return this.confirmDialogRef.afterClosed().pipe(take(1), map(response => {
      return response;
    }));
  }

  get isMobile(): boolean {
    return this.mobile;
  }

  get counties(): string[] {
    return this.countyList;
  }

  get states(): string[] {
    return this.stateList;
  }

  themeChanged$ = this.changeTheme.asObservable();

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
    this.toastr.success("Copied!");
  }

  toggleTheme() {
    this.darkMode = this.userProfile.theme === 'dark';
    this.changeTheme.next(this.userProfile.theme);
  }

  updateUser(page: string): void{
    if(this.userProfile.userId === null){
      this.loading = true;
      this.getUserInfo().subscribe(
        response => this.userProfile = response,
        error => this.consoleLog(error),
        () => {
          this.toggleTheme();
          this.updateUserPage(page);
          this.loading = false;
        }
      );
    }
    else{
      this.updateUserPage(page);
    }
  }

  updateUserPage(page: string): void {
    if (this.userProfile.currentPage !== page) {
      this.userProfile.currentPage = page;
      this.updateUserProfileState();
    }
  }

  updateUserProfileState(): void {
    this.loading = true;
    this.httpClient.post<IUserProfile>(this.api + "UserProfile/UpdateUserProfileState", this.userProfile).subscribe(
      () => { this.loading = false; },
      error => {
        this.consoleLog(error);
        this.loading = false;
       }
    );
  }

  getLicenseStatusOverview(): Observable<ILicenseStatusOverview> {
    return this.httpClient.get<ILicenseStatusOverview>(this.api + 'Shared/GetLicenseStatusOverview')
  }

  getUserInfo(): Observable<IUserProfile> {
    return this.httpClient.get<IUserProfile>(this.api + "UserProfile/GetUserProfile");
  }

  udpateUserInfo(user: IUserProfile): Observable<IUserProfile> {
    return this.httpClient.post<IUserProfile>(this.api + "UserProfile/UpdateUserProfile", user);
  }

  consoleLog(error: any): void {
    if(this.development){
      console.log('error', error);
    }
  }

  isCancelled(response: any): boolean {
    return response === 'cancel' || response === null || response === '' || response === undefined;
  }

  changeDirectory(licenseId: number, licenseType: string, directoryItem: IDirectoryItem): Observable<boolean>{
    return this.httpClient.post<boolean>(this.api + 'Shared/ChangeDirectory/' + licenseId + '/' + licenseType, directoryItem);
  }

}
