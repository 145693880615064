<section>
  <div fxLayout="row">
    <span class="f-s-20" fxFlex>Exam Results</span>
    <button mat-raised-button (click)="close()" color="warn">Close</button>
  </div>
</section>
<mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center" class="p-t-16">
    <span *ngIf="exam.passed" class="f-s-20">Congratulations, you passed with a score of {{exam.score}}%</span>
    <span *ngIf="!exam.passed" class="f-s-18"> You did not pass this exam, your score was {{exam.score}}%</span>
    <span *ngIf="!exam.passed" class="f-s-18"> You must pass this exam before submitting your application, please try again.</span>
  </div>
</mat-dialog-content>
