import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { WorkerService } from "app/services/licenses/worker.service";
import { Observable, of, timer } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })

export class WorkerPermitValidator implements AsyncValidator {
  constructor(private service: WorkerService){}

  validate(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    return timer(500).pipe(switchMap(() => this.service.checkWorkerPermitExists(control.value).pipe(
      map(exists => (!exists ? { labNotFound: true } : null)),
      catchError(() => of(null))
    )));
  }
}
