<div *ngIf="processing">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>

<mat-card *ngIf="!processing">
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-b-16">
        <mat-icon class="icon-20">attach_money</mat-icon>
        <span>Payments</span>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
<mat-table [dataSource]="paymentsSource" aria-describedby="payments" class="mat-elevation-z8">
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
    <mat-cell  *matCellDef="let payment">
      <span class="mobile-label">Type</span>
      <span style="word-break: break-word">
      {{ paymentService.getPaymentTypeDescription(payment.entityType, payment.type, false) }}
    </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Application</mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span class="mobile-label">Application</span>
      <span>{{payment.entityName}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="appStatus">
    <mat-header-cell *matHeaderCellDef>Application Status </mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span class="mobile-label">Application Status</span>
      {{payment.applicationStatus}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>Payment Status </mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span class="mobile-label">Payment Status</span>
      {{payment.status}}
      &nbsp; <mat-icon *ngIf="payment.paid">done</mat-icon>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="paymentCleared">
    <mat-header-cell *matHeaderCellDef>Payment Cleared</mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span class="mobile-label">Payment Cleared</span>
      {{payment.paymentCleared}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span class="mobile-label">Amount</span>
      $ {{payment.amount}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="paymentMethod">
    <mat-header-cell *matHeaderCellDef>Payment Method</mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span class="mobile-label">Payment Method</span>
      {{payment.paymentMethod}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="sku">
    <mat-header-cell *matHeaderCellDef>SKU</mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span class="mobile-label">SKU</span>
      {{payment.sku}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="comments">
    <mat-header-cell *matHeaderCellDef>Notes</mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span class="mobile-label">Notes</span>
      <span style="white-space: pre-wrap">{{payment.comment}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let payment">
      <span *ngIf="canPay(payment)">
        <button mat-stroked-button type='button' (click)="pay(payment.entityType, payment.type, payment.id, payment.comment)">Pay Fee</button>
      </span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="paymentColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: paymentColumns"></mat-row>
</mat-table>

  </mat-card-content>
</mat-card>

