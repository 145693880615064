import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDirectoryItem } from 'app/models/directory/directory-item';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dialog-change-directory',
  templateUrl: './dialog-change-directory-consent.component.html'
})
export class DialogChangeDirectoryComponent {

  public licenseForm = new UntypedFormGroup({
    directoryConsent: new UntypedFormControl("", [Validators.required]),
    directoryConsentWebsite: new UntypedFormControl(false),
    directoryConsentEmail: new UntypedFormControl(false),
    directoryConsentPhone: new UntypedFormControl(false),
    directoryPhone: new UntypedFormControl(""),
    directoryEmail: new UntypedFormControl(""),
    directoryWebsite: new UntypedFormControl(''),
    directoryPreferredName: new UntypedFormControl(false),
  }, {
    validators: [
      this.directoryConsentWebsiteValidator(),
      this.directoryConsentEmailValidator(),
      this.directoryConsentPhoneValidator(),
    ]
  });

  public preferredFirstName: string = '';
  public preferredLastName: string = '';

  public directory: IDirectoryItem = {
    licenseName: '',
    licenseType: '',
    directoryConsent: false,
    directoryConsentWebsite: false,
    directoryConsentEmail: false,
    directoryConsentPhone: false,
    directoryPhone: '',
    directoryEmail: '',
    directoryWebsite: '',
    directoryPreferredName: false
  };

  constructor(public dialogRef: MatDialogRef<DialogChangeDirectoryComponent>,
              public sharedService: SharedService,
              public toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

ngOnInit(): void {
  this.updateLicenseForm();
  this.licenseForm.markAllAsTouched();
}

updateLicenseForm(): void {
  this.licenseForm.patchValue({
    directoryConsent: this.data.license.directoryConsent,
    directoryConsentWebsite: this.data.license.directoryConsentWebsite,
    directoryConsentEmail: this.data.license.directoryConsentEmail,
    directoryConsentPhone: this.data.license.directoryConsentPhone,
    directoryPhone: this.data.license.directoryPhone,
    directoryEmail: this.data.license.directoryEmail,
    directoryWebsite: this.data.license.directoryWebsite,
  });
  if(this.data.type == 'Facilitator') {
    this.preferredFirstName = this.data.license.applicants[0].preferredFirstName;
    this.preferredLastName = this.data.license.applicants[0].preferredLastName;
    this.licenseForm.patchValue({
      directoryPreferredName: this.data.license.directoryPreferredName,
    });
  }
}


directoryConsentWebsiteValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const websiteConsent = control.value.directoryConsentWebsite;
    const website = control.value.directoryWebsite;
    const directoryConsent = control.value.directoryConsent;
    if (websiteConsent && directoryConsent) {
      return (website !== null && website !== '' && website !== undefined) ? null : { directoryWebsiteRequired: true };
    }
    return null;
  };
}

validateDirectoryConsentWebsite(): boolean {
  const websiteControl = this.licenseForm.get('directoryWebsite');
  const websiteConsent = this.licenseForm.get('directoryConsentWebsite').value;
  const directoryConsent = this.licenseForm.get('directoryConsent').value;

  if(websiteConsent && directoryConsent)
  {
    if (websiteControl.value === '' || websiteControl.value === null || websiteControl.value === undefined) {
      websiteControl.setErrors({ required: true });
      return true;
    }

    const pattern = /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]/i;

    if (!pattern.test(websiteControl.value)) {
      websiteControl.setErrors({ pattern: true });
      return true;
    }
  }
  websiteControl.clearValidators();
  websiteControl.updateValueAndValidity();
  return false;
}

directoryConsentEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailConsent = control.value.directoryConsentEmail;
    const email = control.value.directoryEmail;
    const directoryConsent = control.value.directoryConsent;
    if (emailConsent && directoryConsent) {
      return (email !== null && email !== '' && email !== undefined) ? null : { directoryEmailRequired: true };
    }
    return null;
  };
}

validateDirectoryConsentEmail(): boolean {
  if (this.licenseForm.hasError('directoryEmailRequired') && this.licenseForm.get('directoryConsentEmail').value === true) {
    this.licenseForm.get('directoryEmail').setErrors(['required']);
    return true;
  }
  this.licenseForm.get('directoryEmail').clearValidators();
  this.licenseForm.get('directoryEmail').updateValueAndValidity();
  return false;
}


directoryConsentPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneConsent = control.value.directoryConsentPhone;
    const phone = control.value.directoryPhone;
    const directoryConsent = control.value.directoryConsent;
    if (phoneConsent && directoryConsent) {
      return (phone !== null && phone !== '' && phone !== undefined) ? null : { directoryPhoneRequired: true };
    }
    return null;
  };
}

validateDirectoryConsentPhone(): boolean {
  if (this.licenseForm.hasError('directoryPhoneRequired') && this.licenseForm.get('directoryConsentPhone').value === true) {
    this.licenseForm.get('directoryPhone').setErrors(['required']);
    return true;
  }
  this.licenseForm.get('directoryPhone').clearValidators();
  this.licenseForm.get('directoryPhone').updateValueAndValidity();
  return false;
}

save(): void {
  let directoryInfo = this.licenseForm.value;
  this.licenseForm.markAllAsTouched();
  if(this.licenseForm.errors) {
    this.toastr.error("You must fill out the required fields");
  }
  else {
    this.directory.directoryConsent = directoryInfo.directoryConsent;
    this.directory.directoryConsentWebsite = directoryInfo.directoryConsentWebsite;
    this.directory.directoryConsentEmail = directoryInfo.directoryConsentEmail;
    this.directory.directoryConsentPhone = directoryInfo.directoryConsentPhone;
    this.directory.directoryPhone = directoryInfo.directoryPhone;
    this.directory.directoryEmail = directoryInfo.directoryEmail;
    this.directory.directoryWebsite = directoryInfo.directoryWebsite;
    this.directory.directoryPreferredName = directoryInfo.directoryPreferredName;

    this.sharedService.changeDirectory(this.data.license.id, this.data.type, this.directory).subscribe(
      response => {
        if(response){
          this.toastr.success("License Directory Updated");
          this.dialogRef.close(this.directory);
        }
        else
          this.toastr.error("Could not change directory choice");
      },
      error => console.log('error', error)
    );
  }
}
cancel(): void {
  this.dialogRef.close(false);
}

}
