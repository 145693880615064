
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { UserService } from 'app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { IPayment } from '../../models/payment';
import { PaymentService } from '../../service/payment.service';




@Component({
  selector: 'app-pay-online',
  templateUrl: './pay-online.component.html',
  styleUrls: ['./pay-online.component.scss']
})

export class PayOnlineComponent implements OnInit {

  public processing: boolean = false;
  public cardColor: string = this.sharedService.blue + '20';
  public id: number = 0;
  public entityType: string = "";
  public payments: IPayment[] = [];
  public proceedButtonText = 'Proceed to Payment';
  public isValid: boolean = true;


  constructor(public paymentService: PaymentService,
              public sharedService: SharedService,
              public authService: AuthService,
              public userService: UserService,
              private toastr: ToastrService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id") != null) {
      this.id = +this.route.snapshot.paramMap.get("id");
    }

    this.route.queryParams.subscribe(
      params =>
      {
        this.entityType =  params['entityType'];
        this.loadPayment();
      }
      );

  }

  payNow(id: number) {
    this.processing = true;
    this.paymentService.negotiateToken(id).subscribe(result => {
      window.location.href = result;
    }, error => {
      this.processing = false;
      this.toastr.error('Unable communicate with payment processor.  Please try again later.' + error.message);
    });
  }

  loadPayment() {

    this.processing = true;
    this.userService.getUserLicenses().subscribe(result => {
      let license = result.find(l => l.id == this.id && l.payments != undefined && l.payments.some(p => !p.paid && p.entityType == this.entityType));
      if(license != null) {
          this.payments = license.payments.filter(p => !p.paid && p.entityType==this.entityType);
          if(this.payments != undefined && this.payments != null && this.payments.length > 0) {
            this.payments.forEach(p => {
              p.entityId = license.licenseId;
              p.entityName = license.name;
              this.isValid = true;
            })
          }
          else {
            this.toastr.error('Unable get user payment info.');
            this.isValid = false;
          }
      }
      else {
        this.toastr.error('Unable get user payment info.');
        this.isValid = false;
      }

      this.processing = false;
    }, error => {
      this.processing = false;
      this.toastr.error('Unable get user payment info.');
      this.isValid = false;
    });


  }

}


