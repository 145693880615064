import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { IMenu } from './models/core/menu';

export const menu$ = new BehaviorSubject<IMenu[]>([
  {
    route: "licensing-home",
    name: "TLC Home",
    type: "link",
    icon: "home"
  },
  {
    route: "license-dashboard",
    name: "My Licenses and Permits",
    type: "link",
    icon: "list_alt"
  },
  {
    route: "product-tracking",
    name: "Product Tracking",
    type: "link",
    icon: "lan"
  },
  {
    route: "303-reporting",
    name: "303 Data Reporting Portal",
    type: "link",
    icon: "toc"
  },
  {
    route: "apply",
    name: "Apply",
    type: "link",
    active: environment.activeLicenses.all,
    icon: "book"
  },
  {
    route: "license/facilitator",
    name: "Facilitators",
    type: "link",
    icon: "group"
  },
  {
    route: "license/manufacturer",
    name: "Manufacturers",
    type: "link",
    icon: "scale"
  },
  {
    route: "license/service-center",
    name: "Service Centers",
    type: "link",
    icon: "store_mall_directory"
  },
  {
    route: "license/testing-lab",
    name: "Laboratory",
    type: "link",
    icon: "domain"
  },
  {
    route: "training",
    name: "Training Program",
    type: "link",
    icon: "description"
  },
  {
    route: "license/worker-permit",
    name: "Worker Permits",
    type: "link",
    icon: "badge"
  },
  {
    route: "payments",
    name: "Payments",
    type: "link",
    icon: "attach_money"
  },
  {
    route: "messages",
    name: "Messages",
    type: "link",
    icon: "mail"
  }
]);
