<div fxLayout="row">
  <mat-card class="sticky-header p-y-0" fxFlex="40" fxFlex.lt-lg="100" >
    <mat-card-content >
      <mat-card-title>Your Account Information</mat-card-title>
      <div fxLayout="column" fxLayoutGap="8px" style="padding-bottom: 2em">
        <div fxLayout="row">
          <span fxFlex="30" fxFlex.lt-md="40"><strong>Account Name</strong></span>
          <div fxLayout="column">
            <span>
              {{username}}
            </span>
          </div>
        </div>
        <div fxLayout="row">
          <span fxFlex="30" fxFlex.lt-md="40"><strong>Account Email Address</strong></span>
          <div fxLayout="column">
            <span>
              {{email}}
            </span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
