import { trigger, transition, style, animate } from '@angular/animations';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IApplicant } from 'app/models/licenses/applicant';
import { IManufacturerLicense } from 'app/models/licenses/manufacturer-license';
import { SharedService } from 'app/services/core/shared.service';
import { ManufacturerService } from 'app/services/licenses/manufacturer.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicantComponent } from '../applicant/applicant.component';
import { EntityApplicantComponent } from '../entity-applicant/entity-applicant.component';
import { TestingLabDetailsDialog } from '../testing-lab/testing-lab.component';
import { DialogAddPermitComponent } from '../dialog-add-permit/dialog-add-permit.component';
import { DialogChangeDirectoryComponent } from '../dialog-change-directory-consent/dialog-change-directory-consent.component';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import { IEntityApplicant } from 'app/models/licenses/entity-applicant';

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})

export class ManufacturerComponent implements OnInit, OnDestroy {
  @ViewChild(ApplicantComponent) individualApplicants: ApplicantComponent;
  @ViewChild(EntityApplicantComponent) entityApplicants: EntityApplicantComponent;
  public loadingManufacturer: boolean = false;
  public showStartApplication: boolean = false;
  public showStartButton: boolean = true;
  public showSubmitted: boolean = false;
  public viewForm: boolean = false;
  public loadingIndex: {[id: number]: boolean} = {};
  public licenseSteps: string[] = [
    "gettingStarted",
    "manufacturerInformation",
    "applicants",
    "financialInterests",
    "endorsements",
    "documents",
    "feeInformation",
    "reviewAndSubmit"
  ];
  public stepsIndex: { [id: string]: number } = {
    "gettingStarted": 0,
    "manufacturerInformation": 1,
    "applicants": 2,
    "financialInterests": 3,
    "endorsements": 4,
    "documents": 5,
    "feeInformation": 6,
    "reviewAndSubmit": 7
  };
  public licenses: IManufacturerLicense[] = [];
  public license: IManufacturerLicense = {
    id: 0,
    userId: '',
    licenseId: '',
    status: '',
    entityName: '',
    entityWebsite: '',
    registeredBusiness: null,
    businessInOregon: null,
    secretaryofStateNumber: '',
    registrationAttestation: false,
    registryNumber: '',
    registeredIn: '',
    financialAttestation: false,
    applicantAttestation: false,
    physicalState: 'OR',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    premisesDescription: '',
    mailingIsPhysical: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    primaryContactName: '',
    primaryContactNumber: '',
    primaryContactEmail: '',
    primaryContactId: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    fungiCultivation: false,
    psilocybinExtraction: false,
    ediblePsilocybinProduction: false,
    edibleAttestation: false,
    denyEndorsementAttestation: false,
    shareTransferPrices: false,
    canRenew: false,
    canOperate: false,
    reducedRequest: false,
    electronicSignature: '',
    attestation: false,
    directoryConsent: null,
    directoryConsentWebsite: false,
    directoryConsentEmail: false,
    directoryConsentPhone: false,
    directoryPhone: '',
    directoryEmail: '',
    directoryWebsite: '',
    applicationDate: '',
    approvedDate: '',
    renewedDate: '',
    expirationDate: '',
    entityApplicants: [],
    workerPermits: [],
    applicants: [],
    licenseDocuments: [],
    payments: [],
    timely: false,
    financialInterestCorrect: null,
    financialInterestCorrectReason: '',
    manufacturerInformationCorrect: null,
    manufacturerInformationCorrectReason: '',
    endorsementsCorrect: null,
    endorsementsCorrectReason: '',
    isRenewal: false,
    applicantsCorrect: null,
    applicantsCorrectReason: ''
  }
  public routeSubscription: Subscription;
  public step: number = 0;
  public renewing: boolean = false;
  public hasApplication: boolean = false;
  public licenseLocked: boolean = false;
  public documentTypes: IDocumentType[] = [];
  public documentTypeReference: { [id: number]: string } = {};
  public missingTypes: {[type: number]: boolean} = {};
  public lucs: string = 'Land Use Compatibility Statement'
  public premisesSketch: string = 'Premises Plan';
  public ownerConsent: string = 'Owner Consent';
  public socialEquityPlan: string = 'Social Equity Plan';
  public authorizedIndividual: string = 'Authorized Individual Form';
  public reducedFee: string = 'Reduced Fee';
  public changeOfLocationRequest: string = 'Change of Location Request'
  public premisePicture: string = 'Premise Picture';
  public updatedEquityPlan: string = "Updated Social Equity Plan";
  public equityPlanEvaluation: string = "Social Equity Plan Evaluation";
  public updatedPremisePicture: string = "Updated Premise Picture";
  public lucsType: number = 0;
  public premisesType: number = 0;
  public ownerConsentType: number = 0;
  public socialEquityType: number = 0;
  public authorizedIndividualType: number = 0;
  public reducedFeeType: number = 0;
  public premisePictureType: number = 0;
  public updatedEquityPlanType: number = 0;
  public equityPlanEvaluationType: number = 0;
  public updatedPremisePictureType: number = 0;
  public financialAttestationValid: boolean = true;
  public applicantAttestationValid: boolean = true;
  public contactValid: boolean = true;
  public endorsementsValid: boolean = true;
  public documentsValid: boolean = true;
  public applicantsValid: boolean = true;
  public entityApplicantsValid: boolean = true;
  public editingApplicant: boolean = false;
  public editingEntityApplicant: boolean = false;
  public cardColor: string = this.sharedService.gold + '20';
  public startedRenewal: boolean = false;
  public canApplyStatuses: string[] = [
    this.sharedService.denied,
    this.sharedService.expired,
    this.sharedService.withdrawn,
    this.sharedService.revoked,
    this.sharedService.closed,
    this.sharedService.surrendered,
    this.sharedService.insufficient
  ];

  public licenseForm = new UntypedFormGroup({
    entityName: new UntypedFormControl('', Validators.required),
    entityWebsite: new UntypedFormControl('', Validators.pattern( /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]/i )),
    businessInOregon: new UntypedFormControl(Validators.required),
    registrationAttestation: new UntypedFormControl(false, Validators.requiredTrue),
    registryNumber: new UntypedFormControl(''),
    registeredBusiness: new UntypedFormControl(),
    secretaryofStateNumber: new UntypedFormControl(''),
    registeredIn: new UntypedFormControl(''),
    financialAttestation: new UntypedFormControl(false),
    applicantFeeAttestation: new UntypedFormControl(false),
    applicantDenialAttestation: new UntypedFormControl(false),
    physicalCity: new UntypedFormControl('', Validators.required),
    physicalStreet: new UntypedFormControl('', Validators.required),
    physicalZip: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl('', Validators.required),
    premisesDescription: new UntypedFormControl(''),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingState: new UntypedFormControl(''),
    mailingCity: new UntypedFormControl(''),
    mailingStreet: new UntypedFormControl(''),
    mailingZip: new UntypedFormControl('', [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(''),
    primaryContactName: new UntypedFormControl(''),
    primaryContactNumber: new UntypedFormControl(''),
    primaryContactEmail: new UntypedFormControl(''),
    primaryContactId: new UntypedFormControl(''),
    language: new UntypedFormControl(''),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    fungiCultivation: new UntypedFormControl(false),
    psilocybinExtraction: new UntypedFormControl(false),
    ediblePsilocybinProduction: new UntypedFormControl(false),
    firstEdibleAttestation: new UntypedFormControl(false),
    secondEdibleAttestation: new UntypedFormControl(false),
    denyEndorsementAttestation: new UntypedFormControl(false),
    applicantsCorrect: new UntypedFormControl(),
    applicantsCorrectReason: new UntypedFormControl(),
    financialInterestCorrect: new UntypedFormControl(),
    financialInterestCorrectReason: new UntypedFormControl(''),
    manufacturerInformationCorrect: new UntypedFormControl(),
    manufacturerInformationCorrectReason: new UntypedFormControl(),
    endorsementsCorrect: new UntypedFormControl(),
    endorsementsCorrectReason: new UntypedFormControl('')
  }, {
    validators: [
      this.mailingStreetValidator(),
      this.mailingCityValidator(),
      this.mailingStateValidator(),
      this.mailingZipValidator(),
      this.previousLicenseValidator(),
      this.previousLicenseValidator(),
      this.businessInOregonValidator(),
      this.secretaryOfStateNumberValidator(),
      this.businessInOtherStateValidator(),
      this.registeredLocationValidator(),
      this.registryNumberValidator(),
      this.manufacturerInformationCorrectValidator(),
    ]
  });

  public documentsForm = new UntypedFormGroup({
    lucs: new UntypedFormControl(false),
    premisesSketch: new UntypedFormControl(false),
    ownerConsent: new UntypedFormControl(false),
    socialEquityPlan: new UntypedFormControl(false),
    reducedFee: new UntypedFormControl(false),
    changeOfLocationRequest: new UntypedFormControl(false),
    premisePicture: new UntypedFormControl(false),
    updatedEquityPlan: new UntypedFormControl(false),
    equityPlanEvaluation: new UntypedFormControl(false),
    updatedPremisePicture: new UntypedFormControl(false),
  });

  public verifyForm = new UntypedFormGroup({
    electronicSignature: new UntypedFormControl('', [Validators.required]),
    attestation: new UntypedFormControl(false, [Validators.requiredTrue]),
    directoryConsent: new UntypedFormControl("", [Validators.required]),
    directoryConsentWebsite: new UntypedFormControl(false),
    directoryConsentEmail: new UntypedFormControl(false),
    directoryConsentPhone: new UntypedFormControl(false),
    directoryPhone: new UntypedFormControl(""),
    directoryEmail: new UntypedFormControl(""),
    directoryWebsite: new UntypedFormControl(''),
  }, {
    validators: [
      this.directoryConsentWebsiteValidator(),
      this.directoryConsentEmailValidator(),
      this.directoryConsentPhoneValidator(),
    ]
  });

  constructor(public sharedService: SharedService,
              public licenseService: ManufacturerService,
              private router: Router,
              private dialog: MatDialog,
              public paymentService: PaymentService,
              public toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadingManufacturer = true;
    this.getDocumentTypes();
    this.licenseService.getLicenses().subscribe(
      response => this.licenses = response,
      error => console.log('error', error),
      () => {
        this.setupLoadingIndex();
        if (this.sharedService.userProfile.userId === null) {
          this.sharedService.getUserInfo().subscribe(
            response => this.sharedService.userProfile = response,
            error => console.log('error', error),
            () => {
              this.loadingManufacturer = false;
              this.getLicense();
              this.sharedService.toggleTheme();
              this.updateUserState();
            }
          );
        }
        else {
          this.loadingManufacturer = false;
          this.getLicense();
          this.updateUserState();
        }
      }
    );
  }

  getDocumentTypes(): void {
    this.licenseService.getDocumentTypes().subscribe(
      response => this.documentTypes = response,
      error => console.log('error', error),
      () => {
        this.lucsType = this.documentTypes.find(dt => dt.type === this.lucs).id
        this.premisesType = this.documentTypes.find(dt => dt.type === this.premisesSketch).id
        this.ownerConsentType = this.documentTypes.find(dt => dt.type === this.ownerConsent).id
        this.socialEquityType = this.documentTypes.find(dt => dt.type === this.socialEquityPlan).id;
        this.authorizedIndividualType = this.documentTypes.find(dt => dt.type === this.authorizedIndividual).id;
        this.reducedFeeType = this.documentTypes.find(dt => dt.type === this.reducedFee).id;
        this.premisePictureType = this.documentTypes.find(dt => dt.type === this.premisePicture).id;
        this.updatedEquityPlanType = this.documentTypes.find(dt => dt.type === this.updatedEquityPlan).id;
        this.equityPlanEvaluationType = this.documentTypes.find(dt => dt.type === this.equityPlanEvaluation).id;
        this.updatedPremisePictureType = this.documentTypes.find(dt => dt.type === this.updatedPremisePicture).id;

        this.documentTypeReference[this.lucsType] = this.lucs;
        this.documentTypeReference[this.premisesType] = this.premisesSketch;
        this.documentTypeReference[this.ownerConsentType] = this.ownerConsent;
        this.documentTypeReference[this.socialEquityType] = this.socialEquityPlan;
        this.documentTypeReference[this.authorizedIndividualType] = this.authorizedIndividual;
        this.documentTypeReference[this.reducedFeeType] = this.reducedFee;
        this.documentTypeReference[this.premisePictureType] = this.premisePicture;
        this.documentTypeReference[this.updatedEquityPlanType] = this.updatedEquityPlan;
        this.documentTypeReference[this.equityPlanEvaluationType] = this.equityPlanEvaluation;
        this.documentTypeReference[this.updatedPremisePictureType] = this.updatedPremisePicture;

        this.missingTypes[this.lucsType] = false;
        this.missingTypes[this.premisesType] = false;
        this.missingTypes[this.ownerConsentType] = false;
        this.missingTypes[this.socialEquityType] = false;
        this.missingTypes[this.premisePictureType] = false;
      }
    );
  }

  setupLoadingIndex(): void {
    this.licenses.forEach(license => {
      this.loadingIndex[license.id] = false;
    });
  }

  getLicense(): void {
    if (this.licenses.length === 0) {
      this.hasApplication = false;
      this.viewForm = true;
    }
    else {
      this.hasApplication = true;
      if(this.licenses.length === 1){
        let currentLicense = this.licenses.find(fl => fl.status === this.sharedService.pending);
        if (currentLicense) {
        this.viewApplication(currentLicense);
        this.viewForm = true;
        }
      }
    }
  }

  viewApplication(license: IManufacturerLicense): void {
    this.loadingIndex[license.id] = true;
    this.license = license;
    this.licenseLocked = this.license.status !== this.sharedService.pending;
    if(this.licenseLocked){
      this.step = 0;
      this.licenseForm.disable();
      this.documentsForm.disable();
      this.verifyForm.disable();
    }
    else{
      this.licenseForm.enable();
      this.documentsForm.enable();
      this.verifyForm.enable();
      this.setupRouteSubscription();
    }
    this.updateLicenseForm();
    setTimeout(() => {
      this.loadingIndex[license.id] = false;
      this.viewForm = true;
      if(this.step === 7){
        this.verifyForm.markAsDirty();
        this.verifyForm.markAllAsTouched();
        this.licenseForm.markAsDirty();
        this.validateApplication();
      }
    }, 500);
  }

  setupRouteSubscription(): void {
    this.routeSubscription = this.router.events
    .pipe(filter((event) => event instanceof NavigationStart))
    .subscribe((e) => {
      this.updateLicense();
    });
  }

  closeApplication(): void {
    if(this.license.status == 'Pending' || this.license.status == this.sharedService.pendingRenewal){
      this.sharedService.openConfirm("Some of your changes may be lost. Continue?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if (confirmed) {
            this.license = {
              id: 0,
              userId: '',
              licenseId: '',
              status: '',
              entityName: '',
              entityWebsite: '',
              registeredBusiness: null,
              businessInOregon: null,
              secretaryofStateNumber: '',
              registrationAttestation: false,
              registryNumber: '',
              registeredIn: '',
              financialAttestation: false,
              applicantAttestation: false,
              directoryConsent: null,
              directoryConsentWebsite: false,
              directoryConsentEmail: false,
              directoryConsentPhone: false,
              directoryPhone: '',
              directoryEmail: '',
              directoryWebsite: '',
              physicalState: 'OR',
              physicalCity: '',
              physicalStreet: '',
              physicalZip: '',
              physicalCounty: '',
              premisesDescription: '',
              mailingIsPhysical: false,
              mailingState: '',
              mailingCity: '',
              mailingStreet: '',
              mailingZip: '',
              mailingCounty: '',
              primaryContactName: '',
              primaryContactNumber: '',
              primaryContactEmail: '',
              primaryContactId: '',
              language: '',
              previousLicense: false,
              affiliatedLicenses: '',
              fungiCultivation: false,
              psilocybinExtraction: false,
              ediblePsilocybinProduction: false,
              edibleAttestation: false,
              denyEndorsementAttestation: false,
              shareTransferPrices: false,
              canRenew: false,
              canOperate: false,
              reducedRequest: false,
              electronicSignature: '',
              attestation: false,
              applicationDate: '',
              approvedDate: '',
              renewedDate: '',
              expirationDate: '',
              entityApplicants: [],
              workerPermits: [],
              applicants: [],
              licenseDocuments: [],
              payments: [],
              timely: false,
              financialInterestCorrect: null,
              financialInterestCorrectReason: '',
              manufacturerInformationCorrect: null,
              manufacturerInformationCorrectReason: '',
              endorsementsCorrect: null,
              endorsementsCorrectReason: '',
              isRenewal: false,
              applicantsCorrect: null,
              applicantsCorrectReason: ''
            }
            if (!this.licenseLocked) {
              this.routeSubscription.unsubscribe();
            }
            this.viewForm = false;
            this.renewing = false;
          }
        });
    }
    else {
      this.license = {
        id: 0,
        userId: '',
        licenseId: '',
        status: '',
        entityName: '',
        entityWebsite: '',
        registeredBusiness: null,
        businessInOregon: null,
        secretaryofStateNumber: '',
        registrationAttestation: false,
        registryNumber: '',
        registeredIn: '',
        financialAttestation: false,
        applicantAttestation: false,
        directoryConsent: null,
        directoryConsentWebsite: false,
        directoryConsentEmail: false,
        directoryConsentPhone: false,
        directoryPhone: '',
        directoryEmail: '',
        directoryWebsite: '',
        physicalState: 'OR',
        physicalCity: '',
        physicalStreet: '',
        physicalZip: '',
        physicalCounty: '',
        premisesDescription: '',
        mailingIsPhysical: false,
        mailingState: '',
        mailingCity: '',
        mailingStreet: '',
        mailingZip: '',
        mailingCounty: '',
        primaryContactName: '',
        primaryContactNumber: '',
        primaryContactEmail: '',
        primaryContactId: '',
        language: '',
        previousLicense: false,
        affiliatedLicenses: '',
        fungiCultivation: false,
        psilocybinExtraction: false,
        ediblePsilocybinProduction: false,
        edibleAttestation: false,
        denyEndorsementAttestation: false,
        shareTransferPrices: false,
        canRenew: false,
        canOperate: false,
        reducedRequest: false,
        electronicSignature: '',
        attestation: false,
        applicationDate: '',
        approvedDate: '',
        renewedDate: '',
        expirationDate: '',
        entityApplicants: [],
        applicants: [],
        workerPermits: [],
        licenseDocuments: [],
        payments: [],
        timely: false,
        financialInterestCorrect: null,
        financialInterestCorrectReason: '',
        manufacturerInformationCorrect: null,
        manufacturerInformationCorrectReason: '',
        endorsementsCorrect: null,
        endorsementsCorrectReason: '',
        isRenewal: false,
        applicantsCorrect: null,
        applicantsCorrectReason: ''
      }
      if (!this.licenseLocked) {
        this.routeSubscription.unsubscribe();
      }
      this.viewForm = false;
      this.renewing = false;
    }
  }

  addWorkerPermit(license: IManufacturerLicense): void {
    const dialogRef = this.dialog.open(DialogAddPermitComponent, {
      data: license.workerPermits,
      role: 'dialog',
      ariaLabel: 'Add Worker Permits',
      minWidth: !this.sharedService.mobile ? '600px' : '400px',
      maxWidth: !this.sharedService.mobile ? '800px' : '600px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if(!this.sharedService.isCancelled(response)){
        this.licenseService.addWorkerPermit(license.id, response).subscribe({
          next: response => license.workerPermits = [...license.workerPermits, ...response],
          error: e => console.log('error', e)
        });
      }
    });
  }

  removeWorkerPermit(license: IManufacturerLicense, permitId: string): void {
    this.sharedService.openConfirm("Remove Worker Permit: " + permitId + "?");
    this.sharedService.confirmed().subscribe((confirmed) => {
      if(confirmed){
        this.licenseService.removeWorkerPermit(license.id, permitId).subscribe({
          complete: () => {
            license.workerPermits = license.workerPermits.filter(wp => wp.licenseId !== permitId);
          },
          error: e => console.log('error', e)
        });
      }
    });
  }

  startApplication(): void {
    this.showStartButton = false;
    this.sharedService.openConfirm("Would you like to begin a manufacturer license application?");
    this.sharedService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.licenseService.createLicense().subscribe(
          response => this.license = response,
          error => console.log('error', error),
          () => {
            this.setupRouteSubscription();
            this.loadingIndex[this.license.id] = false;
            this.hasApplication = true;
            this.licenses.push(this.license);
            this.updateLicenseForm();
          }
        );
      }
      else{
        this.hasApplication = false;
        this.showStartButton = true;
      }
    });
  }

  requestWithdraw(license: IManufacturerLicense): void{
    this.sharedService.openConfirm("Are you sure you want to request to withdraw your application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.requestWithdraw(license.id).subscribe(
            response => {
              let index = this.licenses.findIndex(fl => fl.id == license.id);
              this.licenses[index] = response;
            },
            error => console.log('error', error),
          )
    }});
  }

  requestSurrender(license: IManufacturerLicense): void{
    this.sharedService.openConfirm("Are you sure you want to request to surrender your application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.requestSurrender(license.id).subscribe(
            response => {
              let index = this.licenses.findIndex(fl => fl.id == license.id);
              this.licenses[index] = response;
            },
            error => console.log('error', error),
          )
    }});
  }

  updateLicenseForm(): void {
    if(this.license.businessInOregon)
      this.licenseForm.patchValue({
        businessInOregon: this.license.businessInOregon,
      });

    if(!this.license.businessInOregon)
      this.licenseForm.patchValue({
        businessInOregon: this.license.businessInOregon,
      });

    if(this.license.directoryWebsite == '' && this.license.entityWebsite != '')
      this.verifyForm.patchValue({
        directoryWebsite: this.license.entityWebsite,
      });
    else
      this.verifyForm.patchValue({
        directoryWebsite: this.license.directoryWebsite,
      });

    this.licenseForm.patchValue({
      entityName: this.license.entityName,
      entityWebsite: this.license.entityWebsite,
      secretaryofStateNumber: this.license.secretaryofStateNumber,
      registeredBusiness: this.license.registeredBusiness,
      registeredIn: this.license.registeredIn,
      registryNumber: this.license.registryNumber,
      registrationAttestation: this.license.registrationAttestation,
      financialAttestation: this.license.financialAttestation,
      applicantFeeAttestation: this.license.applicantAttestation,
      applicantDenialAttestation: this.license.applicantAttestation,
      physicalCity: this.license.physicalCity,
      physicalStreet: this.license.physicalStreet,
      physicalZip: this.license.physicalZip,
      physicalCounty: this.license.physicalCounty,
      premisesDescription: this.license.premisesDescription,
      mailingIsPhysical: this.license.mailingIsPhysical,
      mailingState: this.license.mailingState,
      mailingCity: this.license.mailingCity,
      mailingStreet: this.license.mailingStreet,
      mailingZip: this.license.mailingZip,
      mailingCounty: this.license.mailingCounty,
      primaryContactName: this.license.primaryContactName,
      primaryContactNumber: this.license.primaryContactNumber,
      primaryContactEmail: this.license.primaryContactEmail,
      primaryContactId: this.license.primaryContactId,
      language: this.license.language,
      previousLicense: this.license.previousLicense,
      affiliatedLicenses: this.license.affiliatedLicenses,
      fungiCultivation: this.license.fungiCultivation,
      psilocybinExtraction: this.license.psilocybinExtraction,
      ediblePsilocybinProduction: this.license.ediblePsilocybinProduction,
      firstEdibleAttestation: this.license.edibleAttestation,
      secondEdibleAttestation: this.license.edibleAttestation,
      denyEndorsementAttestation: this.license.denyEndorsementAttestation,
      applicantsCorrect: this.license.applicantsCorrect,
      applicantsCorrectReason: this.license.applicantsCorrectReason,
      financialInterestCorrect: this.license.financialInterestCorrect,
      financialInterestCorrectReason: this.license.financialInterestCorrectReason,
      manufacturerInformationCorrect: this.license.manufacturerInformationCorrect,
      manufacturerInformationCorrectReason: this.license.manufacturerInformationCorrectReason,
      endorsementsCorrect: this.license.endorsementsCorrect,
      endorsementsCorrectReason: this.license.endorsementsCorrectReason
    });

    this.verifyForm.patchValue({
      electronicSignature: this.license.electronicSignature,
      attestation: this.license.attestation,
      directoryConsent: this.license.directoryConsent,
      directoryConsentWebsite: this.license.directoryConsentWebsite,
      directoryConsentEmail: this.license.directoryConsentEmail,
      directoryConsentPhone: this.license.directoryConsentPhone,
      directoryPhone: this.license.directoryPhone,
      directoryEmail: this.license.directoryEmail,
    });
  }

  updateLicense(): void {
    if(this.license.directoryWebsite == '' && this.license.entityWebsite != '')
      this.verifyForm.patchValue({
        directoryWebsite: this.license.entityWebsite,
      });
    else
      this.verifyForm.patchValue({
        directoryWebsite: this.license.directoryWebsite,
      });
    let form = this.licenseForm.value;
    if(!this.renewing)
    {
      this.license.entityName = form.entityName;
      this.license.secretaryofStateNumber = form.secretaryofStateNumber;
      this.license.registeredIn = form.registeredIn;
      this.license.registryNumber = form.registryNumber;
      this.license.premisesDescription = form.premisesDescription;
      this.license.physicalState = "OR";
      this.license.physicalCity = form.physicalCity;
      this.license.physicalStreet = form.physicalStreet;
      this.license.physicalZip = form.physicalZip;
      this.license.physicalCounty = form.physicalCounty;
      this.license.fungiCultivation = form.fungiCultivation;
      this.license.psilocybinExtraction = form.psilocybinExtraction;
      this.license.ediblePsilocybinProduction = form.ediblePsilocybinProduction;
    }

    this.license.entityWebsite = form.entityWebsite;
    this.license.businessInOregon = form.businessInOregon;
    this.license.registrationAttestation = form.registrationAttestation;
    this.license.registeredBusiness = form.registeredBusiness
    this.license.financialAttestation = form.financialAttestation;
    this.license.applicantAttestation = form.applicantDenialAttestation && form.applicantFeeAttestation;
    this.license.mailingIsPhysical = form.mailingIsPhysical;
    if(form.mailingIsPhysical){
      this.license.mailingState = this.license.physicalState;
      this.license.mailingCity = form.physicalCity;
      this.license.mailingStreet = form.physicalStreet;
      this.license.mailingZip = form.physicalZip;
      this.license.mailingCounty = form.physicalCounty;
    }
    else{
      this.license.mailingState = form.mailingState;
      this.license.mailingCity = form.mailingCity;
      this.license.mailingStreet = form.mailingStreet;
      this.license.mailingZip = form.mailingZip;
      this.license.mailingCounty = form.mailingCounty;
    }
    this.license.primaryContactName = form.primaryContactName;
    this.license.primaryContactNumber = form.primaryContactNumber;
    this.license.primaryContactEmail = form.primaryContactEmail;
    this.license.primaryContactId = form.primaryContactId;
    this.license.language = form.language;
    this.license.previousLicense = form.previousLicense;
    this.license.affiliatedLicenses = form.affiliatedLicenses;
    this.license.edibleAttestation = form.firstEdibleAttestation && form.secondEdibleAttestation;
    this.license.denyEndorsementAttestation = form.denyEndorsementAttestation;
    this.license.applicantsCorrect = form.applicantsCorrect;
    this.license.applicantsCorrectReason = form.applicantsCorrectReason;
    this.license.financialInterestCorrect = form.financialInterestCorrect,
    this.license.financialInterestCorrectReason = form.financialInterestCorrectReason,
    this.license.manufacturerInformationCorrect = form.manufacturerInformationCorrect,
    this.license.manufacturerInformationCorrectReason = form.manufacturerInformationCorrectReason,
    this.license.endorsementsCorrect = form.endorsementsCorrect,
    this.license.endorsementsCorrectReason = form.endorsementsCorrectReason,
    this.licenseService.updateLicense(this.license);
  }

  public get canApplyAgain(): boolean {
    let canApply: boolean = true;
    this.licenses.forEach(license => {
      if(!this.canApplyStatuses.includes(license.status)){
        canApply = false;
      }
    });
    return canApply;
  }

  updateUserState(): void {
    if (this.licenseSteps.includes(this.sharedService.userProfile.currentStep)) {
      this.step = this.stepsIndex[this.sharedService.userProfile.currentStep];
    }
    if (this.sharedService.userProfile.currentPage !== "license/manufacturer") {
      this.sharedService.userProfile.currentPage = "license/manufacturer";
      this.sharedService.updateUserProfileState();
    }
  }

  changeStep(step: StepperSelectionEvent): void {
    if(!this.licenseLocked){
      if (step.previouslySelectedIndex === 1) {
        this.updateLicense();
        this.validateManufacturerInformationCorrect();
        this.validateLicenseDocuments();
        this.licenseForm.markAsDirty();
        this.licenseForm.markAllAsTouched();
      }
      if(step.previouslySelectedIndex === 2){
        this.validateApplicants();
        this.validateEntityApplicants();
        this.validatePrimaryContact();
        this.updateLicense();
        if(!this.renewing)
          this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
                                          this.licenseForm.get('applicantDenialAttestation').value;
        else
          this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
                                           this.licenseForm.get('applicantDenialAttestation').value && (this.licenseForm.get('applicantsCorrect').value == true ||
                                           (this.licenseForm.get('applicantsCorrect').value == false && (this.licenseForm.get('applicantsCorrectReason').value !== '' && this.licenseForm.get('applicantsCorrectReason').value !== null)));
      }
      if (step.previouslySelectedIndex === 3) {
        this.updateLicense();
        if(!this.renewing)
          this.financialAttestationValid = this.licenseForm.get('financialAttestation').value;
        if(this.renewing)
          this.financialAttestationValid = this.licenseForm.get('financialAttestation').value && (this.licenseForm.get('financialInterestCorrect').value || ((this.licenseForm.get('financialInterestCorrectReason').value !== '' && this.licenseForm.get('financialInterestCorrectReason').value !== null) && this.licenseForm.get('financialInterestCorrect').value == false));
      }

      if(this.renewing && step.selectedIndex === 4)
        this.validateEndorsements();

      if (step.previouslySelectedIndex === 4) {
        this.updateLicense();
        this.validateEndorsements();
      }
      if (step.previouslySelectedIndex === 5) {
        this.validateLicenseDocuments();
      }
      if (step.selectedIndex === 7){
        if(!this.renewing)
          this.financialAttestationValid = this.licenseForm.get('financialAttestation').value;
        if(this.renewing)
          this.financialAttestationValid = this.licenseForm.get('financialAttestation').value && (this.licenseForm.get('financialInterestCorrect').value || ((this.licenseForm.get('financialInterestCorrectReason').value !== '' && this.licenseForm.get('financialInterestCorrectReason').value !== null) && this.licenseForm.get('financialInterestCorrect').value == false));

        if(!this.renewing)
          this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
                                          this.licenseForm.get('applicantDenialAttestation').value;
        else
          this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
            this.licenseForm.get('applicantDenialAttestation').value && (this.licenseForm.get('applicantsCorrect').value == true ||
            (this.licenseForm.get('applicantsCorrect').value == false && (this.licenseForm.get('applicantsCorrectReason').value !== '' && this.licenseForm.get('applicantsCorrectReason').value !== null)));
        this.licenseForm.markAsDirty();
        this.licenseForm.markAllAsTouched();
        this.validateApplication();
      }
    }
    this.sharedService.userProfile.currentStep = this.licenseSteps[step.selectedIndex];
    this.sharedService.updateUserProfileState();
  }

  setPointOfContact(applicant: IApplicant): void {
    this.licenseForm.patchValue({
      primaryContactName: applicant.legalFirstName + ' ' + applicant.legalLastName,
      primaryContactNumber: applicant.phone,
      primaryContactEmail: applicant.email,
      primaryContactId: applicant.applicantId,
      language: applicant.language,
    });
    this.updateLicense();
    this.validatePrimaryContact();
  }

  editApplicant(editing: boolean): void {
    this.editingApplicant = editing;
  }

  editEntityApplicant(editing: boolean): void {
    this.editingEntityApplicant = editing;
  }

  hasPaidFee(license: IManufacturerLicense, paymentType: string): boolean {
    if(license == null || license.payments == null || license.payments.length == 0) {
      return false;
    }

    let appFee = license.payments.find(tp => tp.paid && tp.type == paymentType);
    if(paymentType == 'LicenseFee')
      {
         appFee = license.payments.find(tp => tp.paid && (tp.type == 'LicenseFee' || tp.type == 'ReducedLicenseFee'));
      }
    if(appFee == null || appFee == undefined) {
      return false;
    }

    return true;
  }

  getPaymentFeeStatus(license: IManufacturerLicense, paymentType: string) : string {
    if(license == null || license.payments == null || license.payments.length == 0) {
      return "";
    }

    let appFee = license.payments.find(tp => tp.type == paymentType);

    if(appFee == null || appFee == undefined) {
      return "";
    }

    return appFee.status;
  }

  getFeeCleared(license: IManufacturerLicense, paymentType: string) : string {
    if(license == null || license.payments == null || license.payments.length == 0) {
      return "";
    }

    let appFee = license.payments.find(tp => tp.type == paymentType);

    if(appFee == null || appFee == undefined) {
      return "";
    }

    return appFee.paymentCleared;
  }

  //Custom Validators
  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.licenseForm.hasError('licensesRequired') && this.licenseForm.get('affiliatedLicenses').touched) {
      return true;
    }
    return false;
  }

  businessInOregonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;

      if(isOregonBusiness != null){
        return null;
      }
      if(this.renewing)
        return null;
      return { businessInOregonRequired: true };
    }
  }

  validateBusinessInOregon(): boolean {
    if (this.licenseForm.hasError('businessInOregonRequired') && this.licenseForm.get('businessInOregon').touched) {
      return true;
    }
    return false;
  }

  businessInOtherStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;
      const otherState = control.value.registeredBusiness;
      if(isOregonBusiness){
        return null;
      }
      if(this.renewing)
        return null;
      return (otherState != null) ? null : { otherBusinessRequired: true };
    }
  }

  validateBusinessInOtherState(): boolean {
    if (this.licenseForm.hasError('otherBusinessRequired') && this.licenseForm.get('registeredBusiness').touched) {
      return true;
    }
    return false;
  }

  secretaryOfStateNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;
      const secretaryofStateNumber = control.value.secretaryofStateNumber;
      if(!isOregonBusiness){
        return null;
      }
      if(this.renewing)
        return null;
      return (secretaryofStateNumber != null && secretaryofStateNumber != '' && secretaryofStateNumber != undefined) ? null : { secretaryofStateNumberRequired: true };
    }
  }

  validateSecretaryOfStateNumber(): boolean {
    if (this.licenseForm.hasError('secretaryofStateNumberRequired') && this.licenseForm.get('secretaryofStateNumber').touched) {
      return true;
    }
    return false;
  }

  registeredLocationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOtherBusiness = control.value.registeredBusiness;
      const registeredLocation = control.value.registeredIn;
      const isOregonBusiness = control.value.businessInOregon;
      if(!isOtherBusiness || isOregonBusiness ){
        return null;
      }
      if(this.renewing)
        return null;
      return (registeredLocation !== null && registeredLocation !== '' && registeredLocation !== undefined) ? null : { registeredLocationRequired: true };
    }
  }

  validateRegisteredLocation(): boolean {
    if (this.licenseForm.hasError('registeredLocationRequired') && this.licenseForm.get('registeredIn').touched) {
      return true;
    }
    return false;
  }

  registryNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOtherBusiness = control.value.registeredBusiness;
      const registryNumber = control.value.registryNumber;
      const isOregonBusiness = control.value.businessInOregon;
      if(!isOtherBusiness || isOregonBusiness){
        return null;
      }
      if(this.renewing)
        return null;
      return (registryNumber !== null && registryNumber !== '' && registryNumber !== undefined) ? null : { registryNumberRequired: true };
    }
  }

  validateRegistryNumber(): boolean {
    if (this.licenseForm.hasError('registryNumberRequired') && this.licenseForm.get('registryNumber').touched) {
      return true;
    }
    return false;
  }

  manufacturerInformationCorrectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pageCorrect = control.value.manufacturerInformationCorrect;
      const reason = control.value.manufacturerInformationCorrectReason;
      if (!pageCorrect && this.license.isRenewal) {
        return (reason !== null && reason !== '' && reason !== undefined) ? null : { manufacturerInformationCorrectReasonRequired: true };
      }
      return null;
    };
  }

  validateManufacturerInformationCorrect(): boolean {
    if (this.licenseForm.hasError('manufacturerInformationCorrectReasonRequired') && this.licenseForm.get('manufacturerInformationCorrect').value === false) {
      this.licenseForm.get('manufacturerInformationCorrectReason').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('manufacturerInformationCorrectReason').clearValidators();
    this.licenseForm.get('manufacturerInformationCorrectReason').updateValueAndValidity();
    return false;
  }

  validateFinancialInterestCorrect(): boolean {
    return (this.licenseForm.get('financialInterestCorrect').value || ((this.licenseForm.get('financialInterestCorrectReason').value !== '' && this.licenseForm.get('financialInterestCorrectReason').value !== null) && this.licenseForm.get('financialInterestCorrect').value == false));
  }

  validateEndorsementsCorrect(): boolean {
    return this.licenseForm.get('endorsementsCorrect').value || (this.licenseForm.get('endorsementsCorrect').value == false && (this.licenseForm.get('endorsementsCorrectReason').value !== '' && this.licenseForm.get('endorsementsCorrectReason').value !== null));

  }

  mailingIsSame(): void {
    this.licenseForm.get('mailingStreet').updateValueAndValidity();
    this.licenseForm.get('mailingCity').updateValueAndValidity();
    this.licenseForm.get('mailingState').updateValueAndValidity();
    this.licenseForm.get('mailingZip').updateValueAndValidity();
  }

  mailingStreetValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean {
    if (this.licenseForm.hasError('mailingStreetRequired') && this.licenseForm.get('mailingStreet').touched) {
      this.licenseForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingStreet').clearValidators();
    this.licenseForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean {
    if (this.licenseForm.hasError('mailingCityRequired') && this.licenseForm.get('mailingCity').touched) {
      this.licenseForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingCity').clearValidators();
    this.licenseForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean {
    if (this.licenseForm.hasError('mailingStateRequired') && this.licenseForm.get('mailingState').touched) {
      this.licenseForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingState').clearValidators();
    this.licenseForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean {
    if (this.licenseForm.hasError('mailingZipRequired') && this.licenseForm.get('mailingZip').touched) {
      this.licenseForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingZip').clearValidators();
    this.licenseForm.get('mailingZip').updateValueAndValidity();
    return false;
  }

  directoryConsentWebsiteValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const websiteConsent = control.value.directoryConsentWebsite;
      const website = control.value.directoryWebsite;
      const directoryConsent = control.value.directoryConsent;
      if (websiteConsent && directoryConsent) {
        return (website !== null && website !== '' && website !== undefined) ? null : { directoryWebsiteRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentWebsite(): boolean {
    const websiteControl = this.verifyForm.get('directoryWebsite');
    const websiteConsent = this.verifyForm.get('directoryConsentWebsite').value;
    const directoryConsent = this.verifyForm.get('directoryConsent').value;

    if(websiteConsent && directoryConsent)
    {
      if (websiteControl.value === '' || websiteControl.value === null || websiteControl.value === undefined) {
        websiteControl.setErrors({ required: true });
        return true;
      }

      const pattern = /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]/i;

      if (!pattern.test(websiteControl.value)) {
        websiteControl.setErrors({ pattern: true });
        return true;
      }
    }
    websiteControl.clearValidators();
    websiteControl.updateValueAndValidity();
    return false;
  }

  directoryConsentEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailConsent = control.value.directoryConsentEmail;
      const email = control.value.directoryEmail;
      const directoryConsent = control.value.directoryConsent;
      if (emailConsent && directoryConsent) {
        return (email !== null && email !== '' && email !== undefined) ? null : { directoryEmailRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentEmail(): boolean {
    if (this.verifyForm.hasError('directoryEmailRequired') && this.verifyForm.get('directoryEmail').value === true) {
      this.verifyForm.get('directoryEmail').setErrors(['required']);
      return true;
    }
    this.verifyForm.get('directoryEmail').clearValidators();
    this.verifyForm.get('directoryEmail').updateValueAndValidity();
    return false;
  }


  directoryConsentPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phoneConsent = control.value.directoryConsentPhone;
      const phone = control.value.directoryPhone;
      const directoryConsent = control.value.directoryConsent;
      if (phoneConsent && directoryConsent) {
        return (phone !== null && phone !== '' && phone !== undefined) ? null : { directoryPhoneRequired: true };
      }
      return null;
    };
  }

  validateDirectoryConsentPhone(): boolean {
    if (this.verifyForm.hasError('directoryPhoneRequired') && this.verifyForm.get('directoryPhone').value === true) {
      this.verifyForm.get('directoryPhone').setErrors(['required']);
      return true;
    }
    this.verifyForm.get('directoryPhone').clearValidators();
    this.verifyForm.get('directoryPhone').updateValueAndValidity();
    return false;
  }
  //End of Validators

  uploadLicenseDocument(event: Event): void {
    let documentForm = this.documentsForm.value;
    let types: number[] = [];

    if (documentForm.lucs) {
      types.push(this.lucsType);
    }
    if (documentForm.premisesSketch){
      types.push(this.premisesType);
    }
    if (documentForm.ownerConsent){
      types.push(this.ownerConsentType);
    }
    if (documentForm.socialEquityPlan) {
      types.push(this.socialEquityType);
    }
    if (documentForm.reducedFee) {
      types.push(this.reducedFeeType);
    }
    if(documentForm.premisePicture) {
      types.push(this.premisePictureType);
    }
    if (documentForm.updatedEquityPlan) {
      types.push(this.updatedEquityPlanType);
    }
    if (documentForm.equityPlanEvaluation) {
      types.push(this.equityPlanEvaluationType);
    }
    if (documentForm.updatedPremisePicture) {
      types.push(this.updatedPremisePictureType);
    }

    types.forEach(type => {
      this.missingTypes[type] = false;
    });

    if(types.length > 0){
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
      if(this.sharedService.validateFile(file))
      {
        let upload: IDocument = {
          id: 0,
          name: file.name,
          comments: "",
          extenstion: "",
          dateCreated: "",
          dateLastUpdated: "",
          createdBy: "",
          lastUpdatedBy: "",
          parentId: this.license.id,
          types: types,
          adminOnly: false,
          deprecated: false
        }

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("document", JSON.stringify(upload));
        this.licenseService.uploadDocument(formData).subscribe(
          response => this.license.licenseDocuments.push(response),
          error => {
            (event.target as HTMLInputElement).value = '';
            console.log('error', error);
          },
          () => {
            (event.target as HTMLInputElement).value = '';
            this.validateLicenseDocuments();
            this.documentsForm.patchValue({
              lucs: false,
              premisesSketch: false,
              ownerConsent: false,
              trainingCertificate: false,
              socialEquityPlan: false,
              reducedFee: false,
              changeOfLocationRequest: false,
              premisePicture: false,
              updatedEquityPlan: false,
              equityPlanEvaluation: false,
              updatedPremisePicture: false
            });
          });
      }
      else
        this.toastr.error("Unsupported File Type");
    }
    else{
      (event.target as HTMLInputElement).value = '';
      this.toastr.error("Please select at least one requirement met by the document");
    }
  }

  downloadDocument(fileId: number, fileName: string): void {
    this.licenseService.downloadFile(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.deleteDocument(id).subscribe(
            () => {
              this.license.licenseDocuments = this.license.licenseDocuments.filter(item => item.id !== id);
              this.validateLicenseDocuments();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  validateLicenseDocuments(): void {
    this.documentsValid = true;
    let requiredTypes: number[];
    if(!this.renewing)
      requiredTypes = [
          this.lucsType,
          this.premisesType,
          this.socialEquityType,
          this.premisePictureType,
        ];
    if(this.renewing)
      requiredTypes = [
        this.equityPlanEvaluationType
      ];

    let types: number[] = [];
    this.license.licenseDocuments.forEach(ld => ld.types.forEach(t => types.push(t)));

    requiredTypes.forEach(type => {
      if(!types.includes(type)){
        this.documentsValid = false;
        this.missingTypes[type] = true;
      }
    });
  }

  validateEndorsements(): void {
    if(!this.renewing)
    {
     this.endorsementsValid = (this.licenseForm.get('fungiCultivation').value ||
                                this.licenseForm.get('psilocybinExtraction').value ||
                                this.licenseForm.get('ediblePsilocybinProduction').value) &&
                                this.licenseForm.get('denyEndorsementAttestation').value;

      if(this.licenseForm.get('ediblePsilocybinProduction').value)
        this.endorsementsValid = this.licenseForm.get('firstEdibleAttestation').value && this.licenseForm.get('secondEdibleAttestation').value;

    }

    if(this.renewing){
      this.endorsementsValid = (this.licenseForm.get('fungiCultivation').value ||
                                this.licenseForm.get('psilocybinExtraction').value ||
                                this.licenseForm.get('ediblePsilocybinProduction').value) &&
                                this.licenseForm.get('denyEndorsementAttestation').value &&
                                (this.licenseForm.get('endorsementsCorrect').value || (this.licenseForm.get('endorsementsCorrect').value == false && (this.licenseForm.get('endorsementsCorrectReason').value !== '' && this.licenseForm.get('endorsementsCorrectReason').value !== null)));

      if(this.licenseForm.get('ediblePsilocybinProduction').value)
        this.endorsementsValid = this.licenseForm.get('firstEdibleAttestation').value && this.licenseForm.get('secondEdibleAttestation').value && (this.licenseForm.get('endorsementsCorrect').value || (this.licenseForm.get('endorsementsCorrect').value == false && (this.licenseForm.get('endorsementsCorrectReason').value !== '' && this.licenseForm.get('endorsementsCorrectReason').value !== null)));

    }



  }

  endorsementSelected(): boolean {
    return (this.licenseForm.get('fungiCultivation').value ||
    this.licenseForm.get('psilocybinExtraction').value ||
    this.licenseForm.get('ediblePsilocybinProduction').value)
  }

  validatePrimaryContact(): void {
    if (this.licenseForm.get('primaryContactName').value !== '' &&
      this.licenseForm.get('primaryContactNumber').value !== '' &&
      this.licenseForm.get('primaryContactEmail').value !== '') {
      this.contactValid = true;
    }
    else {
      this.contactValid = false;
    }
  }

  validateApplicants(): void{
    if(this.individualApplicants?.applicants.length === 0){
      this.applicantsValid = false;
    }
    else{
      this.applicantsValid = true;
      this.individualApplicants?.applicants.forEach(applicant => {
        if(!applicant.complete){
          this.applicantsValid = false;
        }
      });
    }
  }

  validateEntityApplicants(): void{
    if(this.entityApplicants?.applicants.length === 0){
      this.entityApplicantsValid = true;
    }
    else{
      this.entityApplicantsValid = true;
      this.entityApplicants?.applicants.forEach(applicant => {
        if(!applicant.complete){
          this.entityApplicantsValid = false;
        }
      });
    }
  }

  validateApplication(): void{
    this.validateLicenseDocuments();
    this.validateEndorsements();
    this.validateApplicants();
    this.validateEntityApplicants();
    this.validatePrimaryContact();
  }

  applicationValid(): boolean {
    this.validateDirectoryConsentWebsite();
    if (this.renewing) {
      this.financialAttestationValid = this.licenseForm.get('financialAttestation').value && (this.licenseForm.get('financialInterestCorrect').value || (this.licenseForm.get('financialInterestCorrectReason').value !== '' && this.licenseForm.get('financialInterestCorrect').value == false));

      this.applicantAttestationValid = this.licenseForm.get('applicantFeeAttestation').value &&
        this.licenseForm.get('applicantDenialAttestation').value && (this.licenseForm.get('applicantsCorrect').value == true ||
        (this.licenseForm.get('applicantsCorrect').value == false && (this.licenseForm.get('applicantsCorrectReason').value !== '' && this.licenseForm.get('applicantsCorrectReason').value !== null)));
    }

    return (
      this.documentsValid &&
      this.applicantsValid &&
      this.entityApplicantsValid &&
      this.endorsementsValid &&
      this.contactValid &&
      this.financialAttestationValid &&
      this.applicantAttestationValid &&
      this.licenseForm.valid
    );
  }

  submitApplication(): void {
    this.license.attestation = true;
    this.license.electronicSignature = this.verifyForm.get('electronicSignature').value;
    this.license.directoryConsent = this.verifyForm.get('directoryConsent').value;
    this.license.directoryConsentWebsite = this.verifyForm.get('directoryConsentWebsite').value;
    this.license.directoryConsentEmail = this.verifyForm.get('directoryConsentEmail').value;
    this.license.directoryConsentPhone = this.verifyForm.get('directoryConsentPhone').value;
    this.license.directoryPhone = this.verifyForm.get('directoryPhone').value;
    this.license.directoryEmail = this.verifyForm.get('directoryEmail').value;
    this.license.directoryWebsite = this.verifyForm.get('directoryWebsite').value;

    this.licenseService.submitApplication(this.license, this.renewing).subscribe(
      response => this.license = response,
      error => console.log('error', error),
      () => {
        let licenseIndex = this.licenses.findIndex(fl => fl.id === this.license.id);
        this.licenses[licenseIndex] = this.license;
        this.routeSubscription.unsubscribe();
        this.showSubmitted = true;
      }
    );
  }

  reopenApplication(license: IManufacturerLicense): void {
    this.sharedService.openConfirm("Re-open this application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          license.status = this.sharedService.pending;
          this.licenseService.reopenApplication(license).subscribe(
            response => this.license = response,
            error => console.log('error', error),
            () => {
              let licenseIndex = this.licenses.findIndex(fl => fl.id === this.license.id);
              this.licenses[licenseIndex] = this.license;
              this.viewApplication(this.license);
            }
          );
        }
      }
    );
  }

  continue(): void {
    this.showSubmitted = false;
    this.renewing = false;
    this.getLicense();
    this.viewForm = false;
  }

  withdrawApplication(license: IManufacturerLicense): void {
    this.sharedService.openConfirm("Withdraw this application?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.withdrawApplication(license).subscribe(
            response => license = response,
            error => console.log('error', error),
            () => {
              let licenseIndex = this.licenses.findIndex(fl => fl.id === license.id);
              this.licenses[licenseIndex] = license;
              this.getLicense();
            }
          );
        }
      }
    );
  }

  viewPrintScreen(license: IManufacturerLicense): void {
    if (license[0] != null) {
      this.dialog.open(ManufacturerDetailsDialog, {
        data: license[0],
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
    else {
      this.dialog.open(ManufacturerDetailsDialog, {
        data: license,
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
  }

  changeDirectory(license: IManufacturerLicense): void {
    if (license.id > 0) {
      const dialogRef = this.dialog.open(DialogChangeDirectoryComponent, {
        data: {license: license, type: 'Manufacturer'},
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
      dialogRef.afterClosed().subscribe((response) => {
        if(response) {
        license.directoryConsent = response.directoryConsent;
        license.directoryConsentWebsite = response.directoryConsentWebsite;
        license.directoryConsentEmail = response.directoryConsentEmail;
        license.directoryConsentPhone = response.directoryConsentPhone;
        license.directoryPhone = response.directoryPhone;
        license.directoryEmail = response.directoryEmail;
        license.directoryWebsite= response.directoryWebsite;
        }

      });
    }
  }

  licenseHasUnpaidFees(license : IManufacturerLicense) : boolean{
    if(license != null && license.payments != null && license.payments.length > 0) {
       if(license.payments.filter(p => !p.paid).length > 0) {
         return true;
       }
    }
    return false;
  }

  hasRenewal(license: IManufacturerLicense): boolean {
    if(this.licenses.find(l => (l.isRenewal && l.licenseId == license.licenseId && (l.status != this.sharedService.approved && l.status != this.sharedService.resubmitted && l.status != this.sharedService.incomplete && l.status != this.sharedService.insufficient
      && l.status != this.sharedService.denied && l.status != this.sharedService.revoked && l.status != this.sharedService.expired && l.status != this.sharedService.withdrawn
      && l.status != this.sharedService.surrendered && l.status != this.sharedService.closed))) != null )
      return true;
    return false;
  }

  createRenewal(license: IManufacturerLicense): void {
    this.sharedService.openConfirm("Are you sure you want to start a renewal application?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.startedRenewal = true;
          this.loadingManufacturer = true;
          this.licenseService.createRenewalLicense(license.id).subscribe(
          response =>
          {
            this.licenses.push(response);
            this.loadingManufacturer = false;
            this.viewRenewal(response);
            this.step = 0;
          },
          error =>
          {
            console.log('error', error)
          });
    }});
  }

  viewRenewal(license: IManufacturerLicense): void
  {
    this.loadingIndex[license.id] = true;
    this.license = license;
    this.licenseLocked = this.license.status !== this.sharedService.pendingRenewal;
    if (this.licenseLocked) {
      this.step = 0;
      this.verifyForm.disable();
      this.documentsForm.disable();
      this.licenseForm.disable();
    }
    else{
      this.verifyForm.enable();
      this.documentsForm.enable();
      this.licenseForm.enable();
      this.verifyForm.markAllAsTouched();
      this.documentsForm.markAllAsTouched();
      this.licenseForm.markAllAsTouched();
      this.setupRouteSubscription();
    }
    this.updateLicenseForm();

    this.licenseForm.controls['manufacturerInformationCorrect'].setValidators([Validators.required]);
    this.licenseForm.controls['manufacturerInformationCorrect'].updateValueAndValidity();

    this.licenseForm.controls['entityName'].disable();
    this.licenseForm.controls['secretaryofStateNumber'].disable();
    this.licenseForm.controls['registeredIn'].disable();
    this.licenseForm.controls['registryNumber'].disable();
    this.licenseForm.controls['physicalStreet'].disable();
    this.licenseForm.controls['physicalCity'].disable();
    this.licenseForm.controls['physicalZip'].disable();
    this.licenseForm.controls['physicalCounty'].disable();
    this.licenseForm.controls['premisesDescription'].disable();
    this.licenseForm.controls['fungiCultivation'].disable();
    this.licenseForm.controls['psilocybinExtraction'].disable();
    this.licenseForm.controls['ediblePsilocybinProduction'].disable();

    this.renewing = true;
    this.viewForm = true;
    setTimeout(() => this.loadingIndex[license.id] = false, 500);
  }

  ngOnDestroy(): void {
    setTimeout(() => {this.routeSubscription.unsubscribe()}, 250);
  }
}

@Component({
  selector: "manufacturer-details-dialog",
  templateUrl: "./manufacturer-details.html",
  styleUrls: ["../../license-dashboard/license-dashboard.component.scss"]
})

export class ManufacturerDetailsDialog {
  public url = environment.baseUrl;
  public applicants: IApplicant[] = [];
  public entityApplicants: IEntityApplicant[] = [];

  constructor(public dialogRef: MatDialogRef<TestingLabDetailsDialog>,
    public sharedService: SharedService,
    public paymentService: PaymentService,
    public applicantService: ApplicantService,
    @Inject(MAT_DIALOG_DATA) public license: IManufacturerLicense) { }

    ngOnInit() {
      this.applicantService.getApplicants(this.sharedService.manufacturer, this.license.id).subscribe(
        response => this.applicants = response,
        error => console.log('error', error),
      );
      this.applicantService.getEntityApplicants(this.sharedService.manufacturer, this.license.id).subscribe(
        response => this.entityApplicants = response,
        error => console.log('error', error),
      );
    }

    print() {
      window.print();
    }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
