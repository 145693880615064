import { environment } from "../../environments/environment";
/**
 * This file contains authentication parameters. Contents of this file
 * are roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

// #region 1) B2C policies and user flows
/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: environment.b2c.signUpSignInPolicy
  },
  authorities: {
    signUpSignIn: {
      authority:
        environment.b2c.authority +
        environment.b2c.domain +
        environment.b2c.signUpSignInPolicy,
    }
  },
  authorityDomain: environment.b2c.authorityDomain
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const protectedResources: { endpoint: string; scopes: string[] } = {
  endpoint: environment.privateApi,
  scopes: environment.b2c.scopes,
};
