import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IUserProfile, UserLicense } from 'app/models/user/user-profile';

@Injectable({providedIn: 'root'})
export class UserService {
  private api: string = environment.privateApi + 'UserProfile/';
  constructor(private httpClient: HttpClient) { }

  getUserInfo(): Observable<IUserProfile>{
    return this.httpClient.get<IUserProfile>(this.api + "GetUserProfile");
  }

  udpateUserInfo(user: IUserProfile): Observable<IUserProfile>{
    return this.httpClient.post<IUserProfile>(this.api + "UpdateUserProfile", user);
  }

  getUserLicenses() : Observable<UserLicense[]> {
    return this.httpClient.get<UserLicense[]>(this.api + "GetUserLicenses");
  }
}
