<div fxLayout='column' fxLayoutGap="16px" (window:resize)="onResize($event)">
  <h2>Apply For a License</h2>
  <mat-grid-list role="list"
               [cols]="!sharedService.mobile? width: 6"
               [rowHeight]="!sharedService.mobile? '350px' : '350px'"
               gutterSize="16px">

  <mat-grid-tile *ngFor="let tile of tiles" role="listitem"
                 [colspan]="!sharedService.mobile? tile.cols: 6"
                 [rowspan]="tile.rows"
                 [style.background]="tile.color">
    <mat-grid-tile-header [routerLink]="tile.link" style="cursor: pointer;">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <mat-icon class="medium-icon m-24">{{tile.icon}}</mat-icon>
        <span class="f-s-20">{{tile.title}}</span>
      </div>
    </mat-grid-tile-header>
    <span class="p-32" *ngIf="tile.title !== 'Training Program'">{{tile.description}}  <a href="https://oregon.gov/psilocybin" target="_blank" rel="noreferrer noopener" style="color: #303030"> https://oregon.gov/psilocybin</a></span>
    <div fxLayout="column" *ngIf="tile.title == 'Training Program'">
    <span class="p-32">{{tile.description}} <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-Training-Program-Approval.aspx" target="_blank" rel="noreferrer noopener" style="color: #303030; word-break: break-all;
      white-space: normal;"> https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-Training-Program-Approval.aspx</a></span>
  </div>
    <mat-grid-tile-footer [routerLink]="tile.link" style="cursor: pointer;">
      <span mat-line
            [routerLink]="tile.link"
            class="f-s-20">{{tile.action}}</span>
    </mat-grid-tile-footer>
  </mat-grid-tile>
</mat-grid-list>

</div>
