<section>
  <div fxLayout="row" fxLayoutGap="8px">
    <span class="f-s-20" fxFlex>
      {{type}} User Info
    </span>
    <button *ngIf="userForm.valid" mat-stroked-button color="primary" type="submit" (click)="addUser()">{{type}} User</button>
    <button mat-stroked-button color="warn" type="button" (click)="cancel()">Cancel</button>
  </div>
</section>
<mat-dialog-content>
  <form [formGroup]="userForm" (submit)="addUser()">
    <div fxLayout="column">
      <mat-form-field>
        <input matInput placeholder="First Name" formControlName="firstName">
        <mat-error *ngIf="userForm.get('firstName').invalid">
          Please enter a first name
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Last Name" formControlName="lastName">
        <mat-error *ngIf="userForm.get('lastName').invalid">
          Please enter a last name
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Email" formControlName="email">
        <mat-error *ngIf="userForm.get('email').hasError('required')">
          Please enter an email adress
        </mat-error>
        <mat-error *ngIf="userForm.get('email').hasError('email')">
          This doesn't look like a valid email address
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
