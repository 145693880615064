import { Component, OnInit } from '@angular/core';
import { ILicenseTile } from 'app/models/apply/license-tile';
import { ILicenseStatusOverview } from 'app/models/licenses/license-status-overview';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit {

  fixedColumns: number = 6;
  loremIpsum: string = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
  in culpa qui officia deserunt mollit anim id est laborum.`

  overview: ILicenseStatusOverview = {
    activeFacilitator: false,
    startedFacilitator: false,
    activeManufacturer: false,
    startedManufacturer: false,
    activeServiceCenter: false,
    startedServiceCenter: false,
    activeTestingLab: false,
    startedTestingLab: false,
    activeWorkerPermit: false,
    startedWorkerPermit: false,
    activeTrainingProgram: false,
    startedTrainingProgram: false
  }

  tiles: ILicenseTile[] = []
  width: number = 6;
  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
    this.setSize(window.innerWidth);
    this.sharedService.getLicenseStatusOverview().subscribe(
      response => this.overview = response,
      error => console.log('error', error),
      () =>{
        this.setupTiles();
      }
    );

    if(this.sharedService.userProfile.userId === null){
      this.sharedService.getUserInfo().subscribe(
        response => this.sharedService.userProfile = response,
        error => console.log('error', error),
        () => {
          this.sharedService.toggleTheme();
          this.sharedService.updateUserPage("apply");
        }
      );
    }
    else{
      this.sharedService.updateUserPage("apply");
    }
  }

  setSize(element: number){
    if (element < 1300) {
      this.width = 4;
    }

    if (element > 1300) {
      this.width = 6;
    }

    if (element < 800) {
      this.width = 2;
    }
  }
  onResize(event) {
    const element = event.target.innerWidth;
    if (element < 1300) {
      this.width = 4;
    }

    if (element > 1300) {
      this.width = 6;
    }

    if (element < 800) {
      this.width = 2;
    }
  }

  setupTiles(): void{
    this.tiles = [
      {
        title: 'Facilitator',
        icon: 'group',
        description: 'To apply for a facilitator license through Oregon Psilocybin Services, start here. For more information about the facilitator license application requirements and process, please visit:',
        cols: 2,
        rows: 1,
        color: this.sharedService.purple,
        link: '/license/facilitator',
        action: this.overview.startedFacilitator? 'Continue' : 'Apply',
        id: 0,
        status: "",
        licenseIdenitfier: "",
        approvedDate: "",
        expirationDate: "",
        renewedDate: "",
        isRenewal: false,
        licenseType: "",
        type:'',
        entityName: '',
        physicalStreet: '',
        physicalCity: '',
        physicalState: '',
        physicalZip: '',
        physicalCounty: '',
        applicants: [],
        entityApplicants: [],
        fungiCultivation: false,
        psilocybinExtraction: false,
        ediblePsilocybinProduction: false,
        canOperate: false
      },
      {
        title: 'Manufacturer',
        icon: 'scale',
        description: 'To apply for a manufacturer license through Oregon Psilocybin Services, start here. For more information about the manufacturer license application requirements and process, please visit:',
        cols: 2,
        rows: 1,
        color: this.sharedService.gold,
        link: '/license/manufacturer',
        action: this.overview.startedManufacturer? 'Continue' : 'Apply',
        id: 0,
        status: "",
        licenseIdenitfier: "",
        approvedDate: "",
        expirationDate: "",
        renewedDate: "",
        isRenewal: false,
        licenseType: "",
        type:'',
        entityName: '',
        physicalStreet: '',
        physicalCity: '',
        physicalState: '',
        physicalZip: '',
        physicalCounty: '',
        applicants: [],
        entityApplicants: [],
        fungiCultivation: false,
        psilocybinExtraction: false,
        ediblePsilocybinProduction: false,
        canOperate: false
      },
      {
        title: 'Service Center',
        icon: 'store_mall_directory',
        description: 'To apply for a service center license through Oregon Psilocybin Services, start here. For more information about the service center license application requirements and process, please visit:',
        cols: 2,
        rows: 1,
        color: this.sharedService.green,
        link: '/license/service-center',
        action: this.overview.startedServiceCenter? 'Continue' : 'Apply',
        id: 0,
        status: "",
        licenseIdenitfier: "",
        approvedDate: "",
        expirationDate: "",
        renewedDate: "",
        isRenewal: false,
        licenseType: "",
        type:'',
        entityName: '',
        physicalStreet: '',
        physicalCity: '',
        physicalState: '',
        physicalZip: '',
        physicalCounty: '',
        applicants: [],
        entityApplicants: [],
        fungiCultivation: false,
        psilocybinExtraction: false,
        ediblePsilocybinProduction: false,
        canOperate: false
      },
      {
        title: 'Laboratory',
        icon: 'domain',
        description: 'To apply for a laboratory license through Oregon Psilocybin Services, start here. For more information about the laboratory license application requirements and process, please visit:',
        cols: 2,
        rows: 1,
        color: this.sharedService.orange,
        link: '/license/testing-lab',
        action: this.overview.startedTestingLab? 'Continue' : 'Apply',
        id: 0,
        status: "",
        licenseIdenitfier: "",
        approvedDate: "",
        expirationDate: "",
        renewedDate: "",
        isRenewal: false,
        licenseType: "",
        type:'',
        entityName: '',
        physicalStreet: '',
        physicalCity: '',
        physicalState: '',
        physicalZip: '',
        physicalCounty: '',
        applicants: [],
        entityApplicants: [],
        fungiCultivation: false,
        psilocybinExtraction: false,
        ediblePsilocybinProduction: false,
        canOperate: false
      },
      {
        title: 'Worker Permit',
        icon: 'badge',
        description: 'To apply for a worker permit through Oregon Psilocybin Services, start here. For more information about the worker permit application requirements and process, please visit:',
        cols: 2,
        rows: 1,
        color: this.sharedService.red,
        link: '/license/worker-permit',
        action: this.overview.startedWorkerPermit? 'Continue' : 'Apply',
        id: 0,
        status: "",
        licenseIdenitfier: "",
        approvedDate: "",
        expirationDate: "",
        renewedDate: "",
        isRenewal: false,
        licenseType: "",
        type:'',
        entityName: '',
        physicalStreet: '',
        physicalCity: '',
        physicalState: '',
        physicalZip: '',
        physicalCounty: '',
        applicants: [],
        entityApplicants: [],
        fungiCultivation: false,
        psilocybinExtraction: false,
        ediblePsilocybinProduction: false,
        canOperate: false
      },
      {
        title: 'Training Program',
        icon: 'description',
        description: 'To apply for a training program through Oregon Psilocybin Services, start here. For more information about the training program application requirements and process, please visit:',
        cols: 2,
        rows: 1,
        color: this.sharedService.blue,
        link: '/training',
        action: this.overview.startedTrainingProgram? 'Continue' : 'Apply',
        id: 0,
        status: "",
        licenseIdenitfier: "",
        approvedDate: "",
        expirationDate: "",
        renewedDate: "",
        isRenewal: false,
        licenseType: "",
        type:'',
        entityName: '',
        physicalStreet: '',
        physicalCity: '',
        physicalState: '',
        physicalZip: '',
        physicalCounty: '',
        applicants: [],
        entityApplicants: [],
        fungiCultivation: false,
        psilocybinExtraction: false,
        ediblePsilocybinProduction: false,
        canOperate: false
      }
    ]
  }

}
