import { Component, OnInit, Inject } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IUserProfile } from "app/models/user/user-profile";
import { SharedService } from "app/services/core/shared.service";
import { UserService } from "app/services/user/user.service";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html'
})
export class AddUserDialogComponent implements OnInit {
  public type: string = 'Add';
  public userForm = new UntypedFormGroup({
    firstName: new UntypedFormControl("", Validators.required),
    lastName: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", [Validators.required, Validators.email])
  });

  constructor(public sharedService: SharedService,
              public userService: UserService,
              public dialogRef: MatDialogRef<AddUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public user: IUserProfile) { }

  ngOnInit() {
    if(this.user.userId !== null && this.user.userId !== ''){
      this.type = 'Edit';
      this.userForm.patchValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email
      });
    }
  }

  addUser() : void {
    let form = this.userForm.value;
    this.user.firstName = form.firstName;
    this.user.lastName = form.lastName;
    this.user.email = form.email;

    console.log("")
    this.userService.udpateUserInfo(this.user).subscribe(
      response => this.dialogRef.close(response),
      error => console.log('error', error)
    );
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
