import { Component, ViewChild} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IDirectoryItem } from 'app/models/directory/directory-item';
import { SharedService } from 'app/services/core/shared.service';
import { PublicLicensesService } from 'app/services/licenses/public-licenses.service';

@Component({
  selector: 'app-license-directory',
  templateUrl: './license-directory.component.html',
  styleUrls: ['./license-directory.component.scss']
})
export class LicenseDirectoryComponent {
  public licenses: IDirectoryItem[] = [];

  public SearchForm = new UntypedFormGroup({
    licenseName: new UntypedFormControl(''),
    licenseType: new UntypedFormControl([])
  });
  public licenseTypes: string[] = [ '','Facilitator', 'Manufacturer', 'Service Center', 'Laboratory'];
  public licensesColumns = ['licenseName', 'licenseType', 'directoryWebsite', 'directoryPhone', 'directoryEmail'];
  public loading: boolean = true;
  public licensesSource = new MatTableDataSource<IDirectoryItem>(this.licenses);
  @ViewChild('licensesPaginator', { static: false }) licensesPaginator: MatPaginator;
  @ViewChild('licensesSort', { static: false }) licensesSort: MatSort;

  constructor(public publicLicensesService: PublicLicensesService,
    public sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.publicLicensesService.getConsentingLicenses().subscribe(
      response => this.licenses = response,
      error => console.log('error', error),
      () =>
      {
       this.updateLicenseTable(this.licenses);
       this.loading = false;
      }
    );
  }

  updateLicenseTable(LicenseList: IDirectoryItem[]): void {
    this.licensesSource.data = LicenseList;
    this.licensesSource.sort = this.licensesSort;
    this.licensesSource.paginator = this.licensesPaginator;
  }

  searchLicenses(): void {
    let searchValues = this.SearchForm.value;
    let licensesList = this.licenses;

    if(searchValues.licenseName != ''){
      licensesList = this.licenses.filter(l => l.licenseName.toLowerCase().includes(searchValues.licenseName));
      this.updateLicenseTable(licensesList);
    }

    if(searchValues.licenseType != ''){
      licensesList = licensesList.filter(l => l.licenseType.includes(searchValues.licenseType));
      this.updateLicenseTable(licensesList);
    }

    if(searchValues.licenseName == '' && searchValues.licenseType == ''){
      this.updateLicenseTable(this.licenses);
    }
  }

}


