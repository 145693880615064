<mat-card fxLayout="column" fxLayoutGap="12px">
  <mat-card-content fxLayout="column" fxLayoutGap="12px">
    <mat-card-title fxLayoutGap="12px">
      <span>
        Change OPS License Directory
      </span>
      <span fxFlex></span>
      <button mat-flat-button color="warn" (click)="cancel()">Close</button>
      <button mat-flat-button color="primary" (click)="save()" [disabled]="validateDirectoryConsentWebsite()">Save & Close</button>
    </mat-card-title>
    <form [formGroup]="licenseForm">
      <mat-radio-group aria-label="Select an option" formControlName="directoryConsent" fxLayoutGap="16px" style="padding-top: 1em">
        <div fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row">
            <mat-radio-button [value]="true">
              <span class="text-wrap">
                I consent to having my license name, license type, and the contact information selected below listed in the voluntary OPS Licensee Directory.
              </span>
            </mat-radio-button>
          </div>
          <span  *ngIf="licenseForm.get('directoryConsent').value">Please select which contact information you would like to include in the directory:</span>

          <div fxLayout="column" style="padding-left: 1em" *ngIf="licenseForm.get('directoryConsent').value">
            <div fxLayou="row" fxLayoutGap="5px">
              <mat-checkbox formControlName="directoryPreferredName" *ngIf="preferredFirstName != '' || preferredLastName != ''">
                <span style="white-space: normal;">Display my preferred name in the License Directory instead of my legal name.</span>
              </mat-checkbox>
            </div>
            <div fxLayou="row" fxLayoutGap="5px">

              <mat-checkbox formControlName="directoryConsentWebsite">
                <span style="white-space: normal;">Website:</span>
              </mat-checkbox>

              <mat-form-field *ngIf="licenseForm.get('directoryConsentWebsite').value">
                <mat-label>
                  Website
                </mat-label>
                <input matInput formControlName="directoryWebsite">
                <mat-error>
                  <span *ngIf="licenseForm.get('directoryWebsite').hasError('required')">Directory website URL is required</span>
                  <span *ngIf="licenseForm.get('directoryWebsite').hasError('pattern')">Please enter a valid URL (http:// or https://)</span>
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayou="row" fxLayoutGap="5px">
              <mat-checkbox formControlName="directoryConsentEmail">
                <span style="white-space: normal;">Email:</span>
              </mat-checkbox>

              <mat-form-field *ngIf="licenseForm.get('directoryConsentEmail').value">
                <mat-label>
                  Email
                </mat-label>
                <input matInput formControlName="directoryEmail">
                <mat-error *ngIf="validateDirectoryConsentEmail()">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayou="row" fxLayoutGap="5px">
              <mat-checkbox formControlName="directoryConsentPhone">
                <span style="white-space: normal;">Phone Number:</span>
              </mat-checkbox>

              <mat-form-field *ngIf="licenseForm.get('directoryConsentPhone').value">
                <mat-label>
                  Phone number
                </mat-label>
                <input matInput formControlName="directoryPhone" mask="(000) 000-0000 000000">
                <mat-error *ngIf="validateDirectoryConsentPhone()">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row">
            <mat-radio-button [value]="false">
              <span class="text-wrap">
                I <strong>do not</strong> consent to having my license name, license type, and contact information listed in the voluntary OPS Licensee Directory.
              </span>
            </mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
      <mat-error *ngIf="licenseForm.get('directoryConsent').invalid">You must select one statement regarding consent</mat-error>
      <span class="text-wrap"><em>Please note, if you do not consent, you may still be licensed with Oregon Psilocybin Services.</em></span>
    </form>
  </mat-card-content>
</mat-card>
