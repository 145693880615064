
import { Component } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { SharedService } from 'app/services/core/shared.service';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-pay-online-failure',
  templateUrl: './pay-online-failure.component.html'
})

export class PayOnlineFailureComponent {

  public processing: boolean = false;
  public isValid: boolean = true;


  constructor(public paymentService: PaymentService,
              public sharedService: SharedService,
              public authService: AuthService,
              public userService: UserService) { }


}


