import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnInit
} from "@angular/core";
import { environment } from "environments/environment";
import { AuthService } from "app/auth/auth.service";
import { MsalBroadcastService } from "@azure/msal-angular";
import { filter, switchMap } from "rxjs/operators";
import { EventMessage, EventType } from "@azure/msal-browser";
import { SharedService } from "app/services/core/shared.service";
import { InternalApplicationMessagingService } from "app/components/internal-app-messaging/internal-app-messaging.service";
import { Subscription, timer } from "rxjs";

@Component({
  selector: "app-navbar",
  host: {
    class: "matero-header",
  },
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavbarComponent implements OnInit {
  subscription !: Subscription;
  public environment = environment;
  @Input() showToggle = true;
  @Output() toggleSidenav = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    public sharedService: SharedService,
    private msalBroadcastService: MsalBroadcastService,
    public internalApplicationMessagingService: InternalApplicationMessagingService
  ) {}

  ngOnInit(): void{
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    )
    .subscribe(() => {
      this.toggleSidenav.emit();
      this.internalApplicationMessagingService.loadUnreadCount();

      //timer to check for unread messages - in milliseconds
      this.subscription = timer(0, 300000).pipe(
        switchMap(async () =>{
         this.internalApplicationMessagingService.loadUnreadCount()
      })
      ).subscribe();
    });

  }

  public get username(): string{
    return this.authService.username
  }

  login() {
    this.authService.login();
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  logout() {
    this.authService.logout();
  }

  toggleTheme() {
    this.sharedService.darkMode = !this.sharedService.darkMode;
    this.sharedService.userProfile.theme = this.sharedService.darkMode ? "dark" : "light";
    if(this.isAuthenticated()){
      this.sharedService.updateUserProfileState();
    }
    this.sharedService.toggleTheme();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
