<mat-toolbar class="matero-toolbar">
  <button mat-icon-button class="matero-toolbar-button" aria-label="open sidebar menu" name="open sidebar menu"
    *ngIf="(!environment.collapsibleSidebar || sharedService.mobile) && environment.displaySidebar && isAuthenticated()"
    (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <span *ngIf="!sharedService.mobile && !sharedService.tablet" fxFlex class="p-l-16" style="color: #005595">
    Oregon Psilocybin Services (OPS) - Training Program, Licensing, and Compliance (TLC) System
  </span>

  <span *ngIf="sharedService.tablet" fxFlex class="p-l-16" style="color: #005595">
    Oregon Psilocybin Services (OPS) - (TLC)
  </span>

  <span *ngIf="(sharedService.mobile) && !isAuthenticated()" fxFlex class="p-l-16" style="color: #005595">
    Oregon Psilocybin Services (OPS)
  </span>
  <span *ngIf="(sharedService.mobile) && isAuthenticated()" fxFlex class="p-l-16" style="color: #005595">
    OPS TLC
  </span>

  <app-translate *ngIf="environment.translateText"></app-translate>

  <div fxLayout="row" fxLayoutGap="8px">
    <button mat-icon-button *ngIf="!isAuthenticated()" (click)="login()" class="matero-toolbar-button"
      aria-label="Login" matTooltip="Login" matTooltipPosition="below" [matTooltipShowDelay]="750">
      <mat-icon class="dark-theme-icon">login</mat-icon>
    </button>

    <div *ngIf="isAuthenticated()" fxLayout="row" fxLayoutAlign="start center" class="m-r-8">
      <mat-icon [routerLink]="['/messages']"
                *ngIf='environment.internalMessaging.enabled'
                [matBadge]="internalApplicationMessagingService.unreadCount"
                matBadgePosition="after"
                color="primary"
                [matBadgeHidden]="internalApplicationMessagingService.unreadCount == 0"
                matBadgeColor="warn">
        notifications
      </mat-icon>
      <button mat-button [matMenuTriggerFor]="userMenu"><span class="f-s-20">{{ username }}</span>
        <mat-icon>expand_more</mat-icon>
      </button>
    </div>

    <mat-menu #userMenu="matMenu">
      <button mat-menu-item *ngIf="environment.showThemeToggle && sharedService.darkMode" class="matero-toolbar-button"
        (click)="toggleTheme()" aria-label="Toggle Theme" matTooltip="Select Light Theme" matTooltipPosition="below"
        [matTooltipShowDelay]="750">
        <span class="p-r-8">Toggle Theme</span>
        <mat-icon class="light-theme-icon">brightness_7</mat-icon>
      </button>

      <button mat-menu-item *ngIf="environment.showThemeToggle && !sharedService.darkMode" class="matero-toolbar-button"
        (click)="toggleTheme()" aria-label="Toggle Theme" matTooltip="Select Dark Theme" matTooltipPosition="below"
        [matTooltipShowDelay]="750">
        <span class="p-r-8">Toggle Theme</span>
        <mat-icon class="dark-theme-icon">brightness_3</mat-icon>
      </button>
      <button mat-menu-item *ngIf="isAuthenticated()" routerLink='/account-information' class="matero-toolbar-button"
      aria-label="Account Information" matTooltip="Account Information" matTooltipPosition="below" [matTooltipShowDelay]="750">
      <span class="m-r-16">Account</span>
      <mat-icon>account_circle</mat-icon>
      </button>
      <button mat-menu-item *ngIf="isAuthenticated()" (click)="logout()" class="matero-toolbar-button"
        aria-label="Logout" matTooltip="Logout" matTooltipPosition="below" [matTooltipShowDelay]="750">
        <span class="m-r-16">Sign-Out</span>
        <mat-icon>logout</mat-icon>
      </button>

    </mat-menu>
  </div>
</mat-toolbar>
