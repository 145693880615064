import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { LicenseDashboardComponent } from './components/license-dashboard/license-dashboard.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ApplyComponent } from './components/apply/apply.component';
import { AuthGuard } from './auth/auth-guard';
import { PublicProgramListComponent } from './components/public-program-list/public-program-list.component';
import { ComplaintsComponent } from './components/complaints/complaints.component';
import { InternalAppMessagingComponent } from './components/internal-app-messaging/internal-app-messaging.component';
import { PaymentsComponent } from './components/payments/components/payments/payments.component';
import { PayOnlineComponent } from './components/payments/components/pay-online/pay-online.component';
import { PayOnlineCancelComponent } from './components/payments/components/pay-online/cancel/pay-online-cancel.component';
import { PayOnlineFailureComponent } from './components/payments/components/pay-online/failure/pay-online-failure.component';
import { PayOnlineDuplicateComponent } from './components/payments/components/pay-online/duplicate/pay-online-duplicate.component';
import { PayOnlineSuccessComponent } from './components/payments/components/pay-online/success/pay-online-success.component';
import { LicenseInformationComponent } from './components/license-dashboard/license-information/license-information/license-information.component';
import { AccountInformationComponent } from './components/account-information/account-information.component';
import { PublicLicenseSearchComponent } from './components/public-license-search/public-license-search.component';
import { LicenseDirectoryComponent } from './components/directory/license-directory.component';
import { MsalGuard } from '@azure/msal-angular';
import { LicensingHomeComponent } from './components/licensing-home/licensing-home.component';


const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      { path: 'licensing-home', component: LicensingHomeComponent, canActivate: [ MsalGuard] },
      { path: 'license-dashboard', component: LicenseDashboardComponent, canActivate: [ AuthGuard ] },
      { path: '303-reporting',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('./components/303/303.module').then(m => m.SB303Module)
       },
      { path: 'account-information', component: AccountInformationComponent, canActivate: [ AuthGuard ] },
      { path: 'apply', component: ApplyComponent, canActivate: [ AuthGuard ] },
      { path: 'license-directory', component: LicenseDirectoryComponent},
      { path: 'welcome', component: WelcomeComponent },
      { path: 'license-information/:type/:id', component: LicenseInformationComponent},
      {
        path: 'training',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('./components/training/training-program.module').then(m => m.TrainingProgramModule)
      },

      { path: 'messages',
        canActivate: [ AuthGuard ],
        component: InternalAppMessagingComponent,
        loadChildren: () => import('./components/internal-app-messaging/internal-app-messaging.module').then(m => m.InternalAppMessagingModule)
      },

      { path: 'payments',
        canActivate: [ AuthGuard ],
        component: PaymentsComponent
      },

      { path: 'pay-online/:id',
        canActivate: [ AuthGuard ],
        component: PayOnlineComponent
      },
      { path: 'payment/cancel',
        canActivate: [ AuthGuard ],
        component: PayOnlineCancelComponent
      },
      { path: 'payment/failure',
        canActivate: [ AuthGuard ],
        component: PayOnlineFailureComponent
      },
      { path: 'payment/duplicate',
        canActivate: [ AuthGuard ],
        component: PayOnlineDuplicateComponent
      },
      { path: 'payment/success',
        canActivate: [ AuthGuard ],
        component: PayOnlineSuccessComponent
      },
      {
        path: 'sessions',
        loadChildren: () => import('./components/sessions/sessions.module').then(m => m.SessionsModule)
      },
      {
        path: 'license',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('./components/license/license.module').then(m => m.LicenseModule)
      },
      {
        path: 'product-tracking',
        canActivate: [MsalGuard],
        loadChildren: () => import('./components/product-tracking/product-tracking.module').then(m => m.ProductTrackingModule)
      }
    ],
  },
  { path: 'training-approved', component: PublicProgramListComponent},
  { path: 'search-approved-licenses', component: PublicLicenseSearchComponent},
  { path: 'complaints', component: ComplaintsComponent},
  { path: '**', redirectTo: 'training' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      initialNavigation: !isIframe ? "enabledNonBlocking" : "disabled",
      relativeLinkResolution: 'legacy',
    })
  ],
  exports: [RouterModule],
  declarations: [
  ]
})
export class RoutesRoutingModule { }
