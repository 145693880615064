import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import {InternalApplicationMessage, InternalApplicationRecipient } from './internal-app-message';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

const httpOptionsFormData = {
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
    providedIn: 'root'
})
export class InternalApplicationMessagingService {

  public unreadCount: number = 0;

  unreadView = 'Unread';
  sentView = 'Sent';
  inboxView = 'Inbox';
  staffView = 'Staff';

  constructor(private http: HttpClient) {}

  loadUnreadCount() {
    this.getUnread().subscribe(result => {
     this.unreadCount = result.length;
    }, error => {
      console.error(error);
    });
  }

  getInbox(includeArchived: boolean): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/inbox/' + includeArchived ,  { withCredentials: true });
  }

  getMessage(messageId: number): Observable<InternalApplicationMessage> {
    return this.http.get<InternalApplicationMessage>(environment.privateApi + 'InternalApplicationMessaging/message/' + messageId ,  { withCredentials: true });
  }

  getUnread(): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/unread'  ,  { withCredentials: true });
  }

  getSent(includeArchived: boolean): Observable<InternalApplicationMessage[]> {
    return this.http.get<InternalApplicationMessage[]>(environment.privateApi + 'InternalApplicationMessaging/sent/' + includeArchived,  { withCredentials: true });
  }

  getRecipients(): Observable<InternalApplicationRecipient[]> {
    return this.http.get<InternalApplicationRecipient[]>(environment.privateApi + 'InternalApplicationMessaging/recipients' ,  { withCredentials: true });
  }

  getSenderInfo(messageId: number): Observable<InternalApplicationRecipient> {
    return this.http.get<InternalApplicationRecipient>(environment.privateApi + 'InternalApplicationMessaging/senderInfo/' + messageId ,  { withCredentials: true });
  }

  // getUserLicenses(): Observable<InternalApplicationLicense[]> {
  //   return this.http.get<InternalApplicationLicense[]>(environment.privateApi + 'InternalApplicationMessaging/licenses' ,  { withCredentials: true });
  // }

  public sendMessage(message: FormData) : Observable<any> {
    return this.http.post<any>(environment.privateApi + 'InternalApplicationMessaging/send', message, httpOptionsFormData);
  }

  public opened(messageId: number) {
    this.http.get(environment.privateApi + 'InternalApplicationMessaging/opened/' + messageId,  { withCredentials: true }).subscribe(
      () => {
        this.loadUnreadCount();
      }, error => {
        console.error(error);
      });
  }

  public openedBroadcast(messageId: number){
    this.http.get(environment.privateApi + 'InternalApplicationMessaging/opened/broadcast/' + messageId,  { withCredentials: true }).subscribe(
      () => {
        this.loadUnreadCount();
      }, error => {
        console.error(error);
      });
  }

  public archive(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/archive/' + messageId ,  { withCredentials: true });
  }

  public archiveBroadcast(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/archive/broadcast/' + messageId ,  { withCredentials: true });
  }

  public unarchive(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/unarchive/' + messageId ,  { withCredentials: true });
  }

  public unarchiveBroadcast(messageId: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/unarchive/broadcast/' + messageId ,  { withCredentials: true });
  }

  public download(id: number) : Observable<any>{
    return this.http.get<any>(environment.privateApi + 'InternalApplicationMessaging/download/' + id ,  { responseType: 'blob' as 'json', withCredentials: true });
  }

  public saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

}
