
import { Component, OnInit} from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { UserService } from 'app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { IPayment } from '../../models/payment';
import { PaymentService } from '../../service/payment.service';
import { PayDialog } from '../pay/pay-component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})

export class PaymentsComponent implements OnInit {

  public processing: boolean = false;
  public paymentsSource = new MatTableDataSource<IPayment>();
  public payments: IPayment [] = [  ];
  public paymentColumns: string[] = ['type','name','appStatus','status','paymentCleared','amount','paymentMethod', 'sku', 'comments', 'actions'];
  public cardColor: string = this.sharedService.blue + '20';


  constructor(public paymentService: PaymentService,
              public sharedService: SharedService,
              public authService: AuthService,
              public userService: UserService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnInit(): void {
    this.getUserPayments();
  }

  getUserPayments() {
    this.processing = true;
    this.userService.getUserLicenses().subscribe(result => {
      result.forEach(r => {
        r.payments.forEach(p => {
          let payment = p;
          payment.id = +r.id;
          payment.entityName = r.name;
          payment.applicationStatus = r.status;
          this.payments.push(payment);
        });
      } );
      this.paymentsSource = new MatTableDataSource<IPayment>(this.payments);
      this.processing = false;
    }, error => {
      this.processing = false;
      this.toastr.error('Unable get user payments');
    });
  }

  canPay(payment: IPayment): boolean {
    if(payment.entityType == this.paymentService.WorkerPermitPaymentOption) {
      return !payment.paid && payment.applicationStatus == this.paymentService.paymentApprovedAwaitingPayment && payment.status != this.paymentService.paymentAwaitingClearanceStatus;
    }
    return !payment.paid && payment.applicationStatus != this.paymentService.paymentPendingStatus && payment.status != this.paymentService.paymentAwaitingClearanceStatus;
  }

  pay(entityType: string, feeType: string, id: number, comments: string) {
    let showConfirmation = false;
    if (comments != null && comments.indexOf(this.paymentService.PaymentIssuedCommentSearchText) >= 0) {
      showConfirmation = true;
    }
    if (showConfirmation) {
      this.sharedService.openConfirm("Looks like you have already attempted a payment for this license.  For payments to completely clear through the system, it can take upto 3 business days. Most payments are processed within 24 hrs.  Do you want to continue?");
      this.sharedService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          this.dialog.open(PayDialog, {
            data: {
              entityType: entityType,
              feeType: feeType,
              canPay: true,
              id: id
            },
            role: 'dialog',
            ariaLabel: "Fee Information Dialog",
            minWidth: !this.sharedService.mobile ? '400px' : '300px',
            maxWidth: !this.sharedService.mobile ? '800px' : '300px',
            maxHeight: !this.sharedService.mobile ? '800px' : '500px',
            panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
          });
        }
      });
    }
    else {
      this.dialog.open(PayDialog, {
        data: {
          entityType: entityType,
          feeType: feeType,
          canPay: true,
          id: id
        },
        role: 'dialog',
        ariaLabel: "Fee Information Dialog",
        minWidth: !this.sharedService.mobile ? '400px' : '300px',
        maxWidth: !this.sharedService.mobile ? '800px' : '300px',
        maxHeight: !this.sharedService.mobile ? '800px' : '500px',
        panelClass: this.sharedService.userProfile.theme === "dark" ? "theme-dark" : "",
      });
    }
  }


}


