<div fxLayout="column" fxLayoutGap="8px" style="margin: 20px;">
  <div fxLayout="row" fxLayout.lt-md="column">
    <span fxFlex style="font-family: Oswald, sans-serif; font-size: 20pt;">Current Active Psilocybin Training Programs</span>
  </div>
  <mat-table [dataSource]="programSource" matSort #programSort="matSort" aria-describedby="Training Programs"
    class="mat-elevation-z8">
    <ng-container matColumnDef="programName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Program Name</mat-header-cell>
      <mat-cell *matCellDef="let program">
        <span class="mobile-label">Program Name</span>
        {{ program.programName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="website">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Website</mat-header-cell>
      <mat-cell *matCellDef="let program">
        <span class="mobile-label">Website</span>
        <a [href]="program.website" target="_blank" rel="noreferrer noopener">{{ program.website }}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Headquarters/Main Office Address</mat-header-cell>
      <mat-cell *matCellDef="let program">
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span>{{program.facilityCity}}</span>
            <span>{{program.facilityState}}</span>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="programColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: programColumns">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[25, 50, 100 ]" #programPaginator="matPaginator">
  </mat-paginator>
</div>
